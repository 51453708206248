import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import LeadHeader from "../../../components/LeadHeader/LeadHeader"
import PageLoading from "../../../components/PageLoading/PageLoading"
import useLead from "../../../hooks/useLead"
import DatosContacto from "./components/DatosContacto/DatosContacto"
import DatosEstudios from "./components/DatosEstudios/DatosEstudios"
import DatosMarketing from "./components/DatosMarketing/DatosMarketing"
import ModalEditarDatosContactoLead from "../../../modals/ModalEditarDatosContactoLead/ModalEditarDatosContactoLead"
import ModalEditarDatosMarketingLead from "../../../modals/ModalEditarDatosMarketingLead/ModalEditarDatosMarketingLead"
import ModalEditarDatosEstudiosLead from "../../../modals/ModalEditarDatosEstudiosLead/ModalEditarDatosEstudiosLead"
import { updateLead } from "../../../utils/api/leads"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { useNotification } from "../../../context/NotificationManager/NotificationManager"
import css from './Ficha.module.css'

const Ficha = ()=> {

  const notification = useNotification()
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  const { isLoading, lead } = useLead()

  const [isEditarDatosContactoOpen, setEditarDatosContactoOpen] = useState(false)
  const [isEditarDatosEstudiosOpen, setEditarDatosEstudiosOpen] = useState(false)
  const [isEditarDatosMarketingOpen, setEditarDatosMarketingOpen] = useState(false)

  const { isPending: isLeadUpdating, mutate: actualizarLead } = useMutation({
    mutationFn: updateLead,
    onSuccess: ()=> {
      notification.success({ title: 'Academia cambiado', content: 'Se ha actualizado el lead' })
      setEditarDatosContactoOpen(false)
      setEditarDatosEstudiosOpen(false)
      setEditarDatosMarketingOpen(false)
      queryClient.invalidateQueries({ 
        queryKey: ['marketing', 'lead', lead.id]
      })
      queryClient.invalidateQueries({
        queryKey: ['marketing', 'feedback', 'list', 'CRM']
      })
    },
    onError: err=> notification.error({ title: 'Error al modificar el lead', content: err }),
  })


  useEffect(()=> {
    if (!lead) navigate('/crm')
  }, [lead, navigate])

  const handleUpdateLead = (datos) => {
    if (isLeadUpdating) return
    actualizarLead(datos)
  }

  return (
    <PageLoading isLoading={isLoading}>
      <div className={css.main}>
        <LeadHeader label='Ficha' />
        <div className={css.content}>
          <div className={css.accordion}>
            <DatosContacto lead={lead} onEdit={()=> setEditarDatosContactoOpen(true)} />
          </div>
          <div className={css.accordion}>
            <DatosEstudios lead={lead} onEdit={()=> setEditarDatosEstudiosOpen(true)} />
          </div>
          <div className={css.accordion}>
            <DatosMarketing lead={lead} onEdit={()=> setEditarDatosMarketingOpen(true)} />
          </div>
        </div>
      </div>
      <ModalEditarDatosContactoLead
        open={isEditarDatosContactoOpen}
        lead={lead}
        onClose={()=> setEditarDatosContactoOpen(false)}
        onSubmit={handleUpdateLead}
      />
      <ModalEditarDatosMarketingLead
        open={isEditarDatosMarketingOpen}
        lead={lead}
        onClose={()=> setEditarDatosMarketingOpen(false)}
        onSubmit={handleUpdateLead}
      />
      <ModalEditarDatosEstudiosLead
        open={isEditarDatosEstudiosOpen}
        lead={lead}
        onClose={()=> setEditarDatosEstudiosOpen(false)}
        onSubmit={handleUpdateLead}
      />
    </PageLoading>
  )
}

export default Ficha