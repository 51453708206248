import { useEffect, useState } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material'
import { useNotification } from '../../context/NotificationManager/NotificationManager'
import { useQuery } from '@tanstack/react-query'
import { getCodigosPromocionalesHabilitados } from '../../utils/api/codigoPromocional'
import css from './ModalNuevoCodigoPromocional.module.css'

const CodigoOption = ({ codigo, isDisabled })=> (
  <FormControlLabel
    className={css.option}
    value={codigo.nombre}
    control={<Radio />} 
    disabled={isDisabled}
    label={(
      <div className={css.optionLabel}>
        <strong className={css.optionCode}>{codigo.nombre}</strong>
        <span className={css.optionDescription}>{codigo.descripcion}</span>
      </div>
    )} 
  />
)

const ModalNuevoCodigoPromocional = ({ open, pago, onSubmit, onClose }) => {

  const notification = useNotification()

  const { data: codigosPromocionales = [] } = useQuery({
    queryKey: ['codigo-promocional', 'list', 'enabled'], 
    queryFn: () => getCodigosPromocionalesHabilitados()
      .then(datos => datos)
      .catch(e=> {
        notification.error({ title: 'Error al obtener códigos promocionales', content: e })
        return []
      })
  })

  const [codigo, setCodigo] = useState('')

  useEffect(() => {
    if (open) setCodigo('')
  }, [open])

  const handleSubmit = () => {
    onSubmit(codigo)
  }

  const listaCodigos = [...codigosPromocionales].sort((a,b)=> a.nombre < b.nombre ? -1 : 1)

  const promociones = (pago && pago.codigosPromocionales) || []

  return (
    <Dialog onClose={onClose} open={open} fullWidth maxWidth='md'>
      <DialogTitle>Nuevo código promocional</DialogTitle>
      <DialogContent className={css.main}>
        <DialogContentText className={css.intro}>
          Selecciona el código que quieres añadir
        </DialogContentText>
        <FormControl>
          <RadioGroup value={codigo} onChange={e=> setCodigo(e.target.value)}>
            {listaCodigos.map((codigo, i)=> (
              <CodigoOption 
                key={i} 
                codigo={codigo}
                isDisabled={promociones.includes(codigo.nombre)}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button color='error' onClick={onClose}>
          Cancelar
        </Button>
        <Button
          disabled={!codigo}
          color='primary'
          onClick={handleSubmit}
        >
          Enviar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ModalNuevoCodigoPromocional
