import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { Button } from '@mui/material'
import { getAuth, removeAuth } from '../../utils/auth'
import agora from './agora-white.png'
import timestamp from '../../utils/timestamp'
import { useNotification } from '../../context/NotificationManager/NotificationManager'
import { Link } from 'react-router-dom'
import { useIsFetching } from '@tanstack/react-query'
import LoadingIndicator from '../../components/LoadingIndicator/LoadingIndicator'
import css from './Header.module.css'

const Header = ({ onHelpClick }) => {
  const navigate = useNavigate()
  const notification = useNotification()

  const userData = getAuth()

  const fetchingQueries = useIsFetching()

  const [time, setTime] = useState('')

  useEffect(() => {
    const interval = setInterval(() => setTime(timestamp()), 1000)
    return () => clearInterval(interval)
  }, [])

  const handleRefresh = () => window.location.reload(true)

  const handleLogout = () => {
    removeAuth()
    notification.success({
      title: 'Sesión cerrada',
      content: 'Se ha cerrado la sesión correctamente'
    })
    navigate('/login')
  }

  const handleMenuCollapse = ()=> {
    const collapseValue = document.body.getAttribute("data-collapse")
    if (collapseValue) document.body.removeAttribute("data-collapse")
    else document.body.setAttribute("data-collapse", 'collapsed')
  }

  return (
    <header className={css.header}>
      <div className={css.containerHero}>
        <div className={css.logo}>
          <img className={css.image} src={agora} alt='logo ágora' />
        </div>
        <div className={css.headerCenter}>{userData.centro}</div>
      </div>

      <div className={css.actions}>
        {fetchingQueries > 0 && (
          <LoadingIndicator number={fetchingQueries} />
        )}
        <div className={css.clock}>{time}</div>
        <div className={css.name}>
          <Link to='/perfil'>{userData.username}</Link>
        </div>
        <img className={css.profile} src={userData.foto} alt='' />
        <Button
          component={Link}
          to='/perfil'
          className={css.mobileButton}
          variant='contained'
        >
          <i className="material-icons">account_circle</i>
        </Button>
        <Button
          className={css.button}
          variant='contained'
          onClick={onHelpClick}
        >
          <i className="material-icons">help</i>
        </Button>
        <Button 
          className={css.button} 
          variant='contained' 
          onClick={handleMenuCollapse}
        >
          <i className='material-icons'>menu</i>
        </Button>
        <Button
          className={css.button}
          variant='contained'
          onClick={handleRefresh}
        >
          <i className='material-icons'>cached</i>
        </Button>
        <Button
          className={css.button}
          variant='contained'
          onClick={handleLogout}
        >
          <i className='material-icons'>power_settings_new</i>
        </Button>
      </div>
    </header>
  )
}

export default Header
