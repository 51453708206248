import React from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { CssBaseline } from '@mui/material'
import { ThemeProvider } from '@mui/system'
import { LocalizationProvider, esES } from '@mui/x-date-pickers'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { StyledEngineProvider } from '@mui/material/styles'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { NotificationManager } from './context/NotificationManager/NotificationManager'
import theme from './hoc/theme'
import App from './App'
import './index.css'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 5,
      refetchOnWindowFocus: false,
      retry: 1,
    }
  }
})

const app = (
  <React.StrictMode>
    <>
      <CssBaseline />
      <GoogleOAuthProvider clientId='836679919166-1ta7jp9gcs4rqvacem8mkkp6a9ckcss4.apps.googleusercontent.com'>
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <StyledEngineProvider injectFirst>
              <ThemeProvider theme={theme}>
                <LocalizationProvider 
                  dateAdapter={AdapterLuxon} 
                  adapterLocale='es' 
                  localeText={esES.components.MuiLocalizationProvider.defaultProps.localeText}
                >
                  <NotificationManager>
                    <App />
                  </NotificationManager>
                </LocalizationProvider>
              </ThemeProvider>
            </StyledEngineProvider>
          </BrowserRouter>
          <ReactQueryDevtools initialIsOpen={false} buttonPosition='bottom-left' />
        </QueryClientProvider>
      </GoogleOAuthProvider>
    </>
  </React.StrictMode>
)

createRoot(document.getElementById('root')).render(app)
