import { useEffect, useState } from "react"
import useDebounce from "../../../../hooks/useDebounce"
import { Badge, Collapse, IconButton, Link, TableCell, TableRow, TextField } from "@mui/material"
import EstadoAsistencia from "../../../../components/EstadoAsistencia/EstadoAsistencia"
import { isBlank } from "../../../../utils/string"
import useResponsive from "../../../../hooks/useResponsive"
import ProfesorSelector from "../../../../components/form/ProfesorSelector/ProfesorSelector"
import css from './ClaseRow.module.css'

const SinProfesor = ()=> (
  <span className={css.placeholder}>Sin profesor</span>
)

const ClaseRow = ({ asistencia, centro, isOpen, onOpen, onChange, onProfesorChange, onObservacionesChange })=> {

  const [observaciones, setObservaciones] = useState(asistencia.observaciones || '')
  const hasObservaciones = !isBlank(observaciones)
  const observacionesDebounced = useDebounce(observaciones)

  const { size, isScreenBiggerThan } = useResponsive()

  useEffect(()=> {
    if (observacionesDebounced === asistencia.observaciones) return
    onObservacionesChange(observacionesDebounced, asistencia.id)
  }, [ asistencia.id, asistencia.observaciones, observacionesDebounced, onObservacionesChange ])

  const getColspan = ()=> {
    if (size === 'xl') return 6
    if (size === 'lg') return 5
    if (size === 'md') return 4
    return 3
  }

  return (
    <>
      <TableRow 
        className={css.row} 
        data-prueba={asistencia.clase === 'PRUEBA' ? 'true' : 'false'}
        data-clase-fuera={asistencia.centro !== centro}
        data-alumno-fuera={asistencia.centroAlumno !== centro}
      >
        <TableCell className={css.mainRowCell}>
          <Link
            className={css.alumnoLink}
            href={`/alumnos/${asistencia.alumnoId}/ficha`}
          >
            {asistencia.alumno}
          </Link>
        </TableCell>
        <TableCell 
          className={css.mainRowCell}
          data-visibility={isScreenBiggerThan('sm') ? 'visible' : 'invisible'} 
        >
          {asistencia.asignatura}
        </TableCell>
        <TableCell 
          data-visibility={isScreenBiggerThan('md') ? 'visible' : 'invisible'} 
          className={css.mainRowCell}
        >
          {asistencia.profesor || <SinProfesor />}
        </TableCell>
        <TableCell 
          data-visibility={isScreenBiggerThan('lg') ? 'visible' : 'invisible'} 
          className={css.mainRowCell}
        >
          {asistencia.clase.replaceAll("_", " ")}
        </TableCell>
        <TableCell className={css.mainRowCell} align="center">
          <EstadoAsistencia
            asistencia={asistencia}
            centro={centro}
            onClick={(asistenciaAlumno)=> onChange(asistenciaAlumno, asistencia.id)}
          />
        </TableCell>
        <TableCell className={css.mainRowCell}>
          <IconButton
            size="small"
            onClick={onOpen}
          >
            <Badge color="primary" variant="dot" invisible={!hasObservaciones}>
              <i className='material-icons'>
                {isOpen ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
              </i>
            </Badge>
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow 
        className={css.row} 
        data-clase-fuera={asistencia.centro !== centro}
        data-alumno-fuera={asistencia.centroAlumno !== centro}
      >
        <TableCell className={css.additionalRow} colSpan={getColspan()}>
          <Collapse in={isOpen} timeout="auto" unmountOnExit>
            {asistencia.centro !== centro && (
              <p className={css.extraInfo}>
                <i className='material-icons'>info</i>
                <span>
                  Está dando clase en <strong>{asistencia.centro}</strong>
                </span>
              </p> 
            )}
            {asistencia.centroAlumno !== centro && (
              <p className={css.extraInfo}>
                <i className='material-icons'>info</i>
                <span>
                  Alumno con ficha en <strong>{asistencia.centro}</strong>
                </span>
              </p> 
            )}
            <div className={css.profeInfo}>
              <i className='material-icons'>next_week</i>
              <p className={css.profeIntro}>
                Profesor de la clase
              </p>
              <ProfesorSelector
                className={css.input}
                name='profesor'
                label='Profesor'
                value={asistencia.profesorId || ''}
                onChange={(_e, value)=> onProfesorChange(value?.id || '', asistencia.id)}
              />
            </div>
            <div className={css.observaciones}>
              <TextField
                name='observaciones'
                label='Observaciones'
                variant='standard'
                margin='dense'
                value={observaciones}
                onChange={e=> setObservaciones(e.target.value)}
                maxRows={8}
                multiline
                fullWidth
              />
            </div>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
} 

export default ClaseRow