
import { Chip, Collapse, IconButton, Link, TableCell, TableRow, Typography } from '@mui/material'
import { format, formatForExport } from '../../../../../utils/math'
import { useState } from 'react'
import InformationTable from '../../../../../components/Table/InformationTable'
import { getFactura } from '../../../../../utils/documents'
import ModalNuevoCodigoPromocional from '../../../../../modals/ModalNuevoCodigoPromocional/ModalNuevoCodigoPromocional'
import css from './PagoRow.module.css'

const Placeholder = ({ content })=> (
  <span className={css.placeholder}>
    {content}
  </span>
)

const NuevaPromocion = ({ pago, onAddPromocion })=> {

  const [open, setOpen] = useState(false)

  const handleAddPromo = (promo)=> {
    setOpen(false)
    onAddPromocion(promo)
  }

  return (
    <>
      <div className={css.promocionNueva}>
        <Link component="button" variant="body2" onClick={()=> setOpen(true)}>
          Añadir nueva promoción
        </Link>
      </div>
      <ModalNuevoCodigoPromocional
        open={open}
        pago={pago}
        onClose={()=> setOpen(false)}
        onSubmit={handleAddPromo}
      />
    </>
  )
}

const Factura = ({ ingreso })=> {

  const handleViewFactura = ()=> {
    const url = getFactura({ 
      academia: ingreso.centro, 
      numero_factura: ingreso.codigoFactura,
      cliente: ingreso.nombreFactura,
      nif: ingreso.identificacionFactura,
      referencia: ingreso.id,
      fecha_emision: ingreso.fechaFactura,
      concepto: ingreso.conceptoFactura,
      importe: formatForExport(ingreso.importe),
    })
    window.open(url, '_blank')
  }

  if (ingreso.codigoFactura) {
    return (
      <Link className={css.link} component="button" variant="body2" onClick={handleViewFactura}>
        {ingreso.codigoFactura}
      </Link>
    )
  }
  return <Placeholder content="Sin factura" />
}

const PromocionesPago = ({ pago, onDelete })=> {
  if (!pago.codigosPromocionales || pago.codigosPromocionales.length === 0) {
    return <Placeholder content="Sin promociones" />
  }
  return pago.codigosPromocionales.map(promo=> (
    <Chip 
      key={promo}
      className={css.promo}
      label={promo} 
      color="primary" 
      size={onDelete ? "medium" : "small"}
      onDelete={onDelete ? ()=> onDelete(promo) : null}
    />
  ))
}

const EstadoPago = ({ pago })=> {
  const datos = {
    status: 'Pagado',
    content: 'Pagado',
  }
  if (pago.importeRestante > 0) {
    datos.status = 'Pendiente'
    datos.content = `${format(pago.importeRestante)} €`
  }
  if (pago.importeRestante < 0) {
    datos.status = 'Exceso'
    datos.content = `+${format(-pago.importeRestante)} €`
  }
  return (
    <strong 
      className={css.estado} 
      data-status={datos.status}
    >
      {datos.content}
    </strong>
  )
}

const PagoRow = ({ pago, onUpdatePromocion })=> {

  const [isOpen, setOpen] = useState(false)

  const handleUpdatePromociones = (promo)=> {
    onUpdatePromocion({
      id: pago.id,
      tipo: pago.tipo,
      promocion: promo,
    })
  }

  return (
    <>
      <TableRow hover={!isOpen} data-open={isOpen ? 'open' : 'closed'} className={css.row}>
        <TableCell className={css.mainCell}>
          {pago.concepto}
        </TableCell>
        <TableCell className={css.mainCell}>
          {pago.tipo}
        </TableCell>
        <TableCell className={css.mainCell}>
          {pago.fecha}
        </TableCell>
        <TableCell className={css.mainCell}>
          {format(pago.importePago)} €
        </TableCell>
        <TableCell className={css.mainCell}>
          <PromocionesPago pago={pago} />
        </TableCell>
        <TableCell className={css.mainCell}>
          <EstadoPago pago={pago} />
        </TableCell>
        <TableCell className={css.mainCell}>
          <IconButton
            size="small"
            onClick={()=> setOpen(!isOpen)}
          >
            <i className='material-icons'>
              {isOpen ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
            </i>
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow data-open={isOpen ? 'open' : 'closed'} className={css.detailRow}>
        <TableCell className={css.additionalRow} colSpan={7}>
          <Collapse in={isOpen} timeout="auto" unmountOnExit>
            <Typography variant="body1" className={css.subtitle}>
              Códigos promocionales
            </Typography>
            <div className={css.promociones}>
              <PromocionesPago 
                pago={pago} 
                onDelete={handleUpdatePromociones} 
              />
              <NuevaPromocion 
                pago={pago} 
                onAddPromocion={handleUpdatePromociones} 
              />
            </div>
            <Typography variant="body1" className={css.subtitle}>
              Ingresos
            </Typography>
            <div className={css.ingresos}>
              {pago.ingresos.length === 0 ? (
                <Placeholder content="Este pago no tiene ingresos asociados" />
              ) : (
                <div className={css.ingresosTable}>
                  <InformationTable
                    details={[
                      { title: 'Concepto', key: 'concepto', sortDisabled: true },
                      { title: 'Forma pago', key: 'formaPago', sortDisabled: true },
                      { title: 'Fecha pago', key: 'fecha', sortDisabled: true },
                      { title: 'Centro', key: 'centro', sortDisabled: true },
                      { title: 'Importe', key: 'importe', sortDisabled: true },
                      { title: 'Factura', key: 'factura', sortDisabled: true },
                    ]}
                    data={pago.ingresos.map((ingreso) => ({
                      concepto: ingreso.concepto,
                      formaPago: ingreso.formaPago,
                      fecha: `${ingreso.fecha} ${ingreso.hora}`,
                      centro: ingreso.centro,
                      importe: `${format(ingreso.importe)} €`,
                      factura: <Factura ingreso={ingreso} />,
                    }))}
                  />
                </div>
              )}
            </div>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}

export default PagoRow