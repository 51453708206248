import { useNavigate } from 'react-router-dom'
import PageDropdown from '../PageDropdown/PageDropdown'
import useAlumno from '../../hooks/useAlumno'
import css from './AlumnoHeader.module.css'

const AlumnoHeader = ({ label }) => {

  const navigate = useNavigate()

  const { id, nombre } = useAlumno()

  const handleNavigate = (path) => ()=> {
    navigate(`/alumnos/${id}/${path}`)
  }

  const pages = [
    { name: 'Ficha', icon: 'person', onClick: handleNavigate('ficha') },
    { name: 'Histórico', icon: 'fact_check', onClick: handleNavigate('historico') },
    { name: 'Asistencias', icon: 'auto_stories', onClick: handleNavigate('asistencias') },
    { name: 'Contabilidad', icon: 'payments', onClick: handleNavigate('contabilidad') },
    { name: 'Foto', icon: 'photo_camera', onClick: handleNavigate('foto') },
    { name: 'Notas', icon: 'note_alt', onClick: handleNavigate('notas') },
  ]

  return (
    <header className={css.header}>
      <div className={css.headerIcon}>
        <i className='material-icons'>{pages.find(page=> page.name === label).icon}</i>
      </div>
      <PageDropdown
        label={`${label} de ${nombre || 'alumno'}`}
        pages={pages}
      />
    </header>
  )
}

export default AlumnoHeader