import { useQuery } from '@tanstack/react-query'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import { getCodigosPromocionalesHabilitados } from '../../../utils/api/codigoPromocional'
import { useNotification } from '../../../context/NotificationManager/NotificationManager'
import css from './CodigoPromocionalSelector.module.css'

const CodigoPromocionalSelector = ({
  name,
  label,
  className = '',
  value,
  showEmpty = true,
  onChange
}) => {

  const notification = useNotification()

  const { data: codigosPromocionales = [] } = useQuery({
    queryKey: ['codigo-promocional', 'list', 'enabled'], 
    queryFn: () => getCodigosPromocionalesHabilitados()
      .then(datos => datos)
      .catch(e=> {
        notification.error({ title: 'Error al obtener códigos promocionales', content: e })
        return []
      })
  })

  const listaCodigos = codigosPromocionales.map(codigo => codigo.nombre).sort()
  
  return (
    <FormControl
      variant='standard'
      className={className}
      fullWidth
    >
      <InputLabel>{label}</InputLabel>
      <Select
        name={name}
        labelId={name}
        value={value}
        onChange={onChange}
        label={label}
      >
        {showEmpty && (
          <MenuItem className={css.empty} value=''>
            Sin promoción
          </MenuItem>
        )}
        {listaCodigos.map((option, i) => (
          <MenuItem key={i} value={option}>
            {option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default CodigoPromocionalSelector
