import { useState } from "react"
import { dateToString } from "../../../../../utils/date"
import { IconButton, Link } from "@mui/material"
import { useQuery } from "@tanstack/react-query"
import { useNotification } from "../../../../../context/NotificationManager/NotificationManager"
import InformationTable from "../../../../../components/Table/InformationTable"
import PageLoading from "../../../../../components/PageLoading/PageLoading"
import TableHeader from "../../../../../components/TableHeader/TableHeader"
import { dateSort, numberSort, textFilter } from "../../../../../utils/table"
import { downloadCSVFile, getCSVContent } from "../../../../../utils/csv"
import { format, formatForExport } from "../../../../../utils/math"
import { getPagosPendientesMes } from "../../../../../utils/api/contabilidad"
import css from './Pagos.module.css'

const Pagos = ({ mes })=> {

  const notification = useNotification()

  const [search, setSearch] = useState('')

  const { isLoading, data: pagoList=[] } = useQuery({
    queryKey: ['contabilidad', 'pago', 'list', 'pending', 'month', mes], 
    queryFn: ()=> getPagosPendientesMes(mes)
      .then(datos=> datos.map(pago=> ({
        ...pago,
        fecha: dateToString(pago.fecha),
      })))
      .catch(err=> {
        notification.error({ title: 'Error al recuperar los pagos pendientes', content: err })
        return []
      })
  })

  const handleExport = ()=> {
    const content = getCSVContent({
      columns: [
        'Alumno',
        'Fecha',
        'Concepto',
        'Importe',
        'Importe Pendiente',
        'Tipo de Pago',
      ],
      data: pagoList,
      transform: c=> ([
        c.alumno,
        c.fecha,
        c.concepto,
        formatForExport(c.importePago),
        formatForExport(c.importeRestante),
        c.tipoPago,
      ])
    })
    downloadCSVFile(content, `pagos-${mes}`)
  }

  const filterSearch = pago=> {
    return textFilter({ 
      object: pago, 
      fields: ['alumno', 'fecha', 'concepto', 'tipoPago', 'importe', 'importeRestante'],
      search: search
    })
  }

  return (
    <PageLoading isLoading={isLoading}>
      <TableHeader
        actions={(
          <div className={css.actions}>
            <IconButton className={css.download} size='small' color='primary' onClick={handleExport}>
              <i className='material-icons'>download</i>
            </IconButton>
          </div>
        )}
        title='Pagos pendientes de cobro'
        search={search}
        onSearchChange={e=> setSearch(e.target.value)}
      />
      <InformationTable
        details={[
          { title: 'Alumno', key: 'alumno', sortKey: 'alumnoNombre' },
          { title: 'Fecha', key: 'fecha', sortFunction: dateSort },
          { title: 'Importe', key: 'importe', sortFunction: numberSort },
          { title: 'Importe restante', key: 'importeRestante', sortFunction: numberSort },
          { title: 'Tipo de pago', key: 'tipoPago' },
          { title: 'Concepto', key: 'concepto' },
        ]}
        data={pagoList.filter(filterSearch).map((pago) => ({
          alumno: (
            <Link
              className={css.link}
              href={`/alumnos/${pago.alumnoId}/ficha`}
            >
              {pago.alumno}
            </Link>
          ),
          alumnoNombre: pago.alumno,
          fecha: pago.fecha,
          importe: `${format(pago.importePago)} €`,
          importeRestante: (
            <span 
              className={css.deuda} 
              data-status={pago.importeRestante > 0 ? 'deuda' : 'pagado'}
            >
              {format(pago.importeRestante)} €
            </span>
          ),
          tipoPago: pago.tipo,
          concepto: pago.concepto,
        }))}
        isFetching={isLoading}
      />
    </PageLoading>
  )
}

export default Pagos