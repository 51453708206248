import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import { format } from '../../../utils/math'
import css from './VolumenHorasPackSelector.module.css'

const VolumenHorasPackSelector = ({
  className = '',
  pack,
  name,
  label,
  value,
  onChange,
}) => {

  const preciosHora = (pack && pack.preciosHora) || {}

  const opciones = Object.keys(preciosHora)
    .map(volumen => ({
      horas: volumen,
      precio: preciosHora[volumen],
    }))
    .sort((o1, o2) => o1.horas - o2.horas)

  return (
    <FormControl
      className={`${className} ${css.main}`}
      variant='standard'
      fullWidth
    >
      <InputLabel id={name}>{label}</InputLabel>
      <Select
        name={name}
        labelId={name}
        id={name}
        value={value}
        onChange={onChange}
        label={label}
      >
        {opciones.map(opcion => (
          <MenuItem key={opcion.horas} value={opcion.horas}>
            {opcion.horas} horas ({format(opcion.precio)} €)
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default VolumenHorasPackSelector
