import { useState } from 'react'
import { Alert, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import CurrencyInput from '../../components/form/CurrencyInput/CurrencyInput'
import { isBlank } from '../../utils/string'
import css from './ModalCerrarCaja.module.css'

const ModalCerrarCaja = ({
  open,
  balanceTeorico,
  onClose,
  onSubmit,
})=> {

  const [importe, setImporte] = useState('')

  const isImporteCorrecto = +importe === balanceTeorico

  const isSubmitDisabled = isBlank(importe)

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth='sm'>
      <DialogTitle>Cerrar caja</DialogTitle>
      <DialogContent className={css.main}>
        <DialogContentText className={css.intro}>
          Estás a punto de cerrar la caja. Una vez cerrada, no se podrán realizar más operaciones durante el día.
          <br />
        </DialogContentText>
        {!isBlank(importe) && !isImporteCorrecto && (
          <Alert className={css.alert} severity='warning'>
            El importe marcado no coincide con el balance teórico de la caja. Revisa los ingresos realizados.
          </Alert>
        )}
        <CurrencyInput
          className={css.input}
          name='importe'
          label='Balance final de caja'
          value={importe}
          onChange={value=> setImporte(value)}
        />
      </DialogContent>
      <DialogActions>
        <Button color='error' onClick={onClose}>
          Cancelar
        </Button>
        <Button 
          color='primary' 
          disabled={isSubmitDisabled}
          onClick={()=> onSubmit({ importe })}
        >
          Cerrar caja
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ModalCerrarCaja
