import { useEffect, useState } from "react"
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material"
import AcademiaSelector from "../../components/form/AcademiaSelector/AcademiaSelector"
import { isBlank } from "../../utils/string"
import css from './ModalEditarCentroAsistencias.module.css'


const ModalEditarCentroAsistencias = ({ open, onSubmit, onClose }) => {

  const [centro, setCentro] = useState('')
  
  useEffect(()=> {
    setCentro('')
  }, [open])

  const handleSubmit = () => {
    onSubmit({ centro })
  }

  const isSubmitDisabled = isBlank(centro)

  return (
    <Dialog onClose={onClose} open={open} fullWidth maxWidth='md'>
      <DialogTitle>Cambiar centro</DialogTitle>
      <DialogContent className={css.main}>
        <DialogContentText className={css.intro}>
          Elige a continuación que centro quieres asignar. Se cambiará para todas las asistencias seleccionadas.
        </DialogContentText>
        <AcademiaSelector
          className={css.input}
          name='academia'
          label='Academia'
          value={centro}
          onChange={e => setCentro(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button color='error' onClick={onClose}>
          Cancelar
        </Button>
        <Button color='primary' disabled={isSubmitDisabled} onClick={handleSubmit}>
          Cambiar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ModalEditarCentroAsistencias