import { useState } from "react"
import MesRecienteSelector from "../../components/form/MesRecienteSelector/MesRecienteSelector"
import { monthToString } from "../../utils/date"
import { Typography } from "@mui/material"
import { getAuth } from "../../utils/auth"
import Tabs from "../../components/Tabs/Tabs"
import Cajas from "./tabs/Cajas/Cajas"
import Ventas from "./tabs/Ventas/Ventas"
import Ingresos from "./tabs/Ingresos/Ingresos"
import css from './Contabilidad.module.css'

const Contabilidad = ()=> {


  const userData = getAuth()

  const [mes, setMes] = useState(monthToString(new Date()))

  return (
    <>
      <div className={css.header}>
        <Typography variant='h6'>Contabilidad de {userData.centro}</Typography>
        <MesRecienteSelector
          className={css.mes}
          name='mes'
          label='Mes'
          value={mes}
          forwardOptions={2}
          backwardOptions={6}
          onChange={e=> setMes(e.target.value)}
        />
      </div>
      <Tabs
        className={css.tabs}
        tabs={[
          { name: 'Ventas', content: <Ventas mes={mes} />},
          { name: 'Ingresos', content: <Ingresos mes={mes} /> },
          { name: 'Cajas', content: <Cajas mes={mes} /> },
        ]}
      />
    </>
  )
}

export default Contabilidad
