import { useEffect, useState } from 'react'
import { Alert, AlertTitle, Button, Card, CardContent, FormControl, FormControlLabel, Radio, RadioGroup, TextField, Typography } from '@mui/material'
import LeadContactoSelector from '../../components/form/LeadContactoSelector/LeadContactoSelector'
import CursoSelector from '../../components/form/CursoSelector/CursoSelector'
import NivelEstudiosSelector from '../../components/form/NivelEstudiosSelector/NivelEstudiosSelector'
import CentroEstudiosSelector from '../../components/form/CentroEstudiosSelector/CentroEstudiosSelector'
import AsignaturaSelector from '../../components/form/AsignaturaSelector/AsignaturaSelector'
import ComoConoceSelector from '../../components/form/ComoConoceSelector/ComoConoceSelector'
import AcademiaSelector from '../../components/form/AcademiaSelector/AcademiaSelector'
import FormaContactoSelector from '../../components/form/FormaContactoSelector/FormaContactoSelector'
import ObjetivoAlumnoSelector from '../../components/form/ObjetivoAlumnoSelector/ObjetivoAlumnoSelector'
import FechaSelector from '../../components/form/FechaSelector/FechaSelector'
import HoraSelector from '../../components/form/HoraSelector/HoraSelector'
import HorarioSelector from '../../components/form/HorarioSelector/HorarioSelector'
import CausaPerdidoSelector from '../../components/form/CausaPerdidoSelector/CausaPerdidoSelector'
import NumberInput from '../../components/form/NumberInput/NumberInput'
import { validarLead } from '../../utils/model/leads'
import { verificarLead, crearLead } from '../../utils/api/leads'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useNotification } from '../../context/NotificationManager/NotificationManager'
import { Link, useNavigate } from 'react-router-dom'
import { dateToString, dateToTimeString } from '../../utils/date'
import css from './NuevoLead.module.css'

const DatosContactoCard = ({ lead, onChange }) => (
  <Card className={css.card}>
    <CardContent className={css.cardContent}>
      <Typography variant='h6' className={css.cardTitle}>
        Datos de contacto
      </Typography>
      <LeadContactoSelector
        name='contacto'
        className={css.input}
        value={lead.personaContacto}
        onChange={(_event, value) => onChange('personaContacto', value)}
      />
      <TextField
        className={css.input}
        label='Nombre de contacto'
        value={lead.nombreContacto}
        onChange={e => onChange('nombreContacto', e.target.value)}
        variant='standard'
      />
      <TextField
        className={css.input}
        label='Email de contacto'
        value={lead.emailContacto}
        onChange={e => onChange('emailContacto', e.target.value)}
        type='email'
        variant='standard'
      />
      <TextField
        className={css.input}
        label='Teléfono de contacto'
        value={lead.telefonoContacto}
        onChange={e => onChange('telefonoContacto', e.target.value)}
        variant='standard'
      />
    </CardContent>
  </Card>
)

const DatosAlumnoCard = ({ lead, onChange }) => (
  <Card className={css.card}>
    <CardContent className={css.cardContent}>
      <Typography variant='h6' className={css.cardTitle}>
        Datos del alumno
      </Typography>
      <TextField
        className={css.input}
        label='Nombre del alumno'
        value={lead.nombreAlumno}
        onChange={e => onChange('nombreAlumno', e.target.value)}
        variant='standard'
        disabled={lead.personaContacto === 'Propio alumno'}
      />
      <TextField
        className={css.input}
        label='Email del alumno'
        value={lead.emailAlumno}
        onChange={e => onChange('emailAlumno', e.target.value)}
        type='email'
        variant='standard'
        disabled={lead.personaContacto === 'Propio alumno'}
      />
      <TextField
        className={css.input}
        label='Teléfono del alumno'
        value={lead.telefonoAlumno}
        onChange={e => onChange('telefonoAlumno', e.target.value)}
        variant='standard'
        disabled={lead.personaContacto === 'Propio alumno'}
      />
      <NumberInput
        className={css.input}
        label='Código postal'
        value={lead.codigoPostal}
        onChange={value=> onChange('codigoPostal', value)}
      />
    </CardContent>
  </Card>
)

const DatosAcademicosCard = ({ lead, onChange }) => (
  <Card className={css.card}>
    <CardContent className={css.cardContent}>
      <Typography variant='h6' className={css.cardTitle}>
        Datos académicos
      </Typography>
      <CentroEstudiosSelector
        className={css.input}
        name='centroEstudios'
        label='Centro de estudios'
        value={lead.centroEstudios}
        onChange={value=> onChange('centroEstudios', value)}
      />
      <NivelEstudiosSelector
        className={css.input}
        label='Nivel de estudios'
        name='nivel'
        value={lead.nivel}
        onChange={e => onChange('nivel', e.target.value)}
      />
      <CursoSelector
        className={css.input}
        label='Curso'
        name='curso'
        value={lead.curso}
        onChange={e => onChange('curso', e.target.value)}
      />
      <AsignaturaSelector
        className={css.input}
        multiple
        name='asignaturas'
        label='Asignaturas de interés'
        value={lead.asignaturas}
        onChange={(_event, value) => onChange('asignaturas', value.map(a=> a.id))}
      />
    </CardContent>
  </Card>
)

const DatosMarketingCard = ({ lead, onChange }) => (
  <Card className={css.card}>
    <CardContent className={css.cardContent}>
      <Typography variant='h6' className={css.cardTitle}>
        Datos de marketing
      </Typography>
      <AcademiaSelector
        className={css.input}
        name='academia'
        label='Academia'
        value={lead.academia}
        onChange={e => onChange('academia', e.target.value)}
      />
      <ComoConoceSelector
        className={css.input}
        name='comoConoce'
        label='¿Cómo nos ha conocido?'
        value={lead.comoConoce}
        onChange={e => onChange('comoConoce', e.target.value)}
      />
      <ObjetivoAlumnoSelector
        className={css.input}
        name='objetivo'
        label='Objetivo del alumno'
        value={lead.objetivo}
        onChange={e => onChange('objetivo', e.target.value)}
      />
      <FormaContactoSelector
        className={css.input}
        name='formaContacto'
        label='Forma de contacto'
        value={lead.formaContacto}
        onChange={e => onChange('formaContacto', e.target.value)}
      />
      {lead.formaContacto === 'Teléfono' && (
        <TextField
          className={css.input}
          label='Teléfono desde el que llama'
          value={lead.telefonoEntrante}
          onChange={e => onChange('telefonoEntrante', e.target.value)}
          variant='standard'
        />
      )}
    </CardContent>
  </Card>
)

const DatosNextCard = ({ lead, onChange }) => (
  <Card className={css.card}>
    <CardContent className={css.cardContent}>
      <Typography variant='h6' className={css.cardTitle}>
        Siguientes pasos
      </Typography>
      <FormControl className={css.input}>
        <RadioGroup
          className={css.radioButtons}
          name='row-radio-buttons-group'
          value={lead.estadoLead}
          onChange={(_, value) => onChange('estadoLead', value)}
        >
          <FormControlLabel value='LEAD' control={<Radio />} label='Se lo piensa' />
          <FormControlLabel value='PRUEBA' control={<Radio />} label='Viene a prueba' />
          <FormControlLabel value='CLIENTE_PERDIDO' control={<Radio />} label='No le interesa' />
        </RadioGroup>
      </FormControl>
      {lead.estadoLead === 'LEAD' && (
        <>
          <FechaSelector
            className={css.input}
            label='Fecha próximo contacto'
            value={lead.fechaContacto}
            disablePast
            onChange={value => onChange('fechaContacto', value)}
          />
          <HoraSelector
            className={css.input}
            label='Hora próximo contacto'
            value={lead.horaContacto}
            onChange={value => onChange('horaContacto', value)}
          />
        </>
      )}
      {lead.estadoLead === 'PRUEBA' && (
        <>
          <FechaSelector
            className={css.input}
            label='Fecha de la prueba'
            value={lead.fechaPrueba}
            disablePast
            onChange={value => onChange('fechaPrueba', value)}
          />
          <AcademiaSelector
            className={css.input}
            name='academiaPrueba'
            label='Academia de la prueba'
            value={lead.academiaPrueba}
            onChange={e => onChange('academiaPrueba', e.target.value)}
          />
          <HorarioSelector
            className={css.input}
            name='horarioPrueba'
            label='Hora de la prueba'
            centro={lead.academiaPrueba}
            fecha={lead.fechaPrueba}
            value={lead.horaPrueba}
            onChange={(_e, value) => onChange('horaPrueba', value)}
          />
          <AsignaturaSelector
            className={css.input}
            name='asignaturaPrueba'
            label='Asignatura de la prueba'
            value={lead.asignaturaPrueba}
            onChange={(_e, value) => onChange('asignaturaPrueba', value?.id || '')}
          />
        </>
      )}
      {lead.estadoLead === 'CLIENTE_PERDIDO' && (
        <CausaPerdidoSelector
          className={css.input}
          name='motivoDescarte'
          label='Motivo de no seguimiento'
          value={lead.motivoDescarte}
          onChange={e => onChange('motivoDescarte', e.target.value)}
        />
      )}
      {lead.estadoLead && (
        <TextField
          className={css.input}
          label='Observaciones'
          value={lead.observaciones}
          onChange={e => onChange('observaciones', e.target.value)}
          variant='standard'
          multiline
        />
      )}
    </CardContent>
  </Card>
)

const NuevoLead = () => {

  const navigate = useNavigate()
  const notification = useNotification()

  const [lead, setLead] = useState({
    personaContacto: '',

    nombreContacto: '',
    emailContacto: '',
    telefonoContacto: '',

    nombreAlumno: '',
    emailAlumno: '',
    telefonoAlumno: '',
    codigoPostal: '',

    centroEstudios: null,
    nivel: '',
    curso: '',
    asignaturas: [],

    comoConoce: '',
    objetivo: '',
    formaContacto: '',
    academia: '',
    telefonoEntrante: '',

    estadoLead: 'LEAD',
    fechaContacto: null,
    horaContacto: null,
    fechaPrueba: null,
    horaPrueba: null,
    asignaturaPrueba: '',
    academiaPrueba: '',
    motivoDescarte: '',
    observaciones: ''
  })

  const [verificacionLead, setVerificacionLead] = useState(false)

  const leadValido = validarLead(lead)

  useEffect(() => {
    if (lead.personaContacto === 'Propio alumno') {
      setLead(currentLead=> ({
        ...currentLead,
        nombreAlumno: lead.nombreContacto,
        emailAlumno: lead.emailContacto,
        telefonoAlumno: lead.telefonoContacto
      }))
    }
  }, [lead.personaContacto, lead.nombreContacto, lead.emailContacto, lead.telefonoContacto])

  useEffect(() => {
    if (lead.formaContacto !== 'Teléfono') {
      setLead(currentLead => ({
        ...currentLead,
        telefonoEntrante: ''
      }))
    }
  }, [lead.formaContacto])

  useEffect(()=> {
    if (lead.estadoLead === 'LEAD') {
      setLead(currentLead => ({
        ...currentLead,
        fechaPrueba: null,
        horaPrueba: null,
        asignaturaPrueba: '',
        academiaPrueba: '',
        motivoDescarte: ''
      }))
    }
    if (lead.estadoLead === 'PRUEBA') {
      setLead(currentLead => ({
        ...currentLead,
        fechaContacto: null,
        horaContacto: null,
        motivoDescarte: ''
      }))
    }
    if (lead.estadoLead === 'CLIENTE_PERDIDO') {
      setLead(currentLead => ({
        ...currentLead,
        fechaContacto: null,
        horaContacto: null,
        fechaPrueba: null,
        horaPrueba: null,
        asignaturaPrueba: '',
        academiaPrueba: ''
      }))
    }
  }, [lead.estadoLead])

  const { isLoading: isVerificarLoading, data: leadExistente='', isSuccess: verificacionCompletada, status: estadoVerificacion } = useQuery({
    queryKey: ['feedback', 'verificar-lead',  lead.telefonoContacto, lead.emailContacto, lead.telefonoAlumno, lead.emailAlumno],
    enabled: verificacionLead,
    queryFn: ()=> verificarLead({
      email_contacto: lead.emailContacto,
      telefono_contacto: lead.telefonoContacto,
      email_alumno: lead.emailAlumno,
      telefono_alumno: lead.telefonoAlumno
    })
    .then(datos=> datos)
    .catch(err=> {
      notification.error({ title: 'Error al verificar el lead', content: err })
      return ''
    })
  })

  const { isPending: isLeadSubmitting, mutate: createLead } = useMutation({
    mutationFn: crearLead,
    onSuccess: (leadId) => {
      notification.success({ title: 'Lead creado', content: 'Se ha registrado el lead' })
      navigate(`/leads/${leadId}/ficha`)
    },
    onError: err => {
      notification.error({ title: 'Error al registrar el lead', content: err })
    }
  })

  useEffect(()=> {
    if (estadoVerificacion !== 'loading') setVerificacionLead(false)
  }, [estadoVerificacion])

  useEffect(()=> {
    setVerificacionLead(false)
  }, [lead.telefonoContacto, lead.emailContacto, lead.telefonoAlumno, lead.emailAlumno])

  const handleFieldChange = (field, value) => {
    setLead(currentLead => ({
      ...currentLead,
      [field]: value
    }))
  }

  const handleSubmit = () => {
    createLead({
      contacto: lead.personaContacto,
      nombre_contacto: lead.nombreContacto,
      email_contacto: lead.emailContacto,
      telefono_contacto: lead.telefonoContacto,
      nombre_alumno: lead.nombreAlumno,
      email_alumno: lead.emailAlumno,
      telefono_alumno: lead.telefonoAlumno,
      centro_estudios: lead.centroEstudios || '[DESCONOCIDO]',
      asignaturas: lead.asignaturas,
      tipologia_academica: lead.nivel,
      curso: lead.curso,
      objetivos: lead.objetivo,
      como_conoce: lead.comoConoce,
      forma_contacto: lead.formaContacto,
      centro: lead.academia,
      codigo_postal: lead.codigoPostal,
      lead_status: lead.estadoLead,
      fecha_lead: dateToString(lead.fechaContacto),
      hora_lead: dateToTimeString(lead.horaContacto),
      centro_prueba: lead.academiaPrueba,
      fecha_prueba: dateToString(lead.fechaPrueba),
      hora_prueba: lead.horaPrueba,
      asignatura_prueba: lead.asignaturaPrueba || '',
      causa_perdido: lead.motivoDescarte,
      telefono_entrante: lead.telefonoEntrante,
      observaciones: lead.observaciones,
    })
  }

  return (
    <div className={css.main}>
      <Typography variant='h5' className={css.title}>
        Registrar nuevo lead
      </Typography>
      <div className={css.content}>
        <DatosContactoCard lead={lead} onChange={handleFieldChange} />
        <DatosAlumnoCard lead={lead} onChange={handleFieldChange} />
        <DatosAcademicosCard lead={lead} onChange={handleFieldChange} />
        <DatosMarketingCard lead={lead} onChange={handleFieldChange} />
        <DatosNextCard lead={lead} onChange={handleFieldChange} />
      </div>
      <div className={css.submit}>
        {leadValido.warnings.length > 0 && (
          <Alert className={css.infoBanner} severity='warning'>
            <AlertTitle>
              🤖 Hay campos que me han llamado la atención:
            </AlertTitle>
            <ul className={css.bannerList}>
              {leadValido.warnings.map(warning => (
                <li key={warning}>{warning}</li> 
              ))}
            </ul>
          </Alert> 
        )}
        {!leadValido.isValid && (
          <Alert className={css.infoBanner} severity='error'>
            <AlertTitle>
              Faltan datos para poder crear el lead:
            </AlertTitle>
            <ul className={css.bannerList}>
              {leadValido.errors.map(error => (
                <li key={error}>{error}</li> 
              ))}
            </ul>
          </Alert> 
        )}
          <Button
            className={css.button}
            variant='contained'
            color='secondary'
            onClick={() => setVerificacionLead(true)}
            disabled={!leadValido.isValid || isVerificarLoading}
            >
            Verificar lead
          </Button>
          {leadValido.isValid && verificacionCompletada && (
            <>
              {leadExistente ? (
                <Alert className={css.banner} severity='warning'>
                  <AlertTitle>
                    Ya existe un lead con los mismos datos de contacto.
                  </AlertTitle>
                  <Link
                    to={`/leads/${leadExistente}/ficha`}
                    className={css.link}
                    target='_blank'
                  >
                    Revisar ficha del lead
                  </Link>
                </Alert>
              ) : (
                <Alert className={css.banner} severity='success'>
                  No tenemos ningun lead similar en base de datos.
                </Alert> 
              )}
              <Button
                className={css.button}
                variant='contained'
                color='primary'
                onClick={handleSubmit}
                disabled={isLeadSubmitting}
              >
                Registar nuevo lead
              </Button>
            </>
        )}
      </div>
    </div>
  )
}

export default NuevoLead
