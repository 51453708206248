import { getAuth } from './auth'
import config from './config'

const removeEmptyProperties = (obj) => {
  return Object.keys(obj)
    .reduce((acc, key) => {
      if (obj[key] !== null && obj[key] !== undefined) acc[key] = obj[key]
      return acc
    }, {})
}

const getFetchOptions = (method) => ({
  method,
  mode: 'cors',
  cache: 'no-cache',
  credentials: 'same-origin',
  headers: {
    Authorization: `Bearer ${getAuth().token}`
  }
})

const getFetchOptionsWithBody = (method, body) => ({
  method,
  mode: 'cors',
  cache: 'no-cache',
  credentials: 'same-origin',
  headers: {
    Authorization: `Bearer ${getAuth().token}`,
    'Content-Type': 'application/json'
  },
  body: JSON.stringify(body)
})

const processRequest = ({ url, method, body }) => {
  const options = body ? getFetchOptionsWithBody(method, body) : getFetchOptions(method)
  return new Promise((resolve, reject) => {
    fetch(url, options)
      .then(res => res.json())
      .then(res => {
        if (res.resultado === 'ok') {
          if (res.datos !== null && res.datos !== undefined) resolve(res.datos)
          else resolve(res)
        } else reject(res.motivo)
      })
      .catch(() => reject('No se ha podido procesar la petición'))
  })
}

export const GET = (path, data = {}) => {
  const url = config.api + path
  const details = removeEmptyProperties(data)
  const params = new URLSearchParams(details)
  return processRequest({ url: `${url}?${params}`, method: 'GET' })
}

export const POST = (path, data = {}, body) => {
  const url = config.api + path
  const details = removeEmptyProperties(data)
  const params = new URLSearchParams(details)
  return processRequest({ url: `${url}?${params}`, method: 'POST', body })
}

export const PUT = (path, data = {}, body) => {
  const url = config.api + path
  const details = removeEmptyProperties(data)
  const params = new URLSearchParams({ ...details, _method: 'put' })
  return processRequest({ url: `${url}?${params}`, method: 'POST', body })
}

export const DELETE = (path, data = {}, body) => {
  const url = config.api + path
  const details = removeEmptyProperties(data)
  const params = new URLSearchParams({ ...details, _method: 'delete' })
  return processRequest({ url: `${url}?${params}`, method: 'POST', body })
}

export const getBasePath = () => config.api
