import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import css from './TipoClaseSelector.module.css'

const TIPOS_TARIFA = [
  { label: 'Normal', value: 'NORMAL' },
  { label: 'Promoción', value: 'PROMOCION' },
  { label: 'Gratuita', value: 'GRATUITA' },
  { label: 'Cambio', value: 'CAMBIO' },
  { label: 'Recuperación', value: 'RECUPERACION' },
  { label: 'Extra', value: 'EXTRA' },
  { label: 'Prueba', value: 'PRUEBA' },
]

const TIPOS_PACK = [
  { label: 'Pack', value: 'PACK' }, 
  { label: 'Pack (Promoción)', value: 'PACK_PROMOCION' },
]

const getOpciones = contratacion => {
  if (contratacion === 'tarifa') return TIPOS_TARIFA
  if (contratacion === 'pack') return TIPOS_PACK
  else return [ ...TIPOS_TARIFA, ...TIPOS_PACK ]
}

const TipoClaseSelector = ({
  className,
  contratacion,
  name,
  label,
  value,
  onChange,
}) => (
  <FormControl
    className={`${className} ${css.main}`}
    variant='standard'
    margin='dense'
    fullWidth
  >
    <InputLabel id={name}>{label}</InputLabel>
    <Select
      name={name}
      labelId={name}
      id={name}
      value={value}
      onChange={onChange}
      label={label}
    >
      {getOpciones(contratacion).map(tipo => (
        <MenuItem key={tipo.value} value={tipo.value}>
          {tipo.label}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
)

export default TipoClaseSelector
