import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import css from './ObjetivoAlumnoSelector.module.css'

const OBJETIVOS = [
  'No aplicable',
  'Aprobar una asignatura',
  'Aprobar un curso escolar',
  'Aprobar selectividad',
  'Mejorar un idioma',
  'Mejorar en una asignatura',
  'Aprobar un examen específico',
  'Mejorar en técnicas de estudio',
  'Nivelación',
  'Otros'
]

const ObjetivoAlumnoSelector = ({
  className,
  name,
  label,
  value,
  onChange,
}) => (
  <FormControl
    className={`${className} ${css.main}`}
    variant='standard'
    margin='dense'
    fullWidth
  >
    <InputLabel id={name}>{label}</InputLabel>
    <Select
      name={name}
      labelId={name}
      id={name}
      value={value}
      onChange={onChange}
      label={label}
    >
      {OBJETIVOS.map(objetivo => (
        <MenuItem key={objetivo} value={objetivo}>
          {objetivo}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
)

export default ObjetivoAlumnoSelector
