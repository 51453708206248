import { useEffect, useState } from "react"
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@mui/material"
import { areBlank, isBlank } from "../../utils/string"
import TutorSelector from "../../components/form/TutorSelector/TutorSelector"
import css from './ModalEditarTutoresAlumno.module.css'

const ModalEditarTutoresAlumno = ({ open, alumno, onSubmit, onClose }) => {

  const [tutor1, setTutor1] = useState({
    rol: '',
    nombre: '',
    email: '',
    telefono: '',
    dni: '',
  })

  const [tutor2, setTutor2] = useState({
    rol: '',
    nombre: '',
    email: '',
    telefono: '',
    dni: ''
  })

  useEffect(()=> {
    if (alumno.tutor1) {
      setTutor1({
        rol: alumno.tutor1.rol,
        nombre: alumno.tutor1.nombre,
        email: alumno.tutor1.email,
        telefono: alumno.tutor1.telefono,
        dni: alumno.tutor1.dni
      })
    }
    if (alumno.tutor2) {
      setTutor2({
        rol: alumno.tutor2.rol,
        nombre: alumno.tutor2.nombre,
        email: alumno.tutor2.email,
        telefono: alumno.tutor2.telefono,
        dni: alumno.tutor2.dni
      })
    }
  }, [alumno.tutor1, alumno.tutor2])

  const isSubmitEnabled = (
    (areBlank(tutor1.rol, tutor1.nombre, tutor1.email, tutor1.telefono, tutor1.dni) || (!isBlank(tutor1.nombre) && !isBlank(tutor1.rol)))
    && 
    (areBlank(tutor2.rol, tutor2.nombre, tutor2.email, tutor2.telefono, tutor2.dni) || (!isBlank(tutor2.nombre) && !isBlank(tutor2.rol)))
  )

  const handleSubmit = () => {
    const datos = {
      id: alumno.id,
    }
    if (tutor1.rol && tutor1.nombre) {
      datos.tutor1Rol = tutor1.rol
      datos.tutor1Nombre = tutor1.nombre
      datos.tutor1Email = tutor1.email
      datos.tutor1Movil = tutor1.telefono
      datos.tutor1DNI = tutor1.dni
    }
    if (tutor2.rol && tutor2.nombre) {
      datos.tutor2Rol = tutor2.rol
      datos.tutor2Nombre = tutor2.nombre
      datos.tutor2Email = tutor2.email
      datos.tutor2Movil = tutor2.telefono
      datos.tutor2DNI = tutor2.dni
    }
    onSubmit(datos)
  }

  return (
    <Dialog onClose={onClose} open={open} fullWidth maxWidth='md'>
      <DialogTitle>Editar datos de tutores</DialogTitle>
      <DialogContent className={css.main}>
        <DialogContentText className={css.intro}>
          Modifica los datos de los tutores de este alumno
        </DialogContentText>
        <div className={css.content}>
          <section className={css.section}>
            <TutorSelector
              className={css.input}
              name='tutor1'
              value={tutor1.rol}
              onChange={(_e, value)=> setTutor1({ ...tutor1, rol: value })}
              fullWidth
            />
            <TextField
              className={css.input}
              variant='standard'
              label="Nombre"
              value={tutor1.nombre}
              onChange={e=> setTutor1({ ...tutor1, nombre: e.target.value })}
            />
            <TextField
              className={css.input}
              variant='standard'
              label="E-mail"
              value={tutor1.email}
              onChange={e=> setTutor1({ ...tutor1, email: e.target.value })}
            />
            <TextField
              className={css.input}
              variant='standard'
              label="Teléfono"
              value={tutor1.telefono}
              onChange={e=> setTutor1({ ...tutor1, telefono: e.target.value })}
            />
            <TextField
              className={css.input}
              variant='standard'
              label="DNI"
              value={tutor1.dni}
              onChange={e=> setTutor1({ ...tutor1, dni: e.target.value })}
            />
          </section>
          <section className={css.section}>
            <TutorSelector
              className={css.input}
              name='tutor2'
              value={tutor2.rol}
              onChange={(_e, value)=> setTutor2({ ...tutor2, rol: value })}
              fullWidth
            />
            <TextField
              className={css.input}
              variant='standard'
              label="Nombre"
              value={tutor2.nombre}
              onChange={e=> setTutor2({ ...tutor2, nombre: e.target.value })}
            />
            <TextField
              className={css.input}
              variant='standard'
              label="E-mail"
              value={tutor2.email}
              onChange={e=> setTutor2({ ...tutor2, email: e.target.value })}
            />
            <TextField
              className={css.input}
              variant='standard'
              label="Teléfono"
              value={tutor2.telefono}
              onChange={e=> setTutor2({ ...tutor2, telefono: e.target.value })}
            />
            <TextField
              className={css.input}
              variant='standard'
              label="DNI"
              value={tutor2.dni}
              onChange={e=> setTutor2({ ...tutor2, dni: e.target.value })}
            />
          </section>
        </div>
      </DialogContent>
      <DialogActions>
        <Button color='error' onClick={onClose}>Cancelar</Button>
        <Button disabled={!isSubmitEnabled} color='primary' onClick={handleSubmit}>Actualizar</Button>
      </DialogActions>
    </Dialog>
  )
}

export default ModalEditarTutoresAlumno