import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import css from './TipoAsistenciaSelector.module.css'

const TIPOS = [
  { label: 'Viene', value: 'VIENE' },
  { label: 'Falta', value: 'FALTA' },
  { label: 'Falta Justificada', value: 'FALTA_JUSTIFICADA' },
  { label: 'Por Definir', value: 'POR_DEFINIR' },
]

const TipoAsistenciaSelector = ({
  className,
  name,
  label,
  value,
  onChange,
}) => (
  <FormControl
    className={`${className} ${css.main}`}
    variant='standard'
    margin='dense'
    fullWidth
  >
    <InputLabel id={name}>{label}</InputLabel>
    <Select
      name={name}
      labelId={name}
      id={name}
      value={value}
      onChange={onChange}
      label={label}
    >
      {TIPOS.map(tipo => (
        <MenuItem key={tipo.value} value={tipo.value}>
          {tipo.label}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
)

export default TipoAsistenciaSelector
