import { useState } from 'react'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { IconButton, Typography } from '@mui/material'
import { useNotification } from '../../../../context/NotificationManager/NotificationManager'
import { createFactura, getIngresosAlumno, updateFormaPago } from '../../../../utils/api/ingresos'
import { dateFromString, dateToMonth, dateToString, getToday } from '../../../../utils/date'
import PageLoading from '../../../../components/PageLoading/PageLoading'
import InformationTable from '../../../../components/Table/InformationTable'
import FormaPagoSelector from '../../../../components/form/FormaPagoSelector/FormaPagoSelector'
import ModalNuevaFactura from '../../../../modals/ModalNuevaFactura/ModalNuevaFactura'
import useAlumno from '../../../../hooks/useAlumno'
import { format, formatForExport } from '../../../../utils/math'
import { dateSort, numberSort } from '../../../../utils/table'
import { getFactura, getJustificantePago } from '../../../../utils/documents'

const Ingresos = ()=> {
  
  const { id } = useAlumno()
  const notification = useNotification()
  const queryClient = useQueryClient()

  const [ingresoSeleccionado, setIngresoSeleccionado] = useState(null)
  const [nuevaFacturaOpen, setNuevaFacturaOpen] = useState(false)

  const { data: alumnoIngresos = [], isFetching: cargandoIngresos } = useQuery({
    queryKey: ['alumnos', 'contabilidad', 'ingreso-alumno', 'list', 'alumno', 'history', id], 
    queryFn: () => getIngresosAlumno(id)
      .then(datos=> datos
          .sort((a, b)=> a.fecha < b.fecha ? 1 : -1)
          .map(ingreso=> ({
            ...ingreso,
            fecha: dateToString(ingreso.fecha),
            fechaFactura: dateToString(ingreso.fechaFactura),
          }))
      )
      .catch(err=> {
        notification.error({ title: 'Error obteniendo los ingresos', content: err })
        return []
      })
  })

  const { isPending: isFacturaCreating, mutate: generarFactura } = useMutation({
    mutationFn: createFactura,
    onSuccess: ()=> {
      notification.success({ title: 'Factura creada', content: 'Se ha creado la factura correctamente' })
      queryClient.invalidateQueries(['alumnos', 'contabilidad', 'ingreso-alumno', 'list', 'alumno', 'history', id])
      setNuevaFacturaOpen(false)
    },
    onError: (err)=> {
      notification.error({ title: 'Error creando factura', content: err })
    }
  })

  const { isPending: isFormaPagoUpdating, mutate: actualizarFormaPago } = useMutation({
    mutationFn: updateFormaPago,
    onSuccess: ()=> {
      notification.success({
        title: 'Forma de pago actualizada',
        content: 'Se ha actualizado la forma de pago del alumno',
      })
      queryClient.invalidateQueries(['alumnos', 'contabilidad', 'ingreso-alumno', 'list', 'alumno', 'history', id])
    },
    onError: (err)=> {
      notification.error({ title: 'Error al actulizar forma de pago', content: err })
    }
  })

  const handleViewJustificante = ingreso => {
    const url = getJustificantePago({
      academia: ingreso.centro,
      nombre: ingreso.alumno.split(' ')[0],
      alumno: ingreso.alumno,
      referencia: ingreso.id,
      mes: dateToMonth(dateFromString(ingreso.fecha)),
      fecha_emision: ingreso.fecha,
      importe: formatForExport(ingreso.importe),
    })
    window.open(url, '_blank')
  }

  const handleViewFactura = ingreso => {
    const url = getFactura({ 
      academia: ingreso.centro, 
      numero_factura: ingreso.codigoFactura,
      cliente: ingreso.nombreFactura,
      nif: ingreso.identificacionFactura,
      referencia: ingreso.id,
      fecha_emision: ingreso.fechaFactura,
      concepto: ingreso.conceptoFactura,
      importe: formatForExport(ingreso.importe),
    })
    window.open(url, '_blank')
  }

  const handleOpenModal = ingreso => {
    setIngresoSeleccionado(ingreso)
    setNuevaFacturaOpen(true)
  }

  const handleCloseModal = () => {
    setIngresoSeleccionado(null)
    setNuevaFacturaOpen(false)
  }

  const handleEditFormaPago = (ingreso, event) => {
    const forma_pago = event.target.value
    if (isFormaPagoUpdating) return
    actualizarFormaPago({ id: ingreso.id, forma_pago })
  }

  const handleCreateFactura = () => {
    if (isFacturaCreating) return
    generarFactura({ ingreso: ingresoSeleccionado.id })
  }

  const isIngresoEditable = ingreso => ingreso.fecha === dateToString(getToday())

  return (
    <PageLoading isLoading={cargandoIngresos}>
      <Typography variant='h6' align='center' padding='20px'>
        Ingresos realizados
      </Typography>
      <InformationTable
        size='small'
        details={[
          { title: 'Fecha', key: 'fecha', sortFunction: dateSort },
          { title: 'Concepto', key: 'concepto' },
          { title: 'Forma pago', key: 'formaPago' },
          { title: 'Centro', key: 'centro' },
          { title: 'Importe', key: 'importe', sortFunction: numberSort },
          { title: 'Opciones', key: 'opciones', align: 'center', sortDisabled: true }
        ]}
        data={alumnoIngresos.map(ingreso => ({
          fecha: ingreso.fecha,
          concepto: ingreso.concepto || '--',
          formaPago: isIngresoEditable(ingreso) ? (
            <FormaPagoSelector
              value={ingreso.formaPago}
              onChange={event => handleEditFormaPago(ingreso, event)}
              fullWidth
            />
          ) : ingreso.formaPago,
          centro: ingreso.centro || '--',
          importe: `${format(ingreso.importe)} €`,
          opciones: (
            <>
              <IconButton
                color='primary'
                size='small'
                onClick={() => handleViewJustificante(ingreso)}
              >
                <i className='material-icons'>attach_file</i>
              </IconButton>
              {ingreso.codigoFactura && (
                <IconButton
                  color='primary'
                  size='small'
                  onClick={() => handleViewFactura(ingreso)}
                >
                  <i className='material-icons'>picture_as_pdf</i>
                </IconButton>
              )}
              {!ingreso.codigoFactura && (
                <IconButton
                  color='secondary'
                  size='small'
                  onClick={() => handleOpenModal(ingreso)}
                >
                  <i className='material-icons'>post_add</i>
                </IconButton>
              )}
            </>
          )
        }))}
      />
      <ModalNuevaFactura
        open={nuevaFacturaOpen}
        onClose={handleCloseModal}
        onSubmit={handleCreateFactura}
      />
    </PageLoading>
  )
}


export default Ingresos
