import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import AlumnoHeader from "../../../components/AlumnoHeader/AlumnoHeader"
import PageLoading from "../../../components/PageLoading/PageLoading"
import useAlumno from "../../../hooks/useAlumno"
import { useNotification } from "../../../context/NotificationManager/NotificationManager"
import { createPago, getPagosAlumno, updateCodigoPromocional } from "../../../utils/api/contabilidad"
import { dateToString } from "../../../utils/date"
import { assignIngreso, getIngresosAlumno } from "../../../utils/api/ingresos"
import { Fab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material"
import PagoRow from "./components/PagoRow/PagoRow"
import IngresoRow from "./components/IngresoRow/IngresoRow"
import { useState } from "react"
import ModalNuevoPago from "../../../modals/ModalNuevoPago/ModalNuevoPago"
import { getTipoPago } from "../../../utils/model/contabilidad"
import css from './Contabilidad.module.css'

const Contabilidad = ()=> {

  const { id } = useAlumno()
  const notification = useNotification()
  const queryClient = useQueryClient()

  const [isPagoOpen, setPagoOpen] = useState(false)

  const { isLoading: pagosLoading, data: pagoList=[] } = useQuery({
    queryKey: ['contabilidad', 'pago', 'list', 'alumno', id],
    queryFn: ()=> getPagosAlumno({ alumno: id })
      .then(datos=> datos
        .sort((a,b)=> a.fecha < b.fecha ? 1 : -1)
        .map(pago=> ({
          ...pago,
          fecha: dateToString(pago.fecha),
          ingresos: pago.ingresos
            .sort((a,b)=> a.fecha < b.fecha ? 1 : -1)
            .map(ingreso=> ({
              ...ingreso,
              fecha: dateToString(ingreso.fecha),
              fechaFactura: dateToString(ingreso.fechaFactura),
            }))
        }))
      )
      .catch(err=> {
        notification.error({ title: 'Error al recuperar los pagos', content: err })
        return []
      })
  })

  const { isLoading: ingresosLoading, data: ingresoList=[] } = useQuery({
    queryKey: ['contabilidad', 'ingreso-alumno', 'list', 'alumno', id],
    queryFn: ()=> getIngresosAlumno(id) 
      .then(datos=> datos.map(ingreso=> ({
        ...ingreso,
        fecha: dateToString(ingreso.fecha),
        fechaFactura: dateToString(ingreso.fechaFactura),
      })))
      .catch(err=> {
        notification.error({ title: 'Error al recuperar los ingresos', content: err })
        return []
      })
  })

  const { isPending: isPagoCreating, mutate: crearPago } = useMutation({
    mutationFn: createPago,
    onSuccess: ()=> {
      notification.success({ title: 'Pago registrado', content: 'Se ha registrado el pago del alumno' })
      queryClient.invalidateQueries({ 
        queryKey: ['contabilidad', 'pago', 'list', 'alumno', id]
      })
      setPagoOpen(false)
    },
    onError: (err)=> {
      notification.error({ title: 'Error procesando pago', content: err })
    }
  })

  const { isPending: isIngresoAssigning, mutate: asignarIngreso } = useMutation({
    mutationFn: assignIngreso,
    onSuccess: ()=> {
      notification.success({ title: 'Ingreso asignado', content: 'Se ha asignado el ingreso al pago' })
      queryClient.invalidateQueries({ 
        queryKey: ['contabilidad', 'pago', 'list', 'alumno', id]
      })
      queryClient.invalidateQueries({ 
        queryKey: ['contabilidad', 'ingreso-alumno', 'list', 'alumno', id] 
      })
    },
    onError: (err)=> {
      notification.error({ title: 'Error asignando ingreso', content: err })
    }
  })

  const { isPending: isPromoAssigning, mutate: asignarPromocion } = useMutation({
    mutationFn: updateCodigoPromocional,
    onSuccess: ()=> {
      queryClient.invalidateQueries({ 
        queryKey: ['contabilidad', 'pago', 'list', 'alumno', id]
      })
    },
    onError: (err)=> {
      notification.error({ title: 'Error con la promoción', content: err })
    }
  })

  const pagosPendientesList = pagoList.filter(pago=> pago.importeRestante > 0)

  const ingresosSinAsignarList = ingresoList.filter(ingreso=> {
    const pagos = pagoList.filter(pago=> pago.ingresos.some(ingresoPago=> ingresoPago.id === ingreso.id))
    return pagos.length === 0
  })

  const handleNuevoPago = (pago)=> {
    if (isPagoCreating) return
    const datos = {
      alumno: id,
			concepto: pago.concepto,
			importe: pago.importe,
    }
    crearPago(datos)
  }

  const handleIngresoAssign = (ingreso)=> {
    if (isIngresoAssigning) return
    asignarIngreso(ingreso)
  }

  const handleUpdatePromocion = (detalles)=> {
    if (isPromoAssigning) return
    const tipo = getTipoPago(detalles.tipo)
    asignarPromocion({ 
      tipo: tipo, 
      data: { 
        id: detalles.id,
        codigo_promocional: detalles.promocion, 
      } 
    })
  }

  return (
    <div className={css.main}>
      <AlumnoHeader label='Contabilidad' />
      <PageLoading className={css.spinner} isLoading={pagosLoading || ingresosLoading}>
        <Typography variant="h5" className={css.title}>
          Pagos realizados
        </Typography>
        <TableContainer className={css.content}>
          <Table stickyHeader size='small'>
            <TableHead>
              <TableRow>
                <TableCell>Concepto</TableCell>
                <TableCell>Tipo</TableCell>
                <TableCell>Fecha</TableCell>
                <TableCell>Importe</TableCell>
                <TableCell>Promoción</TableCell>
                <TableCell>Estado</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {pagoList.map((pago)=> (
                <PagoRow 
                  key={pago.id} 
                  pago={pago} 
                  onUpdatePromocion={handleUpdatePromocion}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Typography variant="h5" className={css.title}>
          Ingresos sin asignar
        </Typography>
        <div className={css.content}>
          {ingresosSinAsignarList.length === 0 ? (
            <Typography variant="body1" className={css.placeholder}>
              No hay ningún ingreso pendiente de asignar
            </Typography>
          ) : (
            <TableContainer>
              <Table stickyHeader size='small'>
                <TableHead>
                  <TableRow>
                    <TableCell>Concepto</TableCell>
                    <TableCell>Fecha</TableCell>
                    <TableCell>Forma pago</TableCell>
                    <TableCell>Importe</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {ingresosSinAsignarList.map((ingreso)=> (
                    <IngresoRow 
                      key={ingreso.id} 
                      ingreso={ingreso} 
                      pagos={pagosPendientesList} 
                      onAssign={handleIngresoAssign}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}  
        </div>
        <Fab
          className={css.nuevo}
          disabled={isPagoCreating}
          onClick={() => setPagoOpen(true)}
          size='medium'
          color='primary'
          aria-label='add'
        >
          <i className='material-icons'>add</i>
        </Fab>
        <ModalNuevoPago
          open={isPagoOpen}
          onClose={()=> setPagoOpen(false)}
          onSubmit={handleNuevoPago}
        />
      </PageLoading>
    </div>
  )


}

export default Contabilidad
