import { useEffect, useState } from "react"
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@mui/material"
import { isAnyBlank } from "../../utils/string"
import { getAuth } from "../../utils/auth"
import FechaSelector from "../../components/form/FechaSelector/FechaSelector"
import AcademiaSelector from "../../components/form/AcademiaSelector/AcademiaSelector"
import HorarioSelector from "../../components/form/HorarioSelector/HorarioSelector"
import AsignaturaSelector from "../../components/form/AsignaturaSelector/AsignaturaSelector"
import { dateToString } from "../../utils/date"
import css from './ModalContactoPrueba.module.css'


const ModalContactoPrueba = ({ open, disabled, onSubmit, onClose }) => {
  
  const userData = getAuth()
  
  const [prueba, setPrueba] = useState({
    centro: userData.centro,
    fecha: null,
    hora: '',
    asignatura: {},
    observaciones: '',
  })

  useEffect(()=> {
    setPrueba({
      centro: userData.centro,
      fecha: null,
      hora: '',
      asignatura: {},
      observaciones: '',
    })
  }, [open, userData.centro])

  useEffect(()=> {
    setPrueba(p=> ({ ...p, hora: '' }))
  }, [prueba.centro, prueba.fecha])

  const isSubmitDisabled = isAnyBlank(prueba.centro, prueba.fecha, prueba.hora, prueba.asignatura)

  const handleSubmit = () => {
    onSubmit({
      lead_status: 'PRUEBA',
      centro_prueba: prueba.centro,
      fecha_prueba: dateToString(prueba.fecha),
      hora_prueba: prueba.hora,
      asignatura_prueba: prueba.asignatura,
      observaciones: prueba.observaciones,
    })
  }

  return (
    <Dialog onClose={onClose} open={open} fullWidth maxWidth='md'>
      <DialogTitle>Nueva prueba</DialogTitle>
      <DialogContent className={css.main}>
        <DialogContentText className={css.intro}>
          Rellena los datos para agendar una prueba para este lead
        </DialogContentText>
        <FechaSelector
          className={css.input}
          label='Fecha prueba'
          value={prueba.fecha}
          disablePast
          onChange={fecha=> setPrueba({ ...prueba, fecha })}
        />
        <AcademiaSelector
          className={css.input}
          name='academia'
          label='Academia'
          value={prueba.centro}
          onChange={e=> setPrueba({ ...prueba, centro: e.target.value })}
        />
        <HorarioSelector
          className={css.input}
          label='Hora prueba'
          name='hora'
          centro={prueba.centro}
          fecha={prueba.fecha}
          value={prueba.hora}
          onChange={(_e, value)=> setPrueba({ ...prueba, hora: value })}
        />
        <AsignaturaSelector
          className={css.input}
          name='asignatura'
          label='Asignatura'
          value={prueba.asignatura}
          onChange={(_e, value)=> setPrueba({ ...prueba, asignatura: value?.id || '' })}
        />
        <TextField
          className={css.input}
          label="Observaciones"
          multiline
          value={prueba.observaciones}
          onChange={e=> setPrueba({ ...prueba, observaciones: e.target.value })}
          variant='standard'
        />
      </DialogContent>
      <DialogActions>
        <Button 
          color='error' 
          disabled={disabled}
          onClick={onClose}
        >
          Cancelar
        </Button>
        <Button 
          disabled={isSubmitDisabled || disabled}
          color='primary' 
          onClick={handleSubmit}
        >
          Agendar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ModalContactoPrueba