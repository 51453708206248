import { useState } from 'react'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import { useQuery } from "@tanstack/react-query"
import { useNotification } from "../../../context/NotificationManager/NotificationManager"
import { getAlumnoListSearch } from '../../../utils/api/alumnos'
import css from './AlumnoSelector.module.css'

const AlumnoSelector = ({ className, name, label, value, onChange })=> {

  const notification = useNotification()

  const [alumno, setAlumno] = useState('')

  const { isLoading, data: alumnoList=[] } = useQuery({
    queryKey: ['alumnos', 'search', alumno],
    enabled: alumno.length > 2,
    queryFn: ()=> getAlumnoListSearch({ filtro: alumno })
      .then(datos=> datos.sort((a1, a2)=> a1.nombre.localeCompare(a2.nombre)))
      .catch(err=> {
        notification.error({ title: 'Error al recuperar los alumnos', content: err })
        return []
      })
  })


  return (
    <Autocomplete
      id={name} 
      className={`${className} ${css.main}`}
      disableClearable
      options={alumnoList}
      getOptionLabel={option=> option.nombre || ''}
      loading={isLoading}
      loadingText='Cargando alumnos...'
      noOptionsText='No hay alumnos'
      value={value}
      isOptionEqualToValue={(option, value)=> option.id === value.id}
      onChange={onChange}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          value={alumno}
          variant="standard"
          onChange={e=> setAlumno(e.target.value)}
          InputProps={{
            ...params.InputProps,
            type: 'search',
          }}
        />
      )}
    />
  )
}

export default AlumnoSelector