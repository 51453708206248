import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import css from './CursoSelector.module.css'

const CURSOS = [
  { value: '0', label: 'No aplicable' },
  { value: '1', label: '1º' },
  { value: '2', label: '2º' },
  { value: '3', label: '3º' },
  { value: '4', label: '4º' },
  { value: '5', label: '5º' },
  { value: '6', label: '6º' }
]

const CursoSelector = ({ className, name, label, value, onChange }) => (
  <FormControl
    className={`${className} ${css.main}`}
    variant='standard'
    margin='dense'
    fullWidth
  >
    <InputLabel id={name}>{label}</InputLabel>
    <Select
      name={name}
      labelId={name}
      id={name}
      value={value}
      onChange={onChange}
      label={label}
    >
      {CURSOS.map(course => (
        <MenuItem key={course.value} value={course.value}>
          {course.label}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
)

export default CursoSelector
