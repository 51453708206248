import { useEffect, useState } from "react"
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@mui/material"
import { isAnyBlank } from "../../utils/string"
import FechaSelector from "../../components/form/FechaSelector/FechaSelector"
import AcademiaSelector from "../../components/form/AcademiaSelector/AcademiaSelector"
import { dateToDateTime, dateToString } from "../../utils/date"
import css from './ModalEditarFichaAlumno.module.css'

const ModalEditarFichaAlumno = ({ open, alumno, onSubmit, onClose }) => {

  const [datos, setDatos] = useState({
    nombre: '',
    apellidos: '',
    dni: '',
    fechaNacimiento: null,
    codigoPostal: '',
    telefonoFijo: '',
    telefonoMovil: '',
    email: '',
    centro: ''
  })

  useEffect(()=> {
    setDatos({
      nombre: alumno.nombre,
      apellidos: alumno.apellidos,
      dni: alumno.dni,
      fechaNacimiento: dateToDateTime(alumno.fechaNacimiento),
      codigoPostal: alumno.codigoPostal,
      telefonoFijo: alumno.telefonoFijo,
      telefonoMovil: alumno.telefonoMovil,
      email: alumno.email,
      centro: alumno.centro
    })
  }, [open, alumno])

  const isSubmitDisabled = isAnyBlank(
    datos.nombre,
    datos.apellidos,
    datos.centro,
  )

  const handleSubmit = () => {
    onSubmit({
      id: alumno.id,
      nombre: datos.nombre,
      apellidos: datos.apellidos,
      fechaNacimiento: dateToString(datos.fechaNacimiento),
      dni: datos.dni,
      email: datos.email,
      telefonoFijo: datos.telefonoFijo,
      movil: datos.telefonoMovil,
      codigoPostal: datos.codigoPostal,
      centro: datos.centro
    })
  }

  return (
    <Dialog onClose={onClose} open={open} fullWidth maxWidth='md'>
      <DialogTitle>Editar alumno</DialogTitle>
      <DialogContent className={css.main}>
        <DialogContentText className={css.intro}>
          Modifica los datos del perfil de este alumno
        </DialogContentText>
        <section className={css.section}>
          <TextField
            className={css.input}
            label="Nombre"
            value={datos.nombre}
            onChange={e=> setDatos({ ...datos, nombre: e.target.value })}
            variant='standard'
          />
          <TextField
            className={css.input}
            label="Apellidos"
            value={datos.apellidos}
            onChange={e=> setDatos({ ...datos, apellidos: e.target.value })}
            variant='standard'
          />
        </section>
        <FechaSelector
          className={css.dateInput}
          label='Fecha de nacimiento'
          value={datos.fechaNacimiento}
          onChange={fechaNacimiento=> setDatos({ ...datos, fechaNacimiento })}
        />
        <section className={css.section}>
          <TextField
            className={css.input}
            label="DNI"
            value={datos.dni}
            onChange={e=> setDatos({ ...datos, dni: e.target.value })}
            variant='standard'
          />
          <TextField
            className={css.input}
            label="Código postal"
            value={datos.codigoPostal}
            onChange={e=> setDatos({ ...datos, codigoPostal: e.target.value })}
            variant='standard'
          />
        </section>
        <section className={css.section}>
          <TextField
            className={css.input}
            label="Teléfono fijo"
            value={datos.telefonoFijo}
            onChange={e=> setDatos({ ...datos, telefonoFijo: e.target.value })}
            variant='standard'
          />
          <TextField
            className={css.input}
            label="Teléfono móvil"
            value={datos.telefonoMovil}
            onChange={e=> setDatos({ ...datos, telefonoMovil: e.target.value })}
            variant='standard'
          />
        </section>
        <TextField
          className={css.input}
          label="Email"
          value={datos.email}
          onChange={e=> setDatos({ ...datos, email: e.target.value })}
          variant='standard'
        />
        <AcademiaSelector
          className={css.input}
          name='academia'
          label='Academia'
          value={datos.centro}
          onChange={e=> setDatos({ ...datos, centro: e.target.value })}
        />
      </DialogContent>
      <DialogActions>
        <Button color='error' onClick={onClose}>Cancelar</Button>
        <Button disabled={isSubmitDisabled} color='primary' onClick={handleSubmit}>Actualizar</Button>
      </DialogActions>
    </Dialog>
  )
}

export default ModalEditarFichaAlumno