import { useState } from "react"
import { 
  Card,
  CardContent,
  Chip,
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography 
} from "@mui/material"
import { useQuery } from '@tanstack/react-query'
import { useNotification } from "../../../context/NotificationManager/NotificationManager"
import PageLoading from "../../../components/PageLoading/PageLoading"
import { dateToString } from "../../../utils/date"
import { getCodigosPromocionales } from "../../../utils/api/codigoPromocional"
import { getAuth } from '../../../utils/auth'
import css from './Contrataciones.module.css'
import { getIntensivos, getTarifas } from "../../../utils/api/contrataciones"
import { getPacks } from "../../../utils/api/packs"

const TarifaCard = ({ tarifa, centro })=> (
  <CardContent>
    <Typography variant='h6' component='h2'>{tarifa.nombre}</Typography>
    <Typography variant='body2' component='p'>
      Disponible del {tarifa.fechaInicio} al {tarifa.fechaFin}
    </Typography>
    <ul>
      {tarifa.descripcion.map((linea, i)=> (
        <li key={i}>{linea}</li>  
      ))}
    </ul>
    <Typography variant='body1' component='p'>Disponible en:</Typography>
    {tarifa.centros.map((centroTarifa)=> (
      <Chip 
        className={css.centro} 
        key={centroTarifa} 
        label={centroTarifa} 
        color={centroTarifa === centro ? 'primary' : 'default'}
      /> 
    ))}
  </CardContent>
)

const IntensivoCard = ({ intensivo, centro })=> (
  <CardContent>
    <Typography variant='h6' component='h2'>{intensivo.nombre}</Typography>
    <Typography variant='body2' component='p'>
      Disponible del {intensivo.fechaInicio} al {intensivo.fechaFin}
    </Typography>
    <Typography variant='body1' component='p' className={css.descripcion}>
      {intensivo.descripcion}
    </Typography>
    <Typography variant='body1' component='p'>Disponible en:</Typography>
    {intensivo.centros.map((centroIntensivo)=> (
      <Chip 
        className={css.centro} 
        key={centroIntensivo} 
        label={centroIntensivo} 
        color={centroIntensivo === centro ? 'primary' : 'default'}
      /> 
    ))}
  </CardContent>
)

const PackCard = ({ pack, centro })=> (
  <CardContent>
    <Typography variant='h6' component='h2'>{pack.nombre}</Typography>
    <Typography variant='body2' component='p'>
      Disponible del {pack.fechaInicio} al {pack.fechaFin}
    </Typography>
    <Typography variant='body1' component='p' className={css.descripcion}>
      {pack.descripcion}
    </Typography>

    <ul>
      {Object.keys(pack.preciosHora).map((hora)=> (
        <li key={hora}>
          {hora} horas por {pack.preciosHora[hora]}€
        </li>
      ))}
    </ul>
    <Typography variant='body1' component='p'>Disponible en:</Typography>
    {pack.centros.map((centroPack, i)=> (
      <Chip 
        className={css.centro} 
        key={i} 
        label={centroPack} 
        color={centroPack === centro ? 'primary' : 'default'}
      /> 
    ))}
  </CardContent>
)

const PromocionCard = ({ promocion, centro })=> (
  <CardContent>
    <Typography variant='h6' component='h2'>{promocion.nombre}</Typography>
    <Typography variant='body2' component='p'>
      Disponible del {promocion.fechaInicio} al {promocion.fechaFin}
    </Typography>
    <Typography variant='body1' component='p' className={css.descripcion}>
      {promocion.descripcion}
    </Typography>
    <Typography variant='body1' component='p'>Disponible en:</Typography>
    {promocion.centros.map((centroPromocion)=> (
      <Chip 
        className={css.centro} 
        key={centroPromocion} 
        label={centroPromocion} 
        color={centroPromocion === centro ? 'primary' : 'default'}
      /> 
    ))}
  </CardContent>
)

const TipoContrataciones = ({ title, contrataciones, isOpen, selected, onOpen, onSelect })=> (
  <>
    <ListItemButton onClick={onOpen}>
      <ListItemText primary={title} />
      <i className='material-icons'>{isOpen ? 'expand_less' : 'expand_more'}</i>
    </ListItemButton>
    <Collapse in={isOpen} timeout="auto" unmountOnExit className={css.collapsed}>
      <List component="div" disablePadding>
        {contrataciones.map(contratacion=> (
          <ListItemButton 
            key={contratacion.nombre} 
            className={css.contratacion}
            onClick={()=> onSelect(contratacion)}
          >
            <ListItemText primary={contratacion.nombre} />
            <ListItemIcon 
              className={css.contratacionIcon} 
              data-status={selected === contratacion.nombre ? 'selected' : 'default'}
            >
              <i className='material-icons'>visibility</i>
            </ListItemIcon>
          </ListItemButton>
        ))}
      </List>
    </Collapse>
  </>
)

const Contrataciones = ()=> {

  const userData = getAuth()

  const notification = useNotification()

  const { isLoading: isTarifasLoading, data: tarifaList=[] } = useQuery({
    queryKey: ['datos', 'tarifa', 'list'], 
    queryFn: ()=> getTarifas()
      .then(datos=> datos
        .sort((a, b)=> {
          if (a.fechaFin < b.fechaFin) return 1
          if (a.fechaFin > b.fechaFin) return -1
          return 0
        })
        .map(tarifa=> ({
          ...tarifa,
          fechaInicio: dateToString(tarifa.fechaInicio),
          fechaFin: dateToString(tarifa.fechaFin),
        }))
      )
      .catch(err=> {
        notification.error({ title: 'Error al recuperar las tarifas', content: err })
        return []
      })
  })

  const { isLoading: isIntensivosLoading, data: intensivoList=[] } = useQuery({
    queryKey: ['datos', 'intensivo', 'list'], 
    queryFn: ()=> getIntensivos()
      .then(datos=> datos
        .sort((a, b)=> {
          const aInicio = a.dias.sort()[0]
          const bInicio = b.dias.sort()[0]
          if (aInicio < bInicio) return 1
          if (aInicio > bInicio) return -1
          return 0
        })
        .map(intensivo=> ({
          ...intensivo,
          fechaInicio: dateToString(intensivo.dias.sort()[0]),
          fechaFin: dateToString(intensivo.dias.sort()[intensivo.dias.length - 1]),
          dias: intensivo.dias.sort().map(dateToString),
        }))
      )
      .catch(err=> {
        notification.error({ title: 'Error al recuperar los intensivos', content: err })
        return []
      })
  })

  const { isLoading: isPacksLoading, data: packList=[] } = useQuery({
    queryKey: ['datos', 'pack-horas', 'list'], 
    queryFn: ()=> getPacks()
      .then(datos=> datos
        .sort((a, b)=> {
          if (a.fechaFin < b.fechaFin) return 1
          if (a.fechaFin > b.fechaFin) return -1
          return 0
        })
        .map(pack=> ({
          ...pack,
          fechaInicio: dateToString(pack.fechaInicio),
          fechaFin: dateToString(pack.fechaFin),
          fechaInicioContratacion: dateToString(pack.fechaInicioContratacion),
          fechaFinContratacion: dateToString(pack.fechaFinContratacion),
        }))
      )
      .catch(err=> {
        notification.error({ title: 'Error al recuperar los packs', content: err })
        return []
      })
  })

  const { isLoading: isPromocionesLoading, data: codigoList=[] } = useQuery({
    queryKey: ['datos', 'codigo-promocional', 'list'], 
    queryFn: ()=> getCodigosPromocionales()
      .then(datos=> datos
        .sort((a, b)=> {
          if (a.fechaFin < b.fechaFin) return 1
          if (a.fechaFin > b.fechaFin) return -1
          return 0
        })
        .map(codigo=> ({
          ...codigo,
          fechaInicio: dateToString(codigo.fechaInicio),
          fechaFin: dateToString(codigo.fechaFin),
        }))
      )
      .catch(err=> {
        notification.error({ title: 'Error al recuperar los códigos promocionales', content: err })
        return []
      })
  })

  const [openSection, setOpenSection] = useState(null)

  const [selectedContratacion, setSelectedContratacion] = useState(null)
  const [selectedContratacionTipo, setSelectedContratacionTipo] = useState(null)

  const handleContratacionSelect = (tipo)=> (contratacion)=> {
    const contratacionActual = selectedContratacion ? selectedContratacion.nombre : ''
    if (contratacionActual === contratacion.nombre) {
      setSelectedContratacion(null)
      setSelectedContratacionTipo(null)
    }
    else {
      setSelectedContratacion(contratacion)
      setSelectedContratacionTipo(tipo)
    }
  }

  return (
    <PageLoading isLoading={isTarifasLoading || isIntensivosLoading || isPacksLoading || isPromocionesLoading}>
      <div className={css.contrataciones}>
        <Card className={css.listado}>
          <List>
            <TipoContrataciones
              title='Tarifas'
              contrataciones={tarifaList}
              isOpen={openSection === 'tarifas'}
              selected={selectedContratacion ? selectedContratacion.nombre : ''}
              onOpen={()=> setOpenSection(c=> c === 'tarifas' ? null : 'tarifas')}
              onSelect={handleContratacionSelect('tarifa')}
            />
            <TipoContrataciones
              title='Intensivos'
              contrataciones={intensivoList}
              isOpen={openSection === 'intensivos'}
              selected={selectedContratacion ? selectedContratacion.nombre : ''}
              onOpen={()=> setOpenSection(c=> c === 'intensivos' ? null : 'intensivos')}
              onSelect={handleContratacionSelect('intensivo')}
            />
            <TipoContrataciones
              title='Packs'
              contrataciones={packList}
              isOpen={openSection === 'packs'}
              selected={selectedContratacion ? selectedContratacion.nombre : ''}
              onOpen={()=> setOpenSection(c=> c === 'packs' ? null : 'packs')}
              onSelect={handleContratacionSelect('pack')}
            />
            <TipoContrataciones
              title='Códigos promocionales'
              contrataciones={codigoList}
              isOpen={openSection === 'codigos'}
              selected={selectedContratacion ? selectedContratacion.nombre : ''}
              onOpen={()=> setOpenSection(c=> c === 'codigos' ? null : 'codigos')}
              onSelect={handleContratacionSelect('promocion')}
            />
          </List>
        </Card>
        <Card className={css.detalle}>
          {selectedContratacionTipo === 'tarifa' && (
            <TarifaCard tarifa={selectedContratacion} centro={userData.centro} /> 
          )}
          {selectedContratacionTipo === 'intensivo' && (
            <IntensivoCard intensivo={selectedContratacion} centro={userData.centro} />
          )}
          {selectedContratacionTipo === 'pack' && (
            <PackCard pack={selectedContratacion} centro={userData.centro} />
          )}
          {selectedContratacionTipo === 'promocion' && (
            <PromocionCard promocion={selectedContratacion} centro={userData.centro} />
          )}
        </Card>
      </div>
    </PageLoading>
  )

}

export default Contrataciones