import { useEffect, useState } from "react"
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material"
import ProfesorSelector from "../../components/form/ProfesorSelector/ProfesorSelector"
import css from './ModalEditarProfesorAsistencias.module.css'


const ModalEditarProfesorAsistencias = ({ open, onSubmit, onClose }) => {

  const [profesor, setProfesor] = useState('')
  
  useEffect(()=> {
    setProfesor('')
  }, [open])

  const handleSubmit = () => {
    onSubmit({ profesor })
  }

  return (
    <Dialog onClose={onClose} open={open} fullWidth maxWidth='md'>
      <DialogTitle>Cambiar profesor</DialogTitle>
      <DialogContent className={css.main}>
        <DialogContentText className={css.intro}>
          Elige a continuación que profesor quieres seleccionar. Se cambiará para todas las asistencias seleccionadas.
          <br />
          Si dejas el campo vacío, se eliminará el profesor de las asistencias seleccionadas.
        </DialogContentText>
        <ProfesorSelector
          className={css.input}
          name='profesor'
          label='Profesor'
          value={profesor}
          onChange={(_e, value)=> setProfesor(value?.id || '')}
        />
      </DialogContent>
      <DialogActions>
        <Button color='error' onClick={onClose}>
          Cancelar
        </Button>
        <Button color='primary' onClick={handleSubmit}>
          Cambiar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ModalEditarProfesorAsistencias