import { Card, CardContent, FormControl, InputLabel, MenuItem, Select, Typography } from "@mui/material"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { useNotification } from "../../context/NotificationManager/NotificationManager"
import PageLoading from "../../components/PageLoading/PageLoading"
import TextLabel from "../../components/form/TextLabel/TextLabel"
import { getUsuario, updateAcademiaUsuario } from "../../utils/api/usuarios"
import { dateToStringLong, getDateFromObjectId } from "../../utils/date"
import { updateAuthCentro } from "../../utils/auth"
import css from './Perfil.module.css'

const AcademiaPermitidaSelector = ({ className, value, options, onChange })=> (
  <FormControl className={className} variant="standard">
    <InputLabel id='academia'>Academia de trabajo</InputLabel>
    <Select
      labelId='academia'
      id='academia'
      value={value}
      onChange={onChange}
      label='Academia de trabajo'
    >
      {options.sort().map(centro=> <MenuItem key={centro} value={centro}>{centro}</MenuItem> )}
    </Select>
  </FormControl>
)

const Perfil = () => {

  const notification = useNotification()
  const queryClient = useQueryClient()

  const { isLoading, data: perfil={} } = useQuery({
    queryKey: ['personal', 'usuario'], 
    queryFn: ()=> getUsuario()
      .then(datos=> ({
          ...datos,
          fecha: getDateFromObjectId(datos.id),
      }))
      .catch(err=> {
        notification.error({ title: 'Error al recuperar tu perfil', content: err })
        return {}
      })
  })

  const { isPending: isUsuarioUpdating, mutate: actualizarAcademia } = useMutation({
    mutationFn: updateAcademiaUsuario,
    onSuccess: (_data, { centro })=> {
      notification.success({ title: 'Academia cambiada', content: 'Se ha actualizado tu academia de trabajo' })
      updateAuthCentro(centro)
      queryClient.invalidateQueries({ 
        queryKey: [],
        refetchType: 'all'
      })
    },
    onError: err=> notification.error({ title: 'Error al cambiar', content: err }),
  })

  const handleChangeCentro = (e)=> {
    if (isUsuarioUpdating) return
    const centro = e.target.value
    actualizarAcademia({ id: perfil.id, centro })
  }

  return (
    <PageLoading isLoading={isLoading}>
      <div className={css.main}>
        <Typography variant='h5' className={css.title}>
          Mi perfil
        </Typography>
        <div className={css.content}>
          <Card className={css.card}>
            <CardContent className={css.cardContent}>
              <Typography variant='h6' className={css.cardTitle}>
                Mis datos
              </Typography>
              <TextLabel
                className={css.input}
                label='Fecha de registro'
                disabled
                value={dateToStringLong(perfil.fecha)}
              />
              <TextLabel
                className={css.input}
                label='Nombre'
                disabled
                value={perfil.nombre}
              />
              <TextLabel
                className={css.input}
                label='Email'
                disabled
                value={perfil.email}
              />
              <AcademiaPermitidaSelector
                className={css.input}
                value={perfil.centro}
                options={perfil.centrosPermitidos}
                onChange={handleChangeCentro}
              />
            </CardContent>
          </Card>
        </div>
      </div>
    </PageLoading>
  )
}

export default Perfil