import { GET, POST, PUT } from '../request'

export const getIngresosCentro = (mes) => GET('/api/agora/alumnos/contabilidad/ingreso-alumno/list/month', { mes })

export const getIngresosDay = fecha => GET('/api/agora/alumnos/contabilidad/ingreso-alumno/list/day', { fecha })

export const getIngresosAlumno = id => GET('/api/agora/alumnos/contabilidad/ingreso-alumno/list/alumno', { alumno: id })

export const getIngresosSinAsignar = (mes) => GET('/api/agora/alumnos/contabilidad/ingreso-alumno/list/unassigned', { mes }) 

export const getIngresosPendientesFactura = (mes) => GET('/api/agora/alumnos/contabilidad/ingreso-alumno/list/pending-invoice', { mes }) 

export const createFactura = ({ ingreso }) => POST('/api/agora/alumnos/contabilidad/ingreso-alumno/factura', { ingreso })

export const updateFormaPago = ({ id, forma_pago }) => PUT('/api/agora/alumnos/contabilidad/ingreso-alumno', { id, forma_pago })

export const assignIngreso = ({ ingreso, pago, tipo_pago, importe }) => PUT('/api/agora/alumnos/contabilidad/ingreso-alumno/assign', { ingreso, pago, tipo_pago, importe })

export const createIngreso = ({ alumno, concepto, forma_pago, importe })=> POST('/api/agora/alumnos/contabilidad/ingreso-alumno', { alumno, concepto, forma_pago, importe })