import { useState } from 'react'
import { Card, CardContent, CardHeader, Fab, Typography } from '@mui/material'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useNotification } from '../../../../context/NotificationManager/NotificationManager'
import { createNotaAlumno, getNotasAlumnoList, deleteNotaAlumno } from '../../../../utils/api/alumnos'
import { dateToStringLong, dateToTimeString } from '../../../../utils/date'
import ModalNuevaNotaAlumno from '../../../../modals/ModalNuevaNotaAlumno/ModalNuevaNotaAlumno'
import Dropdown from '../../../../components/Dropdown/Dropdown'
import PageLoading from '../../../../components/PageLoading/PageLoading'
import css from './NotasAlumno.module.css'

const NotasAlumno = ({ alumnoId })=> {

  const queryClient = useQueryClient()
  const notification = useNotification()

  const [open , setOpen] = useState(false)

  const { isLoading, data: notasAlumnoList = [] } = useQuery({
    queryKey: ['alumnos', 'notas-alumno', 'list', alumnoId], 
    queryFn: () => getNotasAlumnoList(alumnoId)
      .then(datos=> datos.reverse())
      .catch(err=> {
        notification.error({ title: 'Error obteniendo notas de alumno', content: err })
        return []
      })
  })

  const { isLoading: isCreandoNota, mutate: crearNota } = useMutation({
    mutationFn: createNotaAlumno,
    onSuccess: ()=> {
      queryClient.invalidateQueries({
        queryKey: ['alumnos', 'notas-alumno', 'list', alumnoId]
      })
      notification.success({ title: 'Nota creada', content: 'Se ha creado correctamente' })
      setOpen(false)
    },
    onError: err=> {
      notification.error({ title: 'Error al guardar la nota', content: err })
    }
  })

  const { isLoading: isBorrandoNota, mutate: borrarNota } = useMutation({
    mutationFn: deleteNotaAlumno,
    onSuccess: ()=> {
      queryClient.invalidateQueries({
        queryKey: ['alumnos', 'notas-alumno', 'list', alumnoId]
      })
      notification.success({ title: 'Nota borrada', content: 'Se ha borrado la nota', })
    },
    onError: err=> {
      notification.error({ title: 'Error al borrar la nota', content: err })
    }
  })

  const handleSubmit = ({ nota }) => {
    if (isCreandoNota) return
    crearNota({ nota, alumno: alumnoId })
  }

  const handleDeleteNote = id => {
    if (isBorrandoNota) return
    borrarNota(id)
  }

  const handleOptionClick = ({ id, option }) => {
    if (option === 'Eliminar nota') handleDeleteNote(id)
  }

  return (
    <PageLoading isLoading={isLoading}>
      {notasAlumnoList.length === 0 && (
        <Typography className={css.placeholder} variant='h6'>
          Este alumno no tiene ninguna nota en su ficha
        </Typography>
      )}
      {notasAlumnoList.map((nota) => (
        <div className={css.card} key={nota.id}>
          <Card>
            <CardHeader
              className={css.header}
              title={`${dateToStringLong(nota.fecha)} - ${dateToTimeString(nota.fecha)}`}
              titleTypographyProps={{ variant: 'body1' }}
              subheader={`${nota.usuario} escribió:`} 
              action={
                <Dropdown
                  id={nota.id}
                  options={['Eliminar nota']}
                  onOptionClick={option=> handleOptionClick({ id: nota.id, option })}
                />
              }
            />
            <CardContent>
              <Typography variant='body1'>
                {nota.nota}
              </Typography>
            </CardContent>
          </Card>
        </div>
      ))}
      <Fab
        className={css.fab}
        color='primary'
        aria-label='add'
        onClick={() => setOpen(true)}
      >
        <i className='material-icons'>add</i>
      </Fab>
      <ModalNuevaNotaAlumno
        open={open}
        onSubmit={handleSubmit}
        onClose={() => setOpen(false)}
      />
    </PageLoading>
  )
}

export default NotasAlumno
