import AlumnoHeader from '../../../components/AlumnoHeader/AlumnoHeader'
import useAlumno from '../../../hooks/useAlumno'
import Tabs from '../../../components/Tabs/Tabs'
import NotasAlumno from './NotasAlumno/NotasAlumno'
import NotasProfesor from './NotasProfesor/NotasProfesor'
import css from '../Notas/Notas.module.css'

const Notas = ()=> {

  const { id } = useAlumno()

  return (
    <div className={css.main}>
      <AlumnoHeader label='Notas' />
      <Tabs
        tabs={[
          { 
            name: 'Notas alumno', 
            content: <NotasAlumno alumnoId={id} />
          },
          { 
            name: 'Notas profesor', 
            content: <NotasProfesor alumnoId={id} /> 
          },
        ]}
      />
    </div>
  )
}

export default Notas
