import { useState } from "react"
import { dateToString } from "../../../../../utils/date"
import { IconButton, Link } from "@mui/material"
import { useQuery } from "@tanstack/react-query"
import { useNotification } from "../../../../../context/NotificationManager/NotificationManager"
import { getIngresosSinAsignar } from "../../../../../utils/api/ingresos"
import InformationTable from "../../../../../components/Table/InformationTable"
import PageLoading from "../../../../../components/PageLoading/PageLoading"
import TableHeader from "../../../../../components/TableHeader/TableHeader"
import { dateTimeSort, numberSort, textFilter } from "../../../../../utils/table"
import { downloadCSVFile, getCSVContent } from "../../../../../utils/csv"
import { format, formatForExport } from "../../../../../utils/math"
import css from './Ingresos.module.css'

const Ingresos = ({ mes })=> {
  
  const notification = useNotification()

  const [search, setSearch] = useState('')

  const { isLoading, data: ingresoList=[] } = useQuery({
    queryKey: ['alumnos', 'contabilidad', 'ingreso-alumno', 'list', 'unassigned', mes], 
    queryFn: ()=> getIngresosSinAsignar(mes)
      .then(datos=> datos.map(pago=> ({
        ...pago,
        fecha: dateToString(pago.fecha),
      })))
      .catch(err=> {
        notification.error({ title: 'Error al recuperar los ingresos sin asignar', content: err })
        return []
      })
  })

  const handleExport = ()=> {
    const content = getCSVContent({
      columns: [
        'Alumno',
        'Fecha',
        'Concepto',
        'Centro',
        'Importe',
        'Forma de Pago',
      ],
      data: ingresoList,
      transform: c=> ([
        c.alumno,
        `${c.fecha} ${c.hora}`,
        c.concepto,
        c.centro,
        formatForExport(c.importe),
        c.formaPago,
      ])
    })
    downloadCSVFile(content, `ingresos-desasignados-${mes}`)
  }

  const filterSearch = pago=> {
    return textFilter({ 
      object: pago, 
      fields: ['alumno', 'fecha', 'concepto', 'formaPago', 'importe'],
      search: search
    })
  }

  return (
    <PageLoading isLoading={isLoading}>
      <TableHeader
        actions={(
          <div className={css.actions}>
            <IconButton className={css.download} size='small' color='primary' onClick={handleExport}>
              <i className='material-icons'>download</i>
            </IconButton>
          </div>
        )}
        title='Ingresos pendientes de asignar'
        search={search}
        onSearchChange={e=> setSearch(e.target.value)}
      />
      <InformationTable
        details={[
          { title: 'Alumno', key: 'alumno', sortKey: 'alumnoNombre' },
          { title: 'Fecha', key: 'fecha', sortFunction: dateTimeSort },
          { title: 'Importe', key: 'importe', sortFunction: numberSort },
          { title: 'Forma de pago', key: 'tipoPago' },
          { title: 'Concepto', key: 'concepto' },
        ]}
        data={ingresoList.filter(filterSearch).map((pago) => ({
          alumno: (
            <Link
              className={css.link}
              href={`/alumnos/${pago.alumnoId}/ficha`}
            >
              {pago.alumno}
            </Link>
          ),
          alumnoNombre: pago.alumno,
          fecha: `${pago.fecha} ${pago.hora}`,
          importe: `${format(pago.importe)} €`,
          tipoPago: pago.formaPago,
          concepto: pago.concepto,
        }))}
        isFetching={isLoading}
      />
    </PageLoading>
  )
}

export default Ingresos