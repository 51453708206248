import { useEffect, useState } from "react"
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material"
import AsignaturaSelector from "../../components/form/AsignaturaSelector/AsignaturaSelector"
import { isBlank } from "../../utils/string"
import css from './ModalEditarAsignaturaAsistencias.module.css'


const ModalEditarAsignaturaAsistencias = ({ open, onSubmit, onClose }) => {

  const [asignatura, setAsignatura] = useState('')
  
  useEffect(()=> {
    setAsignatura('')
  }, [open])

  const handleSubmit = () => {
    onSubmit({ asignatura })
  }

  const isSubmitDisabled = isBlank(asignatura)

  return (
    <Dialog onClose={onClose} open={open} fullWidth maxWidth='md'>
      <DialogTitle>Cambiar asignatura</DialogTitle>
      <DialogContent className={css.main}>
        <DialogContentText className={css.intro}>
          Elige a continuación que asignatura quieres seleccionar. Se cambiará para todas las asistencias seleccionadas.
        </DialogContentText>
        <AsignaturaSelector
          className={css.input}
          name='asignatura'
          label='Asignatura'
          value={asignatura}
          onChange={(_e, value)=> setAsignatura(value?.id || '')}
        />
      </DialogContent>
      <DialogActions>
        <Button color='error' onClick={onClose}>
          Cancelar
        </Button>
        <Button disabled={isSubmitDisabled} color='primary' onClick={handleSubmit}>
          Cambiar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ModalEditarAsignaturaAsistencias