import { TextField } from "@mui/material"

const TextLabel = ({
  name='field',
  label,
  value,
  className='',
  disabled=false,
})=> (
  <TextField
    className={className}
    disabled={disabled}
    name={name}
    label={label}
    variant='standard'
    margin='dense'
    value={value || ''}
    InputProps={{ readOnly: true }}
  />
)

export default TextLabel