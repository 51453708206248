import { useState } from 'react'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { Button, IconButton, Typography } from '@mui/material'
import { useNotification } from '../../../../context/NotificationManager/NotificationManager'
import { getIntensivosAlumno } from '../../../../utils/api/intensivos'
import { createPackAlumno, deletePack, getPacksAlumno, updatePackAlumno } from '../../../../utils/api/packs'
import { copyTarifaAlumno, createTarifaAlumno, deleteTarifaAlumno, getTarifasAlumno, updateTarifaAlumno } from '../../../../utils/api/tarifas'
import InformationTable from '../../../../components/Table/InformationTable'
import PageLoading from '../../../../components/PageLoading/PageLoading'
import ModalClonarTarifa from '../../../../modals/ModalClonarTarifa/ModalClonarTarifa'
import useAlumno from '../../../../hooks/useAlumno'
import ModalNuevaTarifa from '../../../../modals/ModalNuevaTarifa/ModalNuevaTarifa'
import ModalNuevoPackHoras from '../../../../modals/ModalNuevoPackHoras/ModalNuevoPackHoras'
import { dateToString, getDateFromObjectId } from '../../../../utils/date'
import { dateSort } from '../../../../utils/table'
import ModalEditarTarifa from '../../../../modals/ModalEditarTarifa/ModalEditarTarifa'
import ModalEditarPackHoras from '../../../../modals/ModalEditarPackHoras/ModalEditarPackHoras'
import ModalConfirmacion from '../../../../modals/ModalConfirmacion/ModalConfirmacion'
import css from './Contrataciones.module.css'

const PlaceholderContrataciones = ({ children })=> (
  <div className={css.placeholder}>
    <p className={css.placeholderContent}>{children}</p>
  </div>
)

const Contrataciones = ()=> {

  const notification = useNotification()
  const queryClient = useQueryClient()
  const { id } = useAlumno()

  const [isClonarTarifaOpen, setClonarTarifaOpen] = useState(false)
  const [isNuevaTarifaOpen, setNuevaTarifaOpen] = useState(false)

  const [isNuevoPackOpen, setNuevoPackOpen] = useState(false)

  const [action, setAction] = useState('')
  const [tarifaSeleccionada, setTarifaSeleccionada] = useState(null)
  const [packSeleccionado, setPackSeleccionado] = useState(null)

  const { data: alumnoTarifas = [], isLoading: cargandoTarifas, } = useQuery({
    queryKey: ['alumnos', 'contabilidad', 'tarifa-alumno', 'list', 'alumno', id], 
    queryFn: () => getTarifasAlumno(id)
      .then(datos=> datos)
      .catch(err=> {
        notification.error({ title: 'Error obteniendo tarifas', content: err })
        return []
      })
  })

  const { data: alumnoIntensivos = [], isLoading: cargandoIntensivos } = useQuery({
    queryKey: ['alumnos', 'contabilidad', 'intensivo-alumno', 'list', 'alumno', id],
    queryFn: () => getIntensivosAlumno(id)
      .then(datos=> datos)
      .catch(err=> {
        notification.error({ title: 'Error obteniendo intensivos', content: err })
        return []
      })
  })

  const { data: alumnoPacks = [], isLoading: cargandoPacks } = useQuery({
    queryKey: ['alumnos', 'contabilidad', 'pack-horas-alumno', 'list', 'alumno', id], 
    queryFn: () => getPacksAlumno(id)
      .then(datos=> datos.map(pack=> ({
        ...pack,
        fecha: dateToString(getDateFromObjectId(pack.id))
      })))
      .catch(err=> {
        notification.error({ title: 'Error obteniendo packs', content: err })
        return []
      })
  })

  const { isPending: isTarifaCreating, mutate: crearTarifa } = useMutation({
    mutationFn: createTarifaAlumno,
    onSuccess: ()=> {
      notification.success({ title: 'Tarifa creada', content: 'Se ha creado la tarifa', })
      queryClient.invalidateQueries(['alumnos', 'contabilidad', 'tarifa-alumno', 'list', 'alumno', id])
      setNuevaTarifaOpen(false)
    },
    onError: (err)=> {
      notification.error({ title: 'Error creando tarifa', content: err })
    }
  })

  const { isPending: isTarifaEditing, mutate: actualizarTarifa } = useMutation({
    mutationFn: updateTarifaAlumno,
    onSuccess: ()=> {
      notification.success({ title: 'Tarifa actualizada', content: 'Datos actualizados correctamente' })
      queryClient.invalidateQueries(['alumnos', 'contabilidad', 'tarifa-alumno', 'list', 'alumno', id])
      setAction('')
      setTarifaSeleccionada(null)
    },
    onError: (err)=> {
      notification.error({ title: 'Error al actulizar tarifa', content: err })
    }
  })

  const { isPending: isTarifaDeleting, mutate: borrarTarifa } = useMutation({
    mutationFn: deleteTarifaAlumno,
    onSuccess: ()=> {
      notification.success({ title: 'Tarifa borrada', content: 'Se ha borrado la tarifa correctamente', })
      queryClient.invalidateQueries(['alumnos', 'contabilidad', 'tarifa-alumno', 'list', 'alumno', id])
      setAction('')
      setTarifaSeleccionada(null)
    },
    onError: (err)=> {
      notification.error({ title: 'Error al borrar la tarifa', content: err })
    }
  })

  const { isPending: isTarifaCopying, mutate: copiarTarifa } = useMutation({
    mutationFn: copyTarifaAlumno,
    onSuccess: ()=> {
      notification.success({ title: 'Tarifa copiada', content: 'Se ha copiado la tarifa' })
      queryClient.invalidateQueries(['alumnos', 'contabilidad', 'tarifa-alumno', 'list', 'alumno', id])
      setClonarTarifaOpen(false)
    },
    onError: (err)=> {
      notification.error({ title: 'Error al copiar la tarifa', content: err })
    }
  })

  const { isPending: isPackCreating, mutate: crearPack } = useMutation({
    mutationFn: createPackAlumno,
    onSuccess: ()=> {
      notification.success({ title: 'Pack horas creado', content: 'Se ha creado el pack correctamente' })
      queryClient.invalidateQueries(['alumnos', 'contabilidad', 'pack-horas-alumno', 'list', 'alumno', id])
      setNuevoPackOpen(false)
    },
    onError: (err)=> {
      notification.error({ title: 'Error creando pack horas', content: err })
    }
  })

  const { isPending: isPackEditing, mutate: actualizarPack } = useMutation({
    mutationFn: updatePackAlumno,
    onSuccess: ()=> {
      notification.success({ title: 'Pack horas editado', content: 'Se ha editado el pack correctamente' })
      queryClient.invalidateQueries(['alumnos', 'contabilidad', 'pack-horas-alumno', 'list', 'alumno', id])
      setAction('')
      setPackSeleccionado('')
    },
    onError: (err)=> {
      notification.error({ title: 'Error al actulizar el pack de horas', content: err })
    }
  })

  const { isPending: isPackDeleting, mutate: borrarPack } = useMutation({
    mutationFn: deletePack,
    onSuccess: ()=> {
      notification.success({ title: 'Pack borrado', content: 'Se ha borrado el pack correctamente' })
      queryClient.invalidateQueries(['alumnos', 'contabilidad', 'pack-horas-alumno', 'list', 'alumno', id])
      setAction('')
      setPackSeleccionado('')
    },
    onError: (err)=> {
      notification.error({ title: 'Error al borrar pack', content: err })
    }
  })

  const handleCopiarTarifa = mes => {
    if (isTarifaCopying) return
    copiarTarifa({ alumno: id, mes })
  }

  const handleCrearTarifa = data => {
    if (isTarifaCreating) return
    crearTarifa({ alumno: id, ...data, })
  }

  const handleCrearPack = data => {
    if (isPackCreating) return
    crearPack({ alumno: id, ...data })
  }

  const handleOpenEditTarifa = tarifa=> {
    setAction('edit')
    setTarifaSeleccionada(tarifa)
  }

  const handleOpenDeleteTarifa = tarifa=> {
    setAction('delete')
    setTarifaSeleccionada(tarifa)
  }

  const handleOpenEditPack = pack => {
    setAction('edit')
    setPackSeleccionado(pack)
  }

  const handleOpenDeletePack = pack => {
    setAction('delete')
    setPackSeleccionado(pack)
  }

  const handleCloseTarifaModal = () => {
    setAction('')
    setTarifaSeleccionada(null)
  }

  const handleClosePackModal = () => {
    setAction('')
    setPackSeleccionado(null)
  }

  const handleEditTarifa = datos => {
    if (isTarifaEditing) return
    actualizarTarifa({ id: tarifaSeleccionada.id, ...datos })
  }

  const handleEditPack = datos => {
    if (isPackEditing) return
    actualizarPack({ id: packSeleccionado.id, ...datos })
  }

  const handleDeleteTarifa = () => {
    if (isTarifaDeleting) return
    borrarTarifa(tarifaSeleccionada.id)
  }

  const handleDeletePack = () => {
    if (isPackDeleting) return
    borrarPack(packSeleccionado.id)
  }

  return (
    <PageLoading isLoading={cargandoTarifas || cargandoIntensivos || cargandoPacks}>
      <div className={css.block}>
        <div className={css.header}>
          <Typography variant='h6'>
            {alumnoPacks.length} Packs de horas contratados
          </Typography>
          <div className={css.actions}>
            <Button 
              variant='outlined' 
              color='primary' 
              size='small' 
              onClick={() => setNuevoPackOpen(true)}
              startIcon={<i className='material-icons'>add</i>}
            >
              Nuevo
            </Button>
          </div>
        </div>
        {alumnoPacks.length > 0 ? (
          <InformationTable
            size='small'
            details={[
              { title: 'Pack', key: 'pack' },
              { title: 'Horas', key: 'horas' },
              { title: 'Fecha', key: 'fecha', sortFunction: dateSort },
              { title: 'Estudios', key: 'estudios', sortDisabled: true },
              { title: 'Centro', key: 'centro' },
              { title: 'Opciones', key: 'opciones', align: 'center', sortDisabled: true }
            ]}
            data={alumnoPacks.map(pack => ({
              pack: pack.pack,
              estudios: (
                <span>
                  {pack.curso ? pack.curso : ''} {pack.tipologiaAcademica}
                  <br />
                  {pack.centroEstudios}
                </span>
              ),
              horas: `${pack.horas} horas`,
              fecha: pack.fecha,
              centro: pack.centro,
              opciones: (
                <div>
                  <IconButton 
                    color='secondary'
                    size='small' 
                    onClick={()=> handleOpenEditPack(pack)}
                  >
                    <i className='material-icons'>edit</i>
                  </IconButton>
                  <IconButton 
                    color='error'
                    size='small' 
                    onClick={()=> handleOpenDeletePack(pack)}
                  >
                    <i className='material-icons'>delete_forever</i>
                  </IconButton>
                </div>
              )
            }))}
          />
        ) : (
          <PlaceholderContrataciones>
            No hay packs de horas contratadas
          </PlaceholderContrataciones>
        )}
      </div>
      <div className={css.block}>
        <div className={css.header}>
          <Typography variant='h6'>
            {alumnoTarifas.length} Tarifas contratadas
          </Typography>
          <div className={css.actions}>
            <Button 
              variant='outlined' 
              color='primary' 
              size='small' 
              onClick={() => setNuevaTarifaOpen(true)}
              startIcon={<i className='material-icons'>add</i>}
            >
              Nueva
            </Button>
            <Button 
              variant='outlined' 
              color='secondary' 
              size='small' 
              disabled={alumnoTarifas.length === 0}
              onClick={() => setClonarTarifaOpen(true)}
              startIcon={<i className='material-icons'>content_copy</i>}
            >
              Copiar
            </Button>
          </div>
        </div>
        {alumnoTarifas.length > 0 ? (
          <InformationTable
            size='small'
            details={[
              { title: 'Mes', key: 'mes' },
              { title: 'Tarifa', key: 'tarifa' },
              { title: 'Estudios', key: 'estudios', sortDisabled: true },
              { title: 'Centro', key: 'centro' },
              { title: 'Opciones', key: 'opciones', align: 'center', sortDisabled: true }
            ]}
            data={alumnoTarifas.map(tarifa => ({
              mes: tarifa.mes,
              tarifa: tarifa.tarifa,
              estudios: (
                <span>
                  {tarifa.curso ? tarifa.curso : ''} {tarifa.tipologiaAcademica}
                  <br />
                  {tarifa.centroEstudios}
                </span>
              ),
              centro: tarifa.centro,
              opciones: (
                <>
                  <IconButton
                    color='secondary'
                    size='small'
                    onClick={() => handleOpenEditTarifa(tarifa)}
                  >
                    <i className='material-icons'>edit</i>
                  </IconButton>

                  <IconButton
                    color='error'
                    size='small'
                    onClick={() => handleOpenDeleteTarifa(tarifa)}
                  >
                    <i className='material-icons'>delete_forever</i>
                  </IconButton>
                </>
              )
            }))}
          />
        ) : (
          <PlaceholderContrataciones>
            No hay tarifas contratadas
          </PlaceholderContrataciones>
        )}
      </div>

      {alumnoIntensivos.length > 0 && (
        <div className={css.block}>
          <div className={css.header}>
            <Typography variant='h6'>
              {alumnoIntensivos.length} Intensivos contratados
            </Typography>
          </div>
          <InformationTable
            size='small'
            details={[
              { title: 'Intensivo', key: 'intensivo' },
              { title: 'Estudios', key: 'estudios', sortDisabled: true },
              { title: 'Centro', key: 'centro' }
            ]}
            data={alumnoIntensivos.map(intensivo => ({
              intensivo: intensivo.intensivo || '--',
              estudios: (
                <span>
                  {intensivo.curso ? intensivo.curso : ''} {intensivo.tipologiaAcademica}
                  <br />
                  {intensivo.centroEstudios}
                </span>
              ),
              centro: intensivo.centro || '--'
            }))}
          />
        </div>
      )}
      <ModalClonarTarifa
        open={isClonarTarifaOpen}
        onClose={()=> setClonarTarifaOpen(false)}
        onSubmit={handleCopiarTarifa}
      />
      <ModalNuevaTarifa
        open={isNuevaTarifaOpen}
        onClose={()=> setNuevaTarifaOpen(false)}
        onSubmit={handleCrearTarifa}
      />
      <ModalEditarTarifa
        open={action === 'edit' && !!tarifaSeleccionada}
        tarifa={tarifaSeleccionada}
        onClose={handleCloseTarifaModal}
        onSubmit={handleEditTarifa}
      />
      <ModalNuevoPackHoras
        open={isNuevoPackOpen}
        onClose={()=> setNuevoPackOpen(false)}
        onSubmit={handleCrearPack}
      />
      <ModalEditarPackHoras
        open={action === 'edit' && !!packSeleccionado}
        pack={packSeleccionado}
        onClose={handleClosePackModal}
        onSubmit={handleEditPack}
      />
      <ModalConfirmacion
        open={action === 'delete' && !!tarifaSeleccionada}
        title='¿Seguro que quieres borrar la tarifa contratada?'
        onClose={handleCloseTarifaModal}
        onClick={handleDeleteTarifa}
      />
      <ModalConfirmacion
        open={action === 'delete' && !!packSeleccionado}
        title='¿Seguro que quieres borrar el pack hora contratado?'
        onClose={handleClosePackModal}
        onClick={handleDeletePack}
      />
    </PageLoading>
  )
}


export default Contrataciones
