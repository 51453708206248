import { getToday, isSameDay } from "../date"
import { areBlank, isAnyBlank, isBlank, isEmail } from "../string"

export const getEstado = (_class)=> {
  if (_class === 'LEAD') return 'Lead'
  if (_class === 'LEAD_NO_VALIDO') return 'Lead no valido'
  if (_class === 'PRUEBA') return 'Prueba'
  if (_class === 'LEAD_CUALIFICADO') return 'Lead cualificado'
  if (_class === 'CLIENTE_CAPTADO') return 'Cliente Captado'
  if (_class === 'CLIENTE_PERDIDO') return 'Cliente Perdido'
}

export const getEstadoContacto = (fechaContacto)=> {
  const hoy = getToday()
  if (isSameDay(fechaContacto, hoy)) return 'normal'
  if (fechaContacto < hoy) return 'late'
  if (fechaContacto > hoy) return 'early'
}

export const validarLead = (nuevoLead)=> {
  const { 
    personaContacto,
    nombreContacto, 
    telefonoContacto, telefonoAlumno,
    emailContacto, emailAlumno,
    academia,
    formaContacto, comoConoce,
    estadoLead,  
    fechaPrueba, horaPrueba, asignaturaPrueba, academiaPrueba
  } = nuevoLead 

  const result = {
    isValid: true,
    errors: [],
    warnings: []
  }

  if (isBlank(personaContacto)) {
    result.isValid = false
    result.errors.push('Es necesario indicar la persona de contacto')
  }

  if (isBlank(nombreContacto)) {
    result.isValid = false
    result.errors.push('El nombre del contacto es obligatorio')
  }

  if (areBlank(telefonoContacto, telefonoAlumno)) {
    result.isValid = false
    result.errors.push('Es necesario indicar un teléfono de contacto')
  }

  if (isBlank(academia)) {
    result.isValid = false
    result.errors.push('Es necesario asignar el lead a una academia')
  }

  if (isBlank(estadoLead)) {
    result.isValid = false
    result.errors.push('Es necesario indicar el estado del lead')
  }

  if (isBlank(comoConoce)) {
    result.isValid = false
    result.errors.push('Es necesario indicar cómo ha conocido mundoestudiante')
  }

  if (isBlank(formaContacto)) {
    result.isValid = false
    result.errors.push('Es necesario indicar la forma de contacto')
  }

  if (estadoLead === 'PRUEBA' && isAnyBlank(fechaPrueba, horaPrueba, asignaturaPrueba, academiaPrueba)) {
    result.isValid = false
    result.errors.push('Es necesario indicar todos los datos de la prueba')
  }

  if (!isBlank(emailContacto) && !isEmail(emailContacto)) {
    result.warnings.push('El email de contacto no parece válido')
  }

  if (!isBlank(emailAlumno) && !isEmail(emailAlumno)) {
    result.warnings.push('El email del alumno no parece tener un formato correcto')
  }

  return result
}