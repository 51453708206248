import { useEffect, useState } from "react"
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@mui/material"
import { isAnyBlank } from "../../utils/string"
import FechaSelector from "../../components/form/FechaSelector/FechaSelector"
import HoraSelector from "../../components/form/HoraSelector/HoraSelector"
import FormaContactoSelector from "../../components/form/FormaContactoSelector/FormaContactoSelector"
import { dateToString, dateToTimeString } from "../../utils/date"
import css from './ModalContactoLead.module.css'

const ModalContactoLead = ({ open, disabled, onSubmit, onClose }) => {

  const [lead, setLead] = useState({
    fecha: null,
    hora: null,
    forma_contacto: '',
    observaciones: '',
  })

  useEffect(()=> {
    setLead({
      fecha: null,
      hora: null,
      forma_contacto: '',
      observaciones: '',
    })
  }, [open])

  const isSubmitDisabled = isAnyBlank(lead.fecha, lead.hora, lead.forma_contacto)

  const handleSubmit = () => {
    onSubmit({
      lead_status: 'LEAD',
      forma_contacto_lead: lead.forma_contacto,
      fecha_lead: dateToString(lead.fecha),
      hora_lead: dateToTimeString(lead.hora),
      observaciones: lead.observaciones,
    })
  }

  return (
    <Dialog onClose={onClose} open={open} fullWidth maxWidth='md'>
      <DialogTitle>Datos nuevo lead</DialogTitle>
      <DialogContent className={css.main}>
        <DialogContentText className={css.intro}>
          Rellena los datos del lead
        </DialogContentText>
        <FechaSelector
          className={css.input}
          label='Fecha próximo contacto'
          value={lead.fecha}
          disablePast
          onChange={fecha=> setLead({ ...lead, fecha })}
        />
        <HoraSelector
          className={css.input}
          label='Hora próximo contacto'
          value={lead.hora}
          onChange={(hora)=> setLead({ ...lead, hora })}
        />
        <FormaContactoSelector
          className={css.input}
          name='contacto'
          label='Forma de contacto'
          value={lead.forma_contacto}
          onChange={e=> setLead({ ...lead, forma_contacto: e.target.value })}
        />
        <TextField
          className={css.input}
          label="Observaciones"
          multiline
          value={lead.observaciones}
          onChange={e=> setLead({ ...lead, observaciones: e.target.value })}
          variant='standard'
        />
      </DialogContent>
      <DialogActions>
        <Button 
          color='error' 
          disabled={disabled}
          onClick={onClose}
        >
          Cancelar
        </Button>
        <Button 
          disabled={isSubmitDisabled || disabled} 
          color='primary' 
          onClick={handleSubmit}
        >
          Anotar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ModalContactoLead