import { useState } from 'react'
import { Accordion, AccordionSummary, AccordionDetails, Typography, IconButton } from '@mui/material'
import TextLabel from '../../../../../components/form/TextLabel/TextLabel'
import css from './DatosFacturacion.module.css'

const DatosFacturacion = ({ 
  facturacion,
  onEdit,
 })=> {

  const [isExpanded, setExpanded] = useState(true)

  const handleChange = (_event, isExpanded) => {
    setExpanded(isExpanded)
  }

  const handleEdit = (e)=> {
    e.stopPropagation()
    onEdit()
  }

  const { nombre='', identificacion='' } = facturacion || {}

  return (
    <Accordion expanded={isExpanded} onChange={handleChange}>
      <AccordionSummary
        expandIcon={<i className='material-icons'>expand_more</i>}
      >
        <div className={css.label}>
          <Typography className={css.title} variant="h2">Datos de facturación</Typography>
          {isExpanded && (
            <IconButton className={css.button} size='small' color='secondary' onClick={handleEdit}>
              <i className='material-icons'>edit</i>
            </IconButton>
          )}
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <div className={css.row}>
          <TextLabel
            className={css.field}
            name='nombre'
            label='Titular factura'
            value={nombre}
          />
          <TextLabel
            className={css.field}
            name='identificacion'
            label='Identificación factura (CIF / NIF)'
            value={identificacion}
          />
        </div>
      </AccordionDetails>
    </Accordion>
  )
}

export default DatosFacturacion