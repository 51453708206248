import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'

const ModalConfirmacion = ({
  open,
  onClose,
  onClick,
  title = '¿Estás seguro?',
  content = 'Esta operación no puede deshacerse',
  okText = 'Confirmar',
  cancelText = 'Cancelar'
})=> (
  <Dialog open={open} onClose={onClose}>
    <DialogTitle>{title}</DialogTitle>
    <DialogContent>
      <DialogContentText>{content}</DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose}>{cancelText}</Button>
      <Button color='error' onClick={onClick}>{okText}</Button>
    </DialogActions>
  </Dialog>
)

export default ModalConfirmacion
