import { useEffect, useState } from "react"
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel } from "@mui/material"
import { isAnyBlank } from "../../utils/string"
import AsignaturaSelector from "../../components/form/AsignaturaSelector/AsignaturaSelector"
import ProfesorSelector from "../../components/form/ProfesorSelector/ProfesorSelector"
import TipoAsistenciaSelector from "../../components/form/TipoAsistenciaSelector/TipoAsistenciaSelector"
import css from './ModalEditarPrueba.module.css'


const ModalEditarPrueba = ({ open, pruebaAlumno, disabled, onSubmit, onClose }) => {
  
  const [prueba, setPrueba] = useState({
    asignatura: '',
    asistencia: '',
    confirmacion: false,
    profesor: '',
  })

  useEffect(()=> {
    setPrueba({
      asignatura: '',
      asistencia: '',
      confirmacion: false,
      profesor: '',
    })
  }, [open])

  useEffect(()=> {
    if (!pruebaAlumno) return
    setPrueba({
      asignatura: pruebaAlumno.asignaturaId,
      asistencia: pruebaAlumno.asistencia,
      confirmacion: pruebaAlumno.confirmada,
      profesor: pruebaAlumno.profesorId,
    })
  }, [pruebaAlumno])

  const isSubmitDisabled = isAnyBlank(prueba.asignatura, prueba.asistencia)

  const handleSubmit = () => {
    onSubmit({
      fecha: pruebaAlumno.fechaPrueba,
      hora: pruebaAlumno.hora,
      asignatura: prueba.asignatura,
      asistencia: prueba.asistencia,
      confirmacion: prueba.confirmacion,
      profesor: prueba.profesor,
    })
  }

  return (
    <Dialog onClose={onClose} open={open} fullWidth maxWidth='md'>
      <DialogTitle>Editar datos de la prueba</DialogTitle>
      <DialogContent className={css.main}>
        <DialogContentText className={css.intro}>
          Puedes modificar los datos de la prueba para este lead
        </DialogContentText>
        <AsignaturaSelector
          className={css.input}
          name='asignatura'
          label='Asignatura'
          value={prueba.asignatura}
          onChange={(_e, value)=> setPrueba({ ...prueba, asignatura: value?.id || '' })}
        />
        <TipoAsistenciaSelector
          className={css.input}
          name='asistencia'
          label='Asistencia'
          value={prueba.asistencia}
          onChange={(e)=> setPrueba({ ...prueba, asistencia: e.target.value || '' })}
        />
        <ProfesorSelector
          className={css.input}
          name='profesor'
          label='Profesor'
          value={prueba.profesor}
          onChange={(_e, value)=> setPrueba({ ...prueba, profesor: value?.id || '' })}
        />
        <FormControlLabel
          control={(
            <Checkbox 
              checked={prueba.confirmacion} 
              onChange={(e)=> setPrueba({ ...prueba, confirmacion: e.target.checked })}
            />
          )} 
          label='Confirmar la prueba'
        />
      </DialogContent>
      <DialogActions>
        <Button 
          color='error' 
          disabled={disabled}
          onClick={onClose}
        >
          Cancelar
        </Button>
        <Button 
          disabled={isSubmitDisabled || disabled}
          color='primary' 
          onClick={handleSubmit}
        >
          Modificar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ModalEditarPrueba