import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import css from './TipoEventoSelector.module.css'

const TIPOS = [
  { value: 'ALERTA', label: 'Alerta' },
  { value: 'AVISO', label: 'Aviso' },
  { value: 'NOTIFICACION', label: 'Notificacion' },
  { value: 'RECORDATORIO', label: 'Recordatorio' },
]

const EventoIcon = ({ value })=> {
  if (!value) return null
  return (
    <i className='material-icons'>
      {value === 'COMUNICADO' && 'campaign'}
      {value === 'AVISO' && 'info'}
      {value === 'NOTIFICACION' && 'notifications'}
      {value === 'RECORDATORIO' && 'alarm'}
      {value === 'ALERTA' && 'warning'}
    </i>
  )
}

const TipoEventoSelector = ({ className, name, label, value, onChange }) => (
  <FormControl
    className={`${className} ${css.main}`}
    variant='standard'
    margin='dense'
    fullWidth
  >
    <InputLabel id={name}>{label}</InputLabel>
    <Select
      name={name}
      labelId={name}
      id={name}
      value={value}
      renderValue={value => (
        <div className={css.option}>
          <EventoIcon value={value} />
          {TIPOS.find(tipo => tipo.value === value)?.label || ''}
        </div>
      )}    
      onChange={onChange}
      label={label}
    >
      {TIPOS.map(tipo => (
        <MenuItem classes={{ root: css.option }} key={tipo.value} value={tipo.value}>
          <EventoIcon value={tipo.value} />
          {tipo.label}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
)

export default TipoEventoSelector
