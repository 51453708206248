import { useEffect, useState } from "react"
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@mui/material"
import { areBlank, isAnyBlank } from "../../utils/string"
import LeadContactoSelector from "../../components/form/LeadContactoSelector/LeadContactoSelector"
import TextLabel from "../../components/form/TextLabel/TextLabel"
import css from './ModalEditarDatosContactoLead.module.css'

const ModalEditarDatosContactoLead = ({ open, lead, onSubmit, onClose }) => {

  const [datos, setDatos] = useState({
    contacto: '',
    nombreContacto: '',
    emailContacto: '',
    telefonoContacto: '',
    nombreAlumno: '',
    emailAlumno: '',
    telefonoAlumno: '',
  })

  useEffect(()=> {
    setDatos({
      contacto: lead.contacto,
      nombreContacto: lead.nombreContacto,
      emailContacto: lead.emailContacto,
      telefonoContacto: lead.telefonoContacto,
      nombreAlumno: lead.nombreAlumno,
      emailAlumno: lead.emailAlumno,
      telefonoAlumno: lead.telefonoAlumno,
    })
  }, [open, lead])

  useEffect(() => {
    if (datos.contacto === 'Propio alumno') {
      setDatos(currentLead=> ({
        ...currentLead,
        nombreAlumno: datos.nombreContacto,
        emailAlumno: datos.emailContacto,
        telefonoAlumno: datos.telefonoContacto
      }))
    }
  }, [datos.contacto, datos.nombreContacto, datos.emailContacto, datos.telefonoContacto])

  const isSubmitDisabled = (
    isAnyBlank(datos.contacto, datos.nombreContacto) ||
    areBlank(datos.telefonoContacto, datos.telefonoAlumno)
  )

  const handleChange = (name, value) => {
    setDatos(currentDatos => ({
      ...currentDatos,
      [name]: value
    }))
  }

  const handleSubmit = () => {
    onSubmit({ id: lead.id, ...datos })
  }

  return (
    <Dialog onClose={onClose} open={open} fullWidth maxWidth='md'>
      <DialogTitle>Editar lead</DialogTitle>
      <DialogContent className={css.main}>
        <DialogContentText className={css.intro}>
          Modifica los datos de contacto de este lead
        </DialogContentText>
        <div className={css.content}>
          <section className={css.section}>
            <LeadContactoSelector
              name='contacto'
              className={css.input}
              value={datos.contacto}
              onChange={(_event, value) => handleChange('contacto', value)}
            />
            <TextField
              className={css.input}
              label='Nombre de contacto'
              value={datos.nombreContacto}
              onChange={e => handleChange('nombreContacto', e.target.value)}
              variant='standard'
            />
            <TextField
              className={css.input}
              label='Email de contacto'
              value={datos.emailContacto}
              onChange={e => handleChange('emailContacto', e.target.value)}
              type='email'
              variant='standard'
            />
            <TextField
              className={css.input}
              label='Teléfono de contacto'
              value={datos.telefonoContacto}
              onChange={e => handleChange('telefonoContacto', e.target.value)}
              variant='standard'
            />
          </section>
          <section className={css.section}>
            <TextLabel
              className={css.input}
              name='relacion'
              label='Persona de interés'
              value='Alumno'
              disabled
            />
            <TextField
              className={css.input}
              label='Nombre del alumno'
              value={datos.nombreAlumno}
              onChange={e => handleChange('nombreAlumno', e.target.value)}
              variant='standard'
              disabled={datos.contacto === 'Propio alumno'}
            />
            <TextField
              className={css.input}
              label='Email del alumno'
              value={datos.emailAlumno}
              onChange={e => handleChange('emailAlumno', e.target.value)}
              type='email'
              variant='standard'
              disabled={datos.contacto === 'Propio alumno'}
            />
            <TextField
              className={css.input}
              label='Teléfono del alumno'
              value={datos.telefonoAlumno}
              onChange={e => handleChange('telefonoAlumno', e.target.value)}
              variant='standard'
              disabled={datos.contacto === 'Propio alumno'}
            />
          </section>
        </div>
      </DialogContent>
      <DialogActions>
        <Button color='error' onClick={onClose}>Cancelar</Button>
        <Button disabled={isSubmitDisabled} color='primary' onClick={handleSubmit}>Actualizar</Button>
      </DialogActions>
    </Dialog>
  )
}

export default ModalEditarDatosContactoLead