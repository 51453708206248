import { useEffect, useState } from 'react'
import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material'
import { isAnyBlank } from '../../utils/string'
import css from './ModalEditarFacturacionAlumno.module.css'

const ModalEditarFacturacionAlumno = ({
  open,
  facturacion,
  onClose,
  onSubmit,
}) => {

  const [factura, setFactura] = useState({
    nombre: '',
    identificacion: '',
  })

  useEffect(()=> {
    const { nombre = '', identificacion = '' } = facturacion || {}
    setFactura({
      nombre: nombre,
      identificacion: identificacion,
    })
  }, [open, facturacion])

  const handleChange = event => {
    setFactura(state => ({
      ...state,
      [event.target.name]: event.target.value
    }))
  }

  const isSubmitDisabled = isAnyBlank(factura.nombre, factura.identificacion)

  return (
    <Dialog onClose={onClose} open={open} fullWidth maxWidth='md'>
      <DialogTitle>Editar datos de facturas</DialogTitle>
      <DialogContent>
        <Alert severity='warning'>
          Modificar estos datos no afectará a las facturas ya emitidas.
        </Alert>
      </DialogContent>
      <DialogContent>
        <TextField
          className={css.input}
          id='nombre'
          name='nombre'
          label='Titular de la factura'
          variant='standard'
          margin='dense'
          value={factura.nombre}
          onChange={handleChange}
          fullWidth
        />
        <TextField
          className={css.input}
          id='identificacion'
          name='identificacion'
          label='Identificación de la factura (CIF / NIF)'
          variant='standard'
          margin='dense'
          value={factura.identificacion}
          onChange={handleChange}
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button color='error' onClick={onClose}>
          Cancelar
        </Button>
        <Button
          color='primary'
          disabled={isSubmitDisabled}
          onClick={() => onSubmit(factura)}
        >
          Enviar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ModalEditarFacturacionAlumno
