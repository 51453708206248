import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import { useQuery } from "@tanstack/react-query"
import { useNotification } from "../../../context/NotificationManager/NotificationManager"
import { getProfesorList } from '../../../utils/api/profesores'
import css from './ProfesorSelector.module.css'

const ProfesorSelector = ({ 
  className, 
  multiple=false, 
  name, 
  label, 
  value, 
  onChange 
})=> {

  const notification = useNotification()

  const { isLoading, data: profesorList=[] } = useQuery({
    queryKey: ['personal', 'profesor', 'list'], 
    queryFn: ()=> getProfesorList()
      .then(datos=> datos.sort((a1, a2)=> a1.nombre.localeCompare(a2.nombre)))
      .catch(err=> {
        notification.error({ title: 'Error al recuperar los profesores', content: err })
        return []
      })
  })

  const selectedValue = profesorList.find(profesor=> profesor.id === value) || null

  return (
    <Autocomplete
      id={name} 
      className={`${className} ${css.main}`}
      multiple={multiple}
      options={profesorList}
      getOptionLabel={option=> option.nombre || ''}
      loading={isLoading}
      loadingText='Cargando profesores...'
      noOptionsText='No hay profesores'
      value={selectedValue}
      isOptionEqualToValue={(option, value)=> option.id === value.id}
      onChange={onChange}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant="standard"
        />
      )}
    />
  )
}

export default ProfesorSelector