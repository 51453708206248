export const getTotales = ({ total })=> {
  if (!total) return { alumnos: 0, detalles: [] }
  const detalles = [ 'CIENCIAS', 'LETRAS', 'IDIOMAS', 'OTRAS' ].reduce((acc, curr)=> {
    if (!total[curr]) return acc
    return [
      ...acc, 
      { type: curr, value: total[curr] }
    ]
  }, [])
  return {
    alumnos: detalles.reduce((acc, curr)=> acc + curr.value, 0),
    detalles,
  }
}

export const mergeClases = (clases)=> {
  return clases
    .map(clase=> ({ ...clase, count: 1 }))
    .sort((a, b)=> {
      if (a.fecha < b.fecha) return -1
      if (a.fecha > b.fecha) return 1
      if (a.hora < b.hora) return -1
      if (a.hora > b.hora) return 1
      return 0
    })
    .reduce((acc, curr)=> {
      if (acc.length === 0) return [curr]
      const ultimaClase = acc[acc.length-1]
      const horasConsecutivas = ultimaClase.hora.substr(-5) === curr.hora.substring(0, 5)
      const mismoCentro = ultimaClase.centro === curr.centro
      const mismaAsignatura = ultimaClase.asignaturaId === curr.asignaturaId
      if (horasConsecutivas && mismoCentro && mismaAsignatura) {
        acc[acc.length-1] = {
          ...ultimaClase,
          hora: `${ultimaClase.hora.substr(0, 5)}-${curr.hora.substr(-5)}`,
          count: ultimaClase.count + 1
        }
        return acc
      }
      return [...acc, curr]
    }, [])
}