import { useEffect, useState } from "react"
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material"
import AsignaturaSelector from "../../components/form/AsignaturaSelector/AsignaturaSelector"
import CursoSelector from "../../components/form/CursoSelector/CursoSelector"
import NivelEstudiosSelector from "../../components/form/NivelEstudiosSelector/NivelEstudiosSelector"
import CentroEstudiosSelector from "../../components/form/CentroEstudiosSelector/CentroEstudiosSelector"
import css from './ModalEditarDatosEstudiosLead.module.css'

const ModalEditarDatosEstudiosLead = ({ open, lead, onSubmit, onClose }) => {

  const [datos, setDatos] = useState({
    centroEstudios: '',
    curso: '',
    tipologiaAcademica: '',
    asignaturas: [],
  })

  useEffect(()=> {
    setDatos({
      centroEstudios: lead.centroEstudios,
      curso: lead.curso,
      tipologiaAcademica: lead.tipologiaAcademica,
      asignaturas: lead.asignaturas.map(asignatura => asignatura.id),
    })
  }, [open, lead])

  const handleChange = (name, value) => {
    setDatos(currentDatos => ({
      ...currentDatos,
      [name]: value
    }))
  }

  const handleSubmit = () => {
    onSubmit({ 
      id: lead.id, 
      ...datos,
      asignaturas: datos.asignaturas
    })
  }

  return (
    <Dialog onClose={onClose} open={open} fullWidth maxWidth='md'>
      <DialogTitle>Editar lead</DialogTitle>
      <DialogContent className={css.main}>
        <DialogContentText className={css.intro}>
          Modifica los datos de estudios de este lead
        </DialogContentText>
        <section className={css.section}>
          <CentroEstudiosSelector
            className={css.input}
            name='centroEstudios'
            label='Centro de estudios'
            value={datos.centroEstudios}
            onChange={value => handleChange('centroEstudios', value)}
          />
          <NivelEstudiosSelector
            className={css.input}
            label='Nivel de estudios'
            name='tipologiaAcademica'
            value={datos.tipologiaAcademica}
            onChange={e => handleChange('tipologiaAcademica', e.target.value)}
          />
          <CursoSelector
            className={css.input}
            label='Curso'
            name='curso'
            value={datos.curso}
            onChange={e => handleChange('curso', e.target.value)}
          />
          <AsignaturaSelector
            className={css.input}
            multiple
            name='asignaturas'
            label='Asignaturas de interés'
            value={datos.asignaturas}
            onChange={(_event, value) => handleChange('asignaturas', value.map(a=> a.id))}
          />
        </section>
      </DialogContent>
      <DialogActions>
        <Button color='error' onClick={onClose}>Cancelar</Button>
        <Button color='primary' onClick={handleSubmit}>Actualizar</Button>
      </DialogActions>
    </Dialog>
  )
}

export default ModalEditarDatosEstudiosLead