import { useRef, useState } from 'react'
import { ListItemIcon, ListItemText, Menu, MenuItem, Typography } from '@mui/material'
import css from './PageDropdown.module.css'

const PageDropdown = ({ label, pages }) => {

  const [isOpen, setOpen] = useState(false)

  const ref = useRef(null)

  return (
    <div className={css.dropdown}>
      <Typography 
        className={css.title} 
        variant="body1" 
        onClick={()=> setOpen(true)}>
        {label}
      </Typography>
      <div ref={ref}>
        <Menu
          open={isOpen}
          onClose={()=> setOpen(false)}
          anchorEl={ref.current}
        >
          {pages.map((page, i) => (
            <MenuItem key={i} onClick={page.onClick}>
              <ListItemIcon>
                <i className='material-icons'>{page.icon}</i>
              </ListItemIcon>
              <ListItemText>{page.name}</ListItemText>
            </MenuItem>
          ))}
        </Menu>
      </div>
    </div>
  )
}

export default PageDropdown