import css from './PageLoading.module.css'

export const LoadingSpinner = ({ className='' }) => (
  <div className={`${className} ${css.loading}`}>
    <div className={css.spinner} />
  </div>
)

const PageLoading = ({ className, isLoading, children }) => {
  if (isLoading) return <LoadingSpinner className={className} />
  else return children
}

export default PageLoading
