import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import css from './FormaPagoSelector.module.css'

const FORMAS_PAGO = [
  { value: 'EFECTIVO', label: 'Efectivo' },
  { value: 'TARJETA', label: 'Tarjeta' },
  { value: 'TRANSFERENCIA', label: 'Transferencia' }
]

const FormaPagoSelector = ({
  className,
  name,
  label,
  value,
  onChange,
}) => (
  <FormControl
    className={`${className} ${css.main}`}
    variant='standard'
    margin='dense'
    fullWidth
  >
    <InputLabel id={name}>{label}</InputLabel>
    <Select
      name={name}
      labelId={name}
      id={name}
      value={value}
      onChange={onChange}
      label={label}
    >
      {FORMAS_PAGO.map(forma => (
        <MenuItem key={forma.value} value={forma.value}>
          {forma.label}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
)

export default FormaPagoSelector
