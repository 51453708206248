import { isAuthenticated } from './utils/auth'
import { Routes, Navigate, Route } from 'react-router'

import Login from './views/Login/Login'
import Layout from './layout/Layout/Layout'

const Authentication = ({ requiresAuth, to, children })=> {
  const auth = isAuthenticated()
  const match = (auth && requiresAuth) || (!auth && !requiresAuth)
  if (match) return children
  else return <Navigate to={to} />
}


const App = ()=> (
  <Routes>
    <Route 
      path='/login' 
      element={(
        <Authentication requiresAuth={false} to='/'>
          <Login />
        </Authentication>
      )} 
    />
    <Route
      path='*'
      element={(
        <Authentication requiresAuth={true} to='/login'>
          <Layout />
        </Authentication>
      )}
    />
  </Routes>
)

export default App
