import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import { useQuery } from '@tanstack/react-query'
import { useNotification } from '../../../context/NotificationManager/NotificationManager'
import { getOpenLeads } from '../../../utils/api/leads'
import css from './LeadSelector.module.css'

const LeadSelector = ({
  className,
  name,
  label,
  value,
  onChange
}) => {
  const notification = useNotification()

  const { isLoading, data: leadList=[] } = useQuery({
    queryKey: ['marketing', 'feedback', 'list', 'open'], 
    queryFn: ()=> getOpenLeads()
      .then(datos=> datos.sort((a1, a2)=> {
        const alumnoSort = (a1.alumno || '').localeCompare((a2.alumno || ''))
        if (alumnoSort !== 0) return alumnoSort
        const contactoSort = (a1.contacto || '').localeCompare((a2.contacto || ''))
        return contactoSort
      }))
      .catch(err=> {
        notification.error({ title: 'Error al recuperar los leads', content: err })
        return []
      })
  })

  const selectedValue = leadList.find(lead=> lead.id === value) || null

  const handleAvailableOptions = (options, { inputValue }) => {
    return options.filter(option => {
      const { contacto, alumno, email, telefono } = option
      const search = inputValue.toLowerCase()
      const options = [contacto || '', alumno || '', email || '', telefono || '']
      return options.some(option => option.toLowerCase().includes(search))
    })
  }

  const getLabel = option => {
    if (!option) return ''
    const { alumno, contacto, estado } = option
    const nombre = (alumno && alumno !== '--') ? alumno : (contacto || '(Lead sin nombre)')
    return `${nombre} (${estado})`
  }

  return (
    <Autocomplete
      id={name}
      className={`${className} ${css.main}`}
      options={leadList}
      filterOptions={handleAvailableOptions}
      getOptionLabel={option=> option ? getLabel(option) : ''}
      loading={isLoading}
      loadingText='Cargando leads...'
      noOptionsText='No hay leads'
      value={selectedValue}
      isOptionEqualToValue={(option, value)=> option.id === value.id}
      onChange={(_event, value) => onChange(value ? value.id : '')}
      renderInput={params => (
        <TextField
          {...params}
          name={name}
          label={label}
          variant='standard'
          fullWidth
        />
      )}
    />
  )
}
export default LeadSelector
