import { useEffect, useState } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import CursoSelector from '../../components/form/CursoSelector/CursoSelector'
import ObjetivoAlumnoSelector from '../../components/form/ObjetivoAlumnoSelector/ObjetivoAlumnoSelector'
import NivelEstudiosSelector from '../../components/form/NivelEstudiosSelector/NivelEstudiosSelector'
import CentroEstudiosSelector from '../../components/form/CentroEstudiosSelector/CentroEstudiosSelector'
import TextLabel from '../../components/form/TextLabel/TextLabel'
import TarifaSelector from '../../components/form/TarifaSelector/TarifaSelector'
import { isAnyBlank } from '../../utils/string'
import css from './ModalEditarTarifa.module.css'

const ModalEditarTarifa = ({
  open,
  tarifa,
  onClose,
  onSubmit,
})=> {

  const [datos, setDatos] = useState({
    tarifa: '',
    centro_estudios: '',
    formacion: '',
    curso: '0',
    objetivo: '',
  })

  useEffect(() => {
    if (!tarifa) return
    setDatos({
      tarifa: tarifa.tarifa,
      centro_estudios: tarifa.centroEstudios,
      formacion: tarifa.tipologiaAcademica,
      curso: tarifa.curso || '0',
      objetivo: tarifa.objetivos,
    })
  }, [tarifa])

  const isSubmitDisabled = isAnyBlank(
    datos.tarifa,
    datos.centro_estudios,
    datos.formacion,
    datos.curso,
    datos.objetivo
  )

  return (
    <Dialog onClose={onClose} open={open} fullWidth maxWidth='md'>
      <DialogTitle>Editar tarifa </DialogTitle>
      <DialogContent className={css.content}>
        <TarifaSelector
          className={css.input}
          name='tarifa_contratada'
          label='Tarifa a contratar'
          value={datos.tarifa}
          extraOption={tarifa?.tarifa || null}
          onChange={e=> setDatos(tarifa=> ({...tarifa, tarifa: e.target.value}))}
        />
        <TextLabel
          className={css.input}
          name='mes'
          label='Mes de la tarifa'
          value={tarifa?.mes || ''}
          disabled
        />
        <CentroEstudiosSelector
          className={css.input}
          name='estudios'
          label='Centro de estudios'
          value={datos.centro_estudios}
          onChange={value=> setDatos(tarifa=> ({...tarifa, centro_estudios: value}))}
        />
        <NivelEstudiosSelector
          className={css.input}
          name='formacion'
          label='Nivel'
          value={datos.formacion}
          onChange={e=> setDatos(tarifa=> ({...tarifa, formacion: e.target.value}))}
        />
        <CursoSelector
          className={css.input}
          name='curso'
          label='Curso'
          value={datos.curso}
          onChange={e=> setDatos(tarifa=> ({...tarifa, curso: e.target.value}))}
        />
        <ObjetivoAlumnoSelector
          className={css.input}
          name='objetivos'
          label='Objetivo'
          value={datos.objetivo}
          onChange={e=> setDatos(tarifa=> ({...tarifa, objetivo: e.target.value}))}
        />
      </DialogContent>
      <DialogActions>
        <Button color='error' onClick={onClose}>
          Cancelar
        </Button>
        <Button
          color='primary'
          disabled={isSubmitDisabled}
          onClick={() => onSubmit(datos)}
        >
          Enviar
        </Button>
      </DialogActions>
    </Dialog>
  )
}
export default ModalEditarTarifa
