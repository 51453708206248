import { useState } from 'react'
import { Alert, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import CurrencyInput from '../../components/form/CurrencyInput/CurrencyInput'
import { isAnyBlank } from '../../utils/string'
import FormaPagoSelector from '../../components/form/FormaPagoSelector/FormaPagoSelector'
import css from './ModalNuevoIngresoPago.module.css'

const ModalNuevoIngresoPago = ({
  open,
  pago,
  onClose,
  onSubmit,
})=> {

  const [formaPago, setFormaPago] = useState('')
  const [importe, setImporte] = useState('')

  const isImporteValido = (pago?.importeRestante || 0) >= +importe

  const isSubmitDisabled = isAnyBlank(formaPago, importe) || !isImporteValido

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth='sm'>
      <DialogTitle>Nuevo ingreso</DialogTitle>
      <DialogContent className={css.main}>
        <DialogContentText className={css.intro}>
          Vas a añadir un nuevo ingreso para {pago?.alumno}.
          <br/> 
          El dinero se asignará directamente al pago de <strong>{pago?.concepto}</strong>.
        </DialogContentText>
        {!isImporteValido && (
          <Alert className={css.alert} severity='error'>
            El importe marcado es superior al importe que queda pendiente de pago.
          </Alert>
        )}
        <FormaPagoSelector
          className={css.input}
          name='formaPago'
          label='Forma de pago'
          value={formaPago}
          onChange={e=> setFormaPago(e.target.value)}
        />
        <CurrencyInput
          className={css.input}
          name='importe'
          label='Importe'
          value={importe}
          onChange={value=> setImporte(value)}
        />
      </DialogContent>
      <DialogActions>
        <Button color='error' onClick={onClose}>
          Cancelar
        </Button>
        <Button 
          color='primary' 
          disabled={isSubmitDisabled}
          onClick={()=> onSubmit({ id: pago.id, formaPago, importe })}
        >
          Añadir ingreso
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ModalNuevoIngresoPago
