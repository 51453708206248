import { useLocation } from 'react-router'
import HelpContent from './content/HelpContent'
import { Dialog, DialogTitle } from '@mui/material'
import css from './Help.module.css'

const Help = ({ open, onClose })=> {

  const location = useLocation()

  return (
    <Dialog 
      open={open} 
      onClose={onClose} 
      fullWidth
      maxWidth='lg'
    >
      <DialogTitle>Información</DialogTitle>
      <div className={css.main}>
        <HelpContent location={location.pathname} />
      </div>
    </Dialog>
  )
}

export default Help