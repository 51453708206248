import { useEffect, useState } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import CursoSelector from '../../components/form/CursoSelector/CursoSelector'
import ObjetivoAlumnoSelector from '../../components/form/ObjetivoAlumnoSelector/ObjetivoAlumnoSelector'
import NivelEstudiosSelector from '../../components/form/NivelEstudiosSelector/NivelEstudiosSelector'
import CentroEstudiosSelector from '../../components/form/CentroEstudiosSelector/CentroEstudiosSelector'
import TextLabel from '../../components/form/TextLabel/TextLabel'
import { isAnyBlank, isBlank } from '../../utils/string'
import { useNotification } from '../../context/NotificationManager/NotificationManager'
import { getPack } from '../../utils/api/packs'
import { useQuery } from '@tanstack/react-query'
import VolumenHorasPackSelector from '../../components/form/VolumenHorasPackSelector/VolumenHorasPackSelector'
import css from './ModalEditarPackHoras.module.css'

const ModalEditarPackHoras = ({
  open,
  pack,
  onClose,
  onSubmit
})=> {

  const notification = useNotification()

  const [datos, setDatos] = useState({
    centro_estudios: '',
    formacion: '',
    curso: '',
    objetivo: '',
    horas: '',
  })

  useEffect(()=> {
    if (!pack) return
    setDatos({
      centro_estudios: pack.centroEstudios,
      formacion: pack.tipologiaAcademica,
      curso: pack.curso,
      objetivo: pack.objetivo,
      horas: pack.horas,
    })
  }, [pack])

  const nombrePack = pack?.pack || ''

  const { data: datosPackHoras={} } = useQuery({
    queryKey: ['datos', 'pack-horas', nombrePack],
    enabled: open && !isBlank(nombrePack),
    queryFn: ()=> getPack(nombrePack)
      .then(datos=> datos)
      .catch(err=> {
        notification.error({ title: 'Error con el pack de horas', content: err })
        return {}
      })
  })

  const isSubmitDisabled = isAnyBlank(
    datos.centro_estudios,
    datos.formacion,
    datos.curso,
    datos.objetivo,
    datos.horas
  )

  return (
    <Dialog onClose={onClose} open={open} fullWidth maxWidth='md'>
      <DialogTitle>Editar pack de horas</DialogTitle>
      <DialogContent className={css.content}>
        <TextLabel
          className={css.input}
          name='pack'
          label='Pack'
          value={nombrePack}
          disabled
        />
        <VolumenHorasPackSelector
          className={css.input}
          name='horas'
          label='Horas a contratar'
          value={datos.horas}
          pack={datosPackHoras}
          onChange={e=> setDatos(pack=> ({...pack, horas: e.target.value}))}
        />
        <CentroEstudiosSelector
          className={css.input}
          name='estudios'
          label='Centro de estudios'
          value={datos.centro_estudios}
          onChange={value=> setDatos(pack=> ({...pack, centro_estudios: value}))}
        />
        <NivelEstudiosSelector
          className={css.input}
          name='nivel'
          label='Nivel'
          value={datos.formacion}
          onChange={e=> setDatos(pack=> ({...pack, formacion: e.target.value}))}
        />
        <CursoSelector
          className={css.input}
          name='curso'
          label='Curso'
          value={datos.curso}
          onChange={e=> setDatos(pack=> ({...pack, curso: e.target.value}))}
        />
        <ObjetivoAlumnoSelector
          className={css.input}
          name='objetivo'
          label='Objetivo'
          value={datos.objetivo}
          onChange={e=> setDatos(pack=> ({...pack, objetivo: e.target.value}))}
        />
      </DialogContent>
      <DialogActions>
        <Button color='error' onClick={onClose}>
          Cancelar
        </Button>
        <Button
          color='primary'
          disabled={isSubmitDisabled}
          onClick={() => onSubmit(datos)}
        >
          Enviar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ModalEditarPackHoras
