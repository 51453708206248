import { useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { useNotification } from '../context/NotificationManager/NotificationManager'
import { getLead } from '../utils/api/leads'
import { dateToString, dateToStringLong, dateToTimeString } from '../utils/date'

const useLead = () => {
  
  const notification = useNotification()

  const { id } = useParams()

  const { isLoading, data: lead = {} } = useQuery({
    queryKey: ['marketing', 'lead', id],
    enabled: !!id,
    queryFn: () => getLead(id)
      .then(datos => ({
        ...datos,
        contactos: datos.contactos.map(contacto => ({
          ...contacto,
          fechaContacto: `${dateToStringLong(contacto.fechaContacto)} - ${dateToTimeString(contacto.fechaContacto)}`,
          proximoContacto: contacto.estadoFeedback === 'PRUEBA' ? dateToStringLong(contacto.proximoContacto) : `${dateToStringLong(contacto.proximoContacto)} - ${dateToTimeString(contacto.proximoContacto)}`,
          fechaPrueba: contacto.estadoFeedback === 'PRUEBA' ? dateToString(contacto.proximoContacto) : null,
          fechaIncorporacion: contacto.estadoFeedback === 'LEAD_CUALIFICADO' ? dateToStringLong(contacto.fechaIncorporacion) : null
        }))
      }))
      .catch(err => {
        notification.error({ title: 'Error al recuperar los datos del lead', content: err })
        return null
      })
  })

  return {
    isLoading,
    id,
    lead
  }
}

export default useLead
