import { FormControl, InputLabel, MenuItem, Select } from "@mui/material"
import { getRecentMonths } from "../../../utils/date"
import css from './MesRecienteSelector.module.css'

const MesRecienteSelector = ({ 
  className='',
  name,
  label,
  value,
  forwardOptions=1,
  backwardOptions=12,
  onChange 
})=> {

  const months = getRecentMonths({ date: new Date(), forwardOptions, backwardOptions })

  return (
    <FormControl 
      className={`${className} ${css.main}`} 
      variant="standard"
      fullWidth
    >
      <InputLabel id={name}>{label}</InputLabel>
      <Select
        labelId={name}
        id={name}
        name={name}
        value={value}
        onChange={onChange}
        label={label}
      >
        {months.map(mes=> <MenuItem key={mes.value} value={mes.value}>{mes.label}</MenuItem> )}
      </Select>
    </FormControl>
  )

}

export default MesRecienteSelector