import { useState } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material'
import CurrencyInput from '../../components/form/CurrencyInput/CurrencyInput'
import { isAnyBlank } from '../../utils/string'
import css from './ModalNuevoPago.module.css'

const ModalNuevoPago = ({
  open,
  onClose,
  onSubmit,
})=> {

  const [concepto, setConcepto] = useState('')
  const [importe, setImporte] = useState('')

  const isSubmitDisabled = isAnyBlank(concepto, importe)

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth='md'>
      <DialogTitle>Nuevo pago</DialogTitle>
      <DialogContent className={css.main}>
        <DialogContentText className={css.intro}>
          Vas a añadir un nuevo pago para este alumno.
        </DialogContentText>
        <TextField
          className={css.input}
          fullWidth
          name='concepto'
          label='Concepto del pago'
          variant='standard'
          value={concepto}
          onChange={e=> setConcepto(e.target.value)}
        />
        <CurrencyInput
          className={css.input}
          name='importe'
          label='Importe a pagar'
          value={importe}
          onChange={value=> setImporte(value)}
        />
      </DialogContent>
      <DialogActions>
        <Button color='error' onClick={onClose}>
          Cancelar
        </Button>
        <Button 
          color='primary' 
          disabled={isSubmitDisabled}
          onClick={()=> onSubmit({ concepto, importe })}
        >
          Crear pago
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ModalNuevoPago
