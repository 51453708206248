import { GET } from '../request'

export const getTarifas = () => GET('/api/agora/datos/tarifa/list')
export const getTarifasActivas = () => GET('/api/agora/datos/tarifa/list/active')

export const getIntensivos = () => GET('/api/agora/datos/intensivo/list')
export const getIntensivosActivos = () => GET('/api/agora/datos/intensivo/list/active')

export const getPacks = () => GET('/api/agora/datos/pack-horas/list')
export const getPacksActivos = () => GET('/api/agora/datos/pack-horas/list/active')
