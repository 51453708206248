import { FormControl, InputLabel, MenuItem, Select } from "@mui/material"
import css from './EjercicioSelector.module.css'

const MIN_YEAR = 2012
const MAX_YEAR = (new Date()).getFullYear() + 3

const EjercicioSelector = ({ 
  className, 
  name, 
  minYear=MIN_YEAR, 
  maxYear=MAX_YEAR, 
  label, 
  value, 
  onChange 
})=> {

  const options = new Array(maxYear - minYear).fill(minYear).map((e, i)=> e+i)

  return (
    <FormControl 
      className={`${className} ${css.main}`} 
      variant="standard"
      fullWidth
    >
      <InputLabel id={name}>{label}</InputLabel>
      <Select
        labelId={name}
        id={name}
        value={value}
        onChange={onChange}
        label={label}
      >
        {options.map(year=> <MenuItem key={year} value={year}>{year}</MenuItem> )}
      </Select>
    </FormControl>
  )

}

export default EjercicioSelector