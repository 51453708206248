import AlumnoHeader from '../../../components/AlumnoHeader/AlumnoHeader'
import Tabs from '../../../components/Tabs/Tabs'
import useAlumno from '../../../hooks/useAlumno'
import VerAsistencias from './tabs/VerAsistencias/VerAsistencias'
import GestionarAsistencias from './tabs/GestionarAsistencias/GestionarAsistencias'

const Asistencias = () => {

  const { id } = useAlumno()

  return (
    <>
      <AlumnoHeader label='Asistencias' />
      <Tabs
        tabs={[
          { 
            name: 'Ver asistencias', 
            content: <VerAsistencias alumnoId={id} />
          },
          { 
            name: 'Gestionar asistencias', 
            content: <GestionarAsistencias alumnoId={id} /> 
          },
        ]}
      />
    </>
  )
}

export default Asistencias
