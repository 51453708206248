import { useState } from 'react'
import { IconButton, Link } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { useNotification } from '../../../context/NotificationManager/NotificationManager'
import InformationTable from '../../../components/Table/InformationTable'
import TableHeader from '../../../components/TableHeader/TableHeader'
import PageLoading from '../../../components/PageLoading/PageLoading'
import EjercicioSelector from '../../../components/form/EjercicioSelector/EjercicioSelector'
import { downloadCSVFile, getCSVContent } from '../../../utils/csv'
import { dateSort, textFilter } from '../../../utils/table'
import { getFeedbackListYear } from '../../../utils/api/leads'
import { dateToString } from '../../../utils/date'
import css from './Leads.module.css'

const Leads = ()=> {

  const notification = useNotification()

  const [search, setSearch] = useState('')
  const [ejercicio, setEjercicio] = useState(new Date().getFullYear())

  const { isLoading, data: leadList=[] } = useQuery({
    queryKey: ['marketing', 'feedback', 'list', 'year', ejercicio], 
    queryFn: ()=> getFeedbackListYear(ejercicio)
      .then(datos=> datos.map(lead=> ({
        ...lead,
        fecha: dateToString(lead.fecha)
      })))
      .catch(err=> {
        notification.error({ title: 'Error al recuperar los leads', content: err })
        return []
      })
  })

  const handleExport = ()=> {
    const content = getCSVContent({
      columns: ['Contacto', 'Alumno', 'E-mail', 'Teléfono', 'Fecha'],
      data: leadList,
      transform: c=> ([
        c.contacto,
        c.alumno,
        c.telefono,
        c.email,
        c.fecha,
      ])
    })
    downloadCSVFile(content, `leads-${ejercicio}`)
  }
  
  const filterSearch = lead=> {
    return textFilter({ 
      object: lead, 
      fields: ['contacto', 'alumno', 'email', 'telefono'],
      search: search
    })
  }

  return (
    <PageLoading isLoading={isLoading}>
      <TableHeader
        actions={(
          <div className={css.actions}>
            <IconButton className={css.download} size='small' color='primary' onClick={handleExport}>
              <i className='material-icons'>download</i>
            </IconButton>
            <EjercicioSelector
              className
              name='ejercicio'
              label='Año'
              value={ejercicio}
              onChange={e=> setEjercicio(e.target.value)}
            />
          </div>
        )}
        title={`${leadList.length} leads de ${ejercicio}`}
        search={search}
        onSearchChange={e=> setSearch(e.target.value)}
      />
      <InformationTable
        details={[
          { title: 'Contacto', key: 'contacto' },
          { title: 'Alumno', key: 'alumno' },
          { title: 'E-mail', key: 'email', sortDisabled: true },
          { title: 'Teléfono', key: 'telefono', sortDisabled: true },
          { title: 'Fecha contacto', key: 'fecha', sortFunction: dateSort },
        ]}
        data={leadList.filter(filterSearch).map((lead) => ({
          contacto: (
            <Link className={css.link} href={`/leads/${lead.id}/ficha`}>
              {lead.contacto}
            </Link>
          ),
          alumno: lead.alumno || '--',
          telefono: lead.telefono || '--',
          email: lead.email || '--',
          fecha: lead.fecha || '--',
        }))}
      />
    </PageLoading>
  )
}

export default Leads
