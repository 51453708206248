import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import { useQuery } from '@tanstack/react-query'
import { useNotification } from '../../../context/NotificationManager/NotificationManager'
import { getCentrosEstudio } from '../../../utils/api/centrosEstudio'
import css from './CentroEstudiosSelector.module.css'

const CentroEstudiosSelector = ({
  className,
  name,
  label,
  value,
  onChange
}) => {
  const notification = useNotification()

  const { isLoading, data: centroList=[] } = useQuery({
    queryKey: ['centro_estudios', 'list'], 
    queryFn: ()=> getCentrosEstudio()
      .then(datos=> datos.sort((a1, a2)=> a1.nombre.localeCompare(a2.nombre)))
      .catch(err=> {
        notification.error({ title: 'Error al recuperar los centros de estudio', content: err })
        return []
      })
  })

  const centrosValidos = centroList.filter((centro, index, centros)=> {
    const { nombre, provincia } = centro
    const firstMatch = centros.findIndex(c=> c.nombre === nombre && c.provincia === provincia)
    return index === firstMatch
  })

  const selectedValue = centrosValidos.find(centro=> centro.nombre === value) || null

  const getLabel = option => {
    if (!option) return ''
    const { nombre, provincia } = option
    if (provincia) return `${nombre} (${provincia})`
    return nombre
  }

  return (
    <Autocomplete
      id={name}
      className={`${className} ${css.main}`}
      options={centrosValidos}
      getOptionLabel={option=> getLabel(option)}
      loading={isLoading}
      loadingText='Cargando centros de estudio...'
      noOptionsText='No hay centros de estudio'
      value={selectedValue}
      isOptionEqualToValue={(option, value)=> option.id === value.id}
      onChange={(_event, value) => onChange(value ? value.nombre : '')}
      renderInput={params => (
        <TextField
          {...params}
          name={name}
          label={label}
          variant='standard'
          fullWidth
        />
      )}
    />
  )
}
export default CentroEstudiosSelector
