import config from './config'

const removeEmptyProperties = (obj) => {
  return Object.keys(obj)
    .reduce((acc, key) => {
      if (obj[key] !== null && obj[key] !== undefined) acc[key] = obj[key]
      return acc
    }, {})
}

const getFetchOptions = (method) => ({
  method,
  mode: 'cors',
  cache: 'no-cache',
  credentials: 'same-origin',
  headers: {
    Authorization: `Bearer ${config.classfy_token}`
  }
})

const processRequest = ({ url, method }) => {
  const options = getFetchOptions(method)
  return new Promise((resolve, reject) => {
    fetch(url, options)
      .then(res => res.json())
      .then(res => {
        if (res.status === 'OK') {
          if (res.data !== null && res.data !== undefined) resolve(res.data)
          else resolve(res)
        } else reject(res.data.userMesage)
      })
      .catch(() => reject('No se ha podido procesar la petición'))
  })
}

export const GET = (path, data = {}) => {
  const url = config.classfy_api + path
  const details = removeEmptyProperties(data)
  const params = new URLSearchParams(details)
  return processRequest({ url: `${url}?${params}`, method: 'GET' })
}