import { useEffect, useState } from 'react'
import { Alert, AlertTitle, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'

const ModalNuevaFactura = ({
  open,
  onClose,
  onSubmit,
}) => {

  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true)

  useEffect(()=> {
    if (open) {
      setIsSubmitDisabled(true)
      const timer = setTimeout(()=> {
        setIsSubmitDisabled(false)
      }, 5000)
      return ()=> clearTimeout(timer)
    }
  }, [open])

  return (
    <Dialog onClose={onClose} open={open} fullWidth maxWidth='sm'>
      <DialogTitle>Nueva factura</DialogTitle>
      <DialogContent>
        <Alert severity='warning'>
          <AlertTitle>El sistema de facturación ha cambiado</AlertTitle>
          <p>
            Los datos de identificación que apareceran en la factura se sacan de los datos de facturación de la ficha del alumno.
          </p>
          <p>
            El concepto que aparecerá en la factura se extraerá automáticamente del pago al que esté asociado
          </p>
          <p>
            Asegúrate de que los datos son correctos antes de generar la factura.
          </p>
        </Alert>
      </DialogContent>
      <DialogActions>
        <Button color='error' onClick={onClose}>
          Cancelar
        </Button>
        <Button
          color='primary'
          disabled={isSubmitDisabled}
          onClick={onSubmit}
        >
          Emitir factura
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ModalNuevaFactura
