import { useState } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material'
import MesRecienteSelector from '../../components/form/MesRecienteSelector/MesRecienteSelector'
import { isBlank } from '../../utils/string'
import css from './ModalClonarTarifa.module.css'

const ModalClonarTarifa = ({
  open,
  onClose,
  onSubmit,
})=> {

  const [mesTarifa, setMesTarifa] = useState('')

  const isSubmitDisabled = isBlank(mesTarifa)

  return (
    <Dialog onClose={onClose} open={open} fullWidth maxWidth='sm'>
      <DialogTitle>Copiar Tarifa</DialogTitle>
      <DialogContent>
        <Typography className={css.intro} variant='body1'>
          Se procederá a replicar la información (curso, centro de estudios,
          objetivos) de la última tarifa contratada por el alumno en el mes
          que se seleccione a continuación.
        </Typography>
        <Typography className={css.intro} variant='body1'>
          Se transferirán los códigos promocionales correspondientes, y se intentarán 
          replicar las asistencias de forma automática. Es posible que algunas asistencias 
          no se copien adecuadamente por no ajustarse a un patrón claro, por lo que es 
          conveniente revisar que la copia se ha realizado correctamente.
        </Typography>
        <div className={css.form}>
          <MesRecienteSelector
            className={css.input}
            name='mes_tarifa'
            label='Mes de la tarifa'
            value={mesTarifa}
            onChange={(e)=> setMesTarifa(e.target.value)}
            forwardOptions={3}
            backwardOptions={6}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button color='error' onClick={onClose}>
          Cancelar
        </Button>
        <Button
          color='primary'
          disabled={isSubmitDisabled}
          onClick={() => onSubmit(mesTarifa)}
        >
          Aceptar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ModalClonarTarifa
