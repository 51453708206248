import { useState } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material'
import MesRecienteSelector from '../../components/form/MesRecienteSelector/MesRecienteSelector'
import { isAnyBlank } from '../../utils/string'
import css from './ModalNuevoCertificado.module.css'

const ModalNuevoCertificado = ({
  open,
  onClose,
  onSubmit,
})=> {

  const [certificado, setCertificado] = useState({
    concepto: '',
    fecha_comienzo: '',
    fecha_fin: '',
    nombre_pagador: '',
    identificacion_pagador: '',
  })

  const handleChange = event => {
    setCertificado(state => ({
      ...state,
      [event.target.name]: event.target.value
    }))
  }

  const isSubmitDisabled = isAnyBlank(
    certificado.concepto,
    certificado.fecha_comienzo,
    certificado.fecha_fin,
    certificado.nombre_pagador,
    certificado.identificacion_pagador
  )

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth='md'>
      <DialogTitle>Crear nuevo certificado</DialogTitle>
      <DialogContent className={css.content}>
        <DialogContentText className={css.intro}>
          Se generará un certificado de asistencia para el alumno con los datos especificados
        </DialogContentText>
        <TextField
          className={css.input}
          id='concepto'
          name='concepto'
          label='Concepto'
          variant='standard'
          margin='dense'
          value={certificado.concepto}
          onChange={handleChange}
          fullWidth
        />
        <MesRecienteSelector
          className={css.input}
          name='fecha_comienzo'
          label='Fecha inicio'
          value={certificado.fecha_comienzo}
          backwardOptions={28}
          onChange={handleChange}
        />
        <MesRecienteSelector
          className={css.input}
          name='fecha_fin'
          label='Fecha fin'
          backwardOptions={36}
          value={certificado.fecha_fin}
          onChange={handleChange}
        />
        <TextField
          className={css.input}
          id='nombre_pagador'
          name='nombre_pagador'
          label='Nombre pagador'
          variant='standard'
          margin='dense'
          value={certificado.nombre_pagador}
          onChange={handleChange}
          fullWidth
        />
        <TextField
          className={css.input}
          id='identificacion_pagador'
          name='identificacion_pagador'
          label='Identificación pagador (indicar tipo de documento)'
          variant='standard'
          margin='dense'
          value={certificado.identificacion_pagador}
          onChange={handleChange}
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button color='error' onClick={onClose}>
          Cancelar
        </Button>
        <Button
          color='primary'
          onClick={() => onSubmit(certificado)}
          disabled={isSubmitDisabled}
        >
          Enviar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ModalNuevoCertificado
