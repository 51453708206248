import { GET } from "../request"

export const getHorario = ({ fecha })=> GET('/api/agora/planificacion/modelo-horario', { fecha })

export const getHorarios = ({ fecha_inicio, fecha_fin, centro })=> GET('/api/agora/planificacion/modelo-horario/list/dates', { fecha_inicio, fecha_fin, centro })

export const getHorarioCentro = ({ centro, fecha })=> GET('/api/agora/planificacion/modelo-horario', { fecha, centro })

export const getHorariosTarifa = ({ tarifa, centro })=> GET('/api/agora/planificacion/modelo-horario/list/tarifa-alumno', { tarifa, centro })

export const getHorariosPack = ({ pack_horas, centro, mes })=> GET('/api/agora/planificacion/modelo-horario/list/pack-horas-alumno', { pack_horas, centro, mes })