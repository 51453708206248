import { IconButton, Menu, MenuItem } from "@mui/material"
import { useRef, useState } from "react"

const Dropdown = ({ id='dropdown', options=[], onOptionClick }) => {

  const [anchorEl, setAnchorEl] = useState(null)
  const buttonRef = useRef(null)

  const handleOpen = () => setAnchorEl(buttonRef.current)
  const handleClose = () => setAnchorEl(null)

  const handleOptionClick = (option) => {
    onOptionClick(option)
    handleClose()
  }

  const open = Boolean(anchorEl)

  return (
    <>
      <IconButton
        ref={buttonRef}
        id={id}
        aria-label="opciones"
        aria-controls={open ? `menu-${id}` : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleOpen}
      >
        <i className='material-icons'>more_vert</i>
      </IconButton>
      <Menu
        id={`menu-${id}`}
        MenuListProps={{ 'aria-labelledby': {id}, }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {options.map((option) => (
          <MenuItem key={option} onClick={()=> handleOptionClick(option)}>
            {option}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

export default Dropdown