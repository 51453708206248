import { Alert, Button, Card, CardActions, CardContent, CardHeader } from "@mui/material"
import { useState } from "react"
import CurrencyInput from "../../../../components/form/CurrencyInput/CurrencyInput"
import css from './AbrirCaja.module.css'

const AbrirCaja = ({ onOpen })=> {

  const [importe, setImporte] = useState(0)

  return (
    <Card elevation={4} className={css.abrir}>
      <CardHeader title='Abrir caja' />
      <CardContent className={css.content}>
        <Alert className={css.info} severity='warning'>
          Es necesario abrir la caja para poder realizar operaciones
        </Alert>
        <CurrencyInput
          className={css.input}
          name='importe'
          label='Balance inicial'
          value={importe}
          onChange={value=> setImporte(value)}
        />
      </CardContent>
      <CardActions className={css.actions}>
        <Button
          disabled={importe <= 0}
          color='primary'
          variant='contained'
          onClick={()=> onOpen(importe)}
        >
          Abrir caja
        </Button>
      </CardActions>
    </Card>
  )
}

export default AbrirCaja