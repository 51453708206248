
// Datos extraidos desde 2019 hasta 2023 de asignaturas que se contratan juntas
export const getRecomendaciones = asignatura_id=> {
  const recomendaciones = {
    '55189779e4b0a479cf2ab228': [ '55185e66355d8e4dbb81c0de', '55185e66355d8e4dbb81c116', '55185e66355d8e4dbb81c120' ],
    '55185e66355d8e4dbb81c130': [ '55189779e4b0a479cf2ab228', '55185e66355d8e4dbb81c116', '55185e66355d8e4dbb81c120' ],
    '55185e66355d8e4dbb81c120': [ '55189779e4b0a479cf2ab228', '55185e66355d8e4dbb81c0de', '55185e66355d8e4dbb81c116' ],
    '55185e66355d8e4dbb81c10a': [ '55185e66355d8e4dbb81c0e6', '55185e66355d8e4dbb81c0de', '55189779e4b0a479cf2ab228' ],
    '55185e66355d8e4dbb81c0de': [ '55189779e4b0a479cf2ab228', '55185e66355d8e4dbb81c0e2', '55185e66355d8e4dbb81c0e6' ],
    '55185e66355d8e4dbb81c0e6': [ '55185e66355d8e4dbb81c0de', '55185e66355d8e4dbb81c0e2', '55185e66355d8e4dbb81c10a' ],
    '55185e66355d8e4dbb81c0df': [ '55185e66355d8e4dbb81c0e3', '55185e66355d8e4dbb81c0de', '55185e66355d8e4dbb81c0e7' ],
    '55185e66355d8e4dbb81c106': [ '55185e66355d8e4dbb81c0de', '55189779e4b0a479cf2ab228', '55185e66355d8e4dbb81c116' ],
    '55185e66355d8e4dbb81c116': [ '55185e66355d8e4dbb81c0de', '55189779e4b0a479cf2ab228', '55185e66355d8e4dbb81c120' ],
    '55185e66355d8e4dbb81c0e2': [ '55185e66355d8e4dbb81c0de', '55185e66355d8e4dbb81c0e6', '55189779e4b0a479cf2ab228' ],
    '55185e66355d8e4dbb81c0ea': [ '55185e66355d8e4dbb81c0de', '55185e66355d8e4dbb81c0e2', '55189779e4b0a479cf2ab228' ],
    '55185e66355d8e4dbb81c111': [ '55185e66355d8e4dbb81c0dd', '551897d8e4b0a479cf2ab22a', '55185e66355d8e4dbb81c115' ],
    '55185e66355d8e4dbb81c115': [ '55185e66355d8e4dbb81c0dd', '55185e66355d8e4dbb81c111', '551897d8e4b0a479cf2ab22a' ],
    '55185e66355d8e4dbb81c0dd': [ '55185e66355d8e4dbb81c111', '551897d8e4b0a479cf2ab22a', '55185e66355d8e4dbb81c115' ],
    '5b1b026c77c8c90fadbe4c33': [ '55185e66355d8e4dbb81c0dd', '55185e66355d8e4dbb81c111', '55185e66355d8e4dbb81c0de' ],
    '55185e66355d8e4dbb81c0f7': [ '55185e66355d8e4dbb81c0f3', '55185e66355d8e4dbb81c0e3', '55185e66355d8e4dbb81c0df' ],
    '5b4e675b971923d9ce1e0a7e': [ '55185e66355d8e4dbb81c0df', '55185e66355d8e4dbb81c0f7' ],
    '55185e66355d8e4dbb81c0f3': [ '55185e66355d8e4dbb81c0f7', '55185e66355d8e4dbb81c0df', '55185e66355d8e4dbb81c0e3' ],
    '55185e66355d8e4dbb81c0e3': [ '55185e66355d8e4dbb81c0df', '55185e66355d8e4dbb81c0e7', '55185e66355d8e4dbb81c0f3' ],
    '5518984ce4b0a479cf2ab22b': [ '55185e66355d8e4dbb81c0de', '55189779e4b0a479cf2ab228', '55185e66355d8e4dbb81c0e6' ],
    '551897d8e4b0a479cf2ab22a': [ '55185e66355d8e4dbb81c0dd', '55185e66355d8e4dbb81c111', '55185e66355d8e4dbb81c0de' ],
    '55185e66355d8e4dbb81c110': [ '55185e66355d8e4dbb81c0dc', '55185e66355d8e4dbb81c114', '5b1b026c77c8c90fadbe4c33' ],
    '55185e66355d8e4dbb81c0dc': [ '55185e66355d8e4dbb81c110', '55185e66355d8e4dbb81c114', '55185e66355d8e4dbb81c0dd' ],
    '55189867e4b0a479cf2ab22c': [ '55185e66355d8e4dbb81c0de', '55189779e4b0a479cf2ab228', '55185e66355d8e4dbb81c106' ],
    '562eb26ee4b08766c03b8e5f': [ '55185e66355d8e4dbb81c111', '55185e66355d8e4dbb81c0dd', '55185e66355d8e4dbb81c115' ],
    '55185e66355d8e4dbb81c143': [ '55185e66355d8e4dbb81c0dd', '55185e66355d8e4dbb81c111', '551897d8e4b0a479cf2ab22a' ],
    '55185e66355d8e4dbb81c109': [ '55185e66355d8e4dbb81c0dd', '55185e66355d8e4dbb81c111', '551897d8e4b0a479cf2ab22a' ],
    '5b4fbec1971923e56ce18e3f': [ '55185e66355d8e4dbb81c0e3', '55185e66355d8e4dbb81c0f3', '5b4408ed9719232f5ad1e509' ],
    '55185e66355d8e4dbb81c0fb': [ '55185e66355d8e4dbb81c0df', '55185e66355d8e4dbb81c0e3', '55185e66355d8e4dbb81c0de' ],
    '55185e66355d8e4dbb81c114': [ '55185e66355d8e4dbb81c0dc', '55185e66355d8e4dbb81c110', '55185e66355d8e4dbb81c0dd' ],
    '55185e66355d8e4dbb81c12c': [ '55185e66355d8e4dbb81c0de', '55185e66355d8e4dbb81c106', '55189779e4b0a479cf2ab228' ],
    '551898c1e4b0a479cf2ab22f': [ '55185e66355d8e4dbb81c111', '55185e66355d8e4dbb81c0dd', '55185e66355d8e4dbb81c115' ],
    '55185e66355d8e4dbb81c0e7': [ '55185e66355d8e4dbb81c0e3', '55185e66355d8e4dbb81c0df', '55185e66355d8e4dbb81c0e6' ],
    '569695d1e4b0ac4d415d4bf9': [ '55185e66355d8e4dbb81c0e7' ],
    '55185e66355d8e4dbb81c128': [ '55189779e4b0a479cf2ab228', '55185e66355d8e4dbb81c0de', '55185e66355d8e4dbb81c106' ],
    '55185e66355d8e4dbb81c124': [ '55189779e4b0a479cf2ab228', '55185e66355d8e4dbb81c116', '55185e66355d8e4dbb81c0de' ],
    '5c35223a36d1859b0a149a60': [ '55185e66355d8e4dbb81c0dc', '55185e66355d8e4dbb81c110', '562eb25ee4b08766c03b8e5e' ],
    '56a12301e4b02cc75ffbc8a6': [ '5c5b3c3ab5d56f8e2397cd17', '5518984ce4b0a479cf2ab22b', '55185e66355d8e4dbb81c0dd' ],
    '5c5b3bf3b5d56f8e2397cd15': [ '56a12301e4b02cc75ffbc8a6', '5c5b3c3ab5d56f8e2397cd17' ],
    '5c5b3c3ab5d56f8e2397cd17': [ '56a12301e4b02cc75ffbc8a6', '5c5db736b5d56fa0799e6b73', '5c5b3bf3b5d56f8e2397cd15' ],
    '55185e66355d8e4dbb81c0ef': [ '55185e66355d8e4dbb81c0fb', '5814855c1b4891023f63a101', '55185e66355d8e4dbb81c0df' ],
    '55185e66355d8e4dbb81c107': [ '55185e66355d8e4dbb81c0fb', '55185e66355d8e4dbb81c0df', '55185e66355d8e4dbb81c0ef' ],
    '56607899e4b0d92c0ffbed30': [ '57b6daf91a3bc3cbeddb27c5', '55185e66355d8e4dbb81c124', '55189779e4b0a479cf2ab228' ],
    '55185e66355d8e4dbb81c118': [ '55185e66355d8e4dbb81c0dc' ],
    '56d57039d4c69bdeb1be53c1': [ '55185e66355d8e4dbb81c10b', '55185e66355d8e4dbb81c0e7', '57613f8c923842042936c847' ],
    '55185e66355d8e4dbb81c10b': [ '56d57039d4c69bdeb1be53c1', '55185e66355d8e4dbb81c0e7', '5b1b026c77c8c90fadbe4c33' ],
    '5b577f009719230925aefe5f': [ '5b60c4f8b3123f063c296042', '55185e66355d8e4dbb81c10b', '562eb2a8e4b08766c03b8e60' ],
    '5b60c4f8b3123f063c296042': [ '5b577f009719230925aefe5f', '55185e66355d8e4dbb81c0fb' ],
    '5b4409609719232f5ad1e50d': [ '55185e66355d8e4dbb81c0df', '55185e66355d8e4dbb81c10b', '5b1b026c77c8c90fadbe4c33' ],
    '55185e66355d8e4dbb81c11a': [ '5690d2a4e4b0a5ab646ab652', '55185e66355d8e4dbb81c116', '55189779e4b0a479cf2ab228' ],
    '5c5db930b5d56fa0799e6b79': [ '55185e66355d8e4dbb81c0ef', '55185e66355d8e4dbb81c107', '55185e66355d8e4dbb81c0de' ],
    '57613f8c923842042936c847': [ '56d57039d4c69bdeb1be53c1' ],
    '55185e66355d8e4dbb81c11b': [ '5690d2a4e4b0a5ab646ab652', '55185e66355d8e4dbb81c116', '55185e66355d8e4dbb81c0de' ],
    '5690d2a4e4b0a5ab646ab652': [ '55185e66355d8e4dbb81c11b', '55185e66355d8e4dbb81c11c', '55185e66355d8e4dbb81c11a' ],
    '55185e66355d8e4dbb81c11c': [ '5690d2a4e4b0a5ab646ab652', '55185e66355d8e4dbb81c0dd', '55185e66355d8e4dbb81c0de' ],
    '57fb72f81ae5dd01bdc28acd': [ '55185e66355d8e4dbb81c0df', '55185e66355d8e4dbb81c0e3', '55185e66355d8e4dbb81c0f3' ],
    '5c5db879b5d56fa0799e6b75': [ '55185e66355d8e4dbb81c0de', '55185e66355d8e4dbb81c0e6', '55189779e4b0a479cf2ab228' ],
    '562eb25ee4b08766c03b8e5e': [ '55185e66355d8e4dbb81c0dc', '55185e66355d8e4dbb81c110', '55185e66355d8e4dbb81c114' ],
    '55185e66355d8e4dbb81c0eb': [ '55185e66355d8e4dbb81c0e3', '55185e66355d8e4dbb81c0df', '55185e66355d8e4dbb81c0ea' ],
    '562eb2a8e4b08766c03b8e60': [ '55185e66355d8e4dbb81c0df', '55185e66355d8e4dbb81c0e3', '57fb72f81ae5dd01bdc28acd' ],
    '56966ad3e4b0ac4d415d4b02': [ '55185e66355d8e4dbb81c0de', '55185e66355d8e4dbb81c131', '55185e66355d8e4dbb81c0df' ],
    '55185e66355d8e4dbb81c162': [ '55185e66355d8e4dbb81c110', '55185e66355d8e4dbb81c0dc', '55185e66355d8e4dbb81c114' ],
    '55189799e4b0a479cf2ab229': [ '55185e66355d8e4dbb81c162', '55185e66355d8e4dbb81c114' ],
    '57b6daf91a3bc3cbeddb27c5': [ '55189779e4b0a479cf2ab228', '55185e66355d8e4dbb81c124', '55185e66355d8e4dbb81c120' ],
    '57345f5ed4c647d2378d09bd': [ '57345f7dd4c647d2378d09c0', '55185e66355d8e4dbb81c0dd' ],
    '57345f7dd4c647d2378d09c0': [ '57345f5ed4c647d2378d09bd', '55185e66355d8e4dbb81c0dd' ],
    '5b4fbef2971923e56ce18e41': [ '5646f9fae4b025ad6bf0a8c9' ],
    '5646f9fae4b025ad6bf0a8c9': [ '5b4fbef2971923e56ce18e41' ],
    '55185e66355d8e4dbb81c152': [ '55189779e4b0a479cf2ab228', '55185e66355d8e4dbb81c130' ],
    '56eb03f5d4c6f6842c9aee44': [ '55185e66355d8e4dbb81c0e3', '55185e66355d8e4dbb81c0df', '5860e324cee6ce02392c0e38' ],
    '5860e324cee6ce02392c0e38': [ '56eb03f5d4c6f6842c9aee44', '55185e66355d8e4dbb81c0f3', '55185e66355d8e4dbb81c0e3' ],
    '55185e66355d8e4dbb81c101': [ '55185e66355d8e4dbb81c0dd', '551897d8e4b0a479cf2ab22a', '55185e66355d8e4dbb81c115' ],
    '5540f177e4b0daef599f884b': [ '55189779e4b0a479cf2ab228', '55185e66355d8e4dbb81c0de', '55185e66355d8e4dbb81c130' ],
    '567195b9e4b0bd7e40119685': [ '55185e66355d8e4dbb81c0df', '55185e66355d8e4dbb81c0fb', '5787cb49476dc703313ce5cb' ],
    '5787cb49476dc703313ce5cb': [ '55185e66355d8e4dbb81c0df', '567195b9e4b0bd7e40119685', '5690d2a4e4b0a5ab646ab652' ],
    '57441e9ed4c662cb611426c3': [ '55185e66355d8e4dbb81c0de', '55185e66355d8e4dbb81c0e6' ],
    '5612514de4b0c4fe02bf6e7e': [ '55185e66355d8e4dbb81c162', '55185e66355d8e4dbb81c111', '55185e66355d8e4dbb81c0dd' ],
    '55185e66355d8e4dbb81c131': [ '56966ad3e4b0ac4d415d4b02', '55185e66355d8e4dbb81c0df', '55185e66355d8e4dbb81c13b' ],
    '55185e66355d8e4dbb81c13a': [ '55185e66355d8e4dbb81c130', '55189779e4b0a479cf2ab228', '55185e66355d8e4dbb81c116' ],
    '55185e66355d8e4dbb81c156': [ '55185e66355d8e4dbb81c0e6', '55185e66355d8e4dbb81c0de', '55189779e4b0a479cf2ab228' ],
    '55185e66355d8e4dbb81c102': [ '55189779e4b0a479cf2ab228', '55185e66355d8e4dbb81c0de', '55185e66355d8e4dbb81c0e2' ],
    '56de065dd4c6d2187e726d2b': [ '55185e66355d8e4dbb81c0f3' ],
    '56efca97d4c6697b310386bc': [ '55185e66355d8e4dbb81c111', '55185e66355d8e4dbb81c0dd', '562eb26ee4b08766c03b8e5f' ],
    '55185e66355d8e4dbb81c0fa': [ '55185e66355d8e4dbb81c0de', '55185e66355d8e4dbb81c0fb', '55185e66355d8e4dbb81c10a' ],
    '55185e66355d8e4dbb81c13b': [ '55185e66355d8e4dbb81c0df', '55185e66355d8e4dbb81c131', '55185e66355d8e4dbb81c13a' ],
    '5f2079e84cea348589497f2b': [ '55185e66355d8e4dbb81c0e3', '55185e66355d8e4dbb81c0f7', '56be2a2dd4c695ad3d70e5be' ],
    '55185e66355d8e4dbb81c0e9': [ '55185e66355d8e4dbb81c115', '55185e66355d8e4dbb81c111', '55185e66355d8e4dbb81c0dd' ],
    '5673d62de4b0f7a118287946': [ '55189779e4b0a479cf2ab228', '55185e66355d8e4dbb81c120' ],
    '55185e66355d8e4dbb81c155': [ '55185e66355d8e4dbb81c115', '55185e66355d8e4dbb81c156', '55185e66355d8e4dbb81c0dd' ],
    '58eb7231fd07d608eddc55ce': [ '55185e66355d8e4dbb81c0df', '55185e66355d8e4dbb81c0e3' ],
    '56be2a2dd4c695ad3d70e5be': [ '55185e66355d8e4dbb81c0e7', '5f2079e84cea348589497f2b', '56d57039d4c69bdeb1be53c1' ],
    '56af570dd4c64f66c5f75602': [ '5b1b026c77c8c90fadbe4c33', '55185e66355d8e4dbb81c0de', '55185e66355d8e4dbb81c106' ],
    '5c5db736b5d56fa0799e6b73': [ '5c5b3c3ab5d56f8e2397cd17' ],
    '57fb730c1ae5dd01bdc28acf': [ '55185e66355d8e4dbb81c0f7', '55185e66355d8e4dbb81c0e3' ],
    '5c13a40e77c8140fdcd558a3': [ '55185e66355d8e4dbb81c10b', '55185e66355d8e4dbb81c0e7', '55185e66355d8e4dbb81c0df' ],
    '5b4408ed9719232f5ad1e509': [ '5b4fbec1971923e56ce18e3f', '55185e66355d8e4dbb81c0f3', '55185e66355d8e4dbb81c0e3' ],
    '5bbdeb2e77c8f6e7d8f7109c': [ '55185e66355d8e4dbb81c0f3', '55185e66355d8e4dbb81c0de', '57fb72f81ae5dd01bdc28acd' ],
    '55185e66355d8e4dbb81c144': [ '55189779e4b0a479cf2ab228', '55185e66355d8e4dbb81c0de', '55185e66355d8e4dbb81c128' ],
    '5bbdeb9e77c8f6e7d8f710a3': [ '56a12301e4b02cc75ffbc8a6' ],
    '61277b7679216538783b84af': [ '56a12301e4b02cc75ffbc8a6', '5c5b3c3ab5d56f8e2397cd17', '55185e66355d8e4dbb81c11a' ],
    '6319a8a5cd9ceeac182c056e': [ '55185e66355d8e4dbb81c114', '55185e66355d8e4dbb81c0dc', '55185e66355d8e4dbb81c162' ],
    '56968b77e4b0ac4d415d4b8a': [ '55185e66355d8e4dbb81c107', '55185e66355d8e4dbb81c0f7' ],
    '55185e66355d8e4dbb81c119': [ '55185e66355d8e4dbb81c115', '551897d8e4b0a479cf2ab22a' ],
    '565dbc4ee4b0db4edc23b4bf': [ '55185e66355d8e4dbb81c0df', '55185e66355d8e4dbb81c0fb', '56b39e89d4c6e6172bd83f87' ],
    '5814855c1b4891023f63a101': [ '55185e66355d8e4dbb81c0ef', '55185e66355d8e4dbb81c0df' ],
    '6319a85ccd9ceeac182bfd78': [ '55185e66355d8e4dbb81c0dd', '55185e66355d8e4dbb81c111', '55185e66355d8e4dbb81c115' ],
    '5b510ca6971923efea717cb9': [ '55185e66355d8e4dbb81c107' ],
    '63088d0352031b2d8c00d15b': [ '55189779e4b0a479cf2ab228', '55185e66355d8e4dbb81c116' ],
    '56b39e89d4c6e6172bd83f87': [ '55185e66355d8e4dbb81c0df', '55185e66355d8e4dbb81c0f3', '5bbdeb2e77c8f6e7d8f7109c' ],
    '6345667efab9736610397f8b': [ '55185e66355d8e4dbb81c0dc', '55185e66355d8e4dbb81c114' ],
    '5646f9dce4b025ad6bf0a8c8': [ '55185e66355d8e4dbb81c0fb' ],
    '5785fba93741ef9b02516134': [ '551897d8e4b0a479cf2ab22a', '55185e66355d8e4dbb81c0dd' ]
  }

  return recomendaciones[asignatura_id] || []
}

export const getColor = (asignatura)=> {
  const firstLetter = asignatura.toLowerCase().charAt(0)
  if ('abc'.split('').includes(firstLetter)) {
    return 'primary-green'
  }
  if ('def'.split('').includes(firstLetter)) {
    return 'primary-green-light'
  }
  if ('ghi'.split('').includes(firstLetter)) {
    return 'primary-red'
  }
  if ('jkl'.split('').includes(firstLetter)) {
    return 'primary-orange'
  }
  if ('mno'.split('').includes(firstLetter)) {
    return 'secondary-green'
  }
  if ('pqr'.split('').includes(firstLetter)) {
    return 'secondary-red'
  }
  if ('stu'.split('').includes(firstLetter)) {
    return 'secondary-orange'
  }
  if ('vwx'.split('').includes(firstLetter)) {
    return 'secondary-turquoise'
  }
  else return 'secondary-yellow'
}