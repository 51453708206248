import { useEffect, useState } from "react"
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Link, TextField } from "@mui/material"
import { isAnyBlank } from "../../utils/string"
import FechaSelector from "../../components/form/FechaSelector/FechaSelector"
import { dateFromString, dateToDateTime, dateToString } from "../../utils/date"
import AcademiaSelector from "../../components/form/AcademiaSelector/AcademiaSelector"
import TipoEventoSelector from "../../components/form/TipoEventoSelector/TipoEventoSelector"
import css from './ModalEventoCalendario.module.css'

const EventoIcon = ({ evento })=> {
  if (!evento || !evento.tipo) return null
  return (
    <i className='material-icons'>
      {evento.tipo === 'AVISO' && 'info'}
      {evento.tipo === 'NOTIFICACION' && 'notifications'}
      {evento.tipo === 'RECORDATORIO' && 'alarm'}
      {evento.tipo === 'ALERTA' && 'warning'}
    </i>
  )
}

const ModalEventoCalendario = ({ open, evento, onSubmit, onDelete, onClose }) => {

  const [data, setData] = useState({
    titulo: '',
    descripcion: '',
    fecha: null,
    centros: [],
    tipo: '',
  })

  useEffect(()=> {
    if (!open || !evento) {
      setData({
        titulo: '',
        descripcion: '',
        fecha: null,
        centros: [],
        tipo: '',
      })
      return
    }
    else {
      setData({
        titulo: evento.titulo,
        descripcion: evento.descripcion,
        fecha: dateToDateTime(dateFromString(evento.fecha)),
        centros: evento.centros,
        tipo: evento.tipo,
      })
    }
  }, [open, evento])

  const isSubmitDisabled = isAnyBlank(data.fecha, data.titulo, data.tipo, data.centros, data.descripcion)

  const handleSubmit = () => {
    onSubmit({
      id: evento.id,
      titulo: data.titulo,
      descripcion: data.descripcion,
      fecha: dateToString(data.fecha),
      centros: data.centros,
      tipo: data.tipo,
    })
  }

  return (
    <Dialog onClose={onClose} open={open} fullWidth maxWidth='md'>
      <DialogTitle className={css.title}>
        <EventoIcon evento={evento} />
        {evento?.titulo}
      </DialogTitle>
      <DialogContent className={css.main}>
        <DialogContentText className={css.intro}>
          Evento creado por <strong>{evento?.creador}</strong>.{' '}
          <Link className={css.deleteLink} color='error' component="button" variant="body2" onClick={onDelete}>
            Borrar evento
          </Link>
        </DialogContentText>
        <TextField
          className={css.input}
          value={data.titulo}
          label="Título"
          onChange={e=> setData({ ...data, titulo: e.target.value })}
          variant='standard'
        />
        <TipoEventoSelector
          className={css.input}
          name='tipo'
          label='Tipo de evento'
          value={data.tipo}
          onChange={e=> setData({ ...data, tipo: e.target.value })}
        />
        <FechaSelector
          className={css.input}
          label='Fecha'
          value={data.fecha}
          onChange={fecha=> setData({ ...data, fecha })}
        />
        <AcademiaSelector
          multiple
          className={css.input}
          name='academia'
          label='Academias'
          value={data.centros}
          onChange={e=> setData({ ...data, centros: e.target.value })}
        />
        <TextField
          className={css.input}
          value={data.descripcion}
          label="Descripción"
          multiline
          onChange={e=> setData({ ...data, descripcion: e.target.value })}
          variant='standard'
        />
      </DialogContent>
      <DialogActions>
        <Button 
          color='error' 
          onClick={onClose}
        >
          Cerrar
        </Button>
        <Button 
          disabled={isSubmitDisabled} 
          color='primary' 
          onClick={handleSubmit}
        >
          Actualizar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ModalEventoCalendario