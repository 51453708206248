import { Fab, IconButton } from '@mui/material'
import { useState } from 'react'
import PageLoading from '../../components/PageLoading/PageLoading'
import InformationTable from '../../components/Table/InformationTable'
import TableHeader from '../../components/TableHeader/TableHeader'
import ModalNuevoHelpdesk from '../../modals/ModalNuevoHelpdesk/ModalNuevoHelpdesk'
import { dateSort } from '../../utils/table'
import { createHelpdesk, getHelpdesk } from '../../utils/api/helpdesk'
import { useNotification } from '../../context/NotificationManager/NotificationManager'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { dateToString } from '../../utils/date'
import { downloadCSVFile, getCSVContent } from '../../utils/csv'
import css from './Helpdesk.module.css'

const ContenidoHelpdesk = ({ title, content }) => (
  <>
    <strong className={css.title}>{title}</strong>
    <p className={css.content}>{content}</p>
  </>
)

const Helpdesk = () => {
  const notification = useNotification()
  const queryClient = useQueryClient()

  const [isModalOpen, setModalOpen] = useState(false)

  const { isLoading, data: helpdeskList = [] } = useQuery({
    queryKey: ['helpdesk', 'list'],
    queryFn: () => getHelpdesk()
      .then(datos => datos)
      .catch(err => {
        notification.error({ title: 'Error al recuperar los helpdesk', content: err })
        return []
      })
  })

  const { isPending: isHeldpeskCreating, mutate: crearHelpdesk } = useMutation({
    mutationFn: createHelpdesk,
    onSuccess: () => {
      notification.success({ title: 'Helpdesk enviado', content: 'Se ha enviado el helpdesk' })
      queryClient.invalidateQueries({
        queryKey: ['helpdesk', 'list']
      })
      setModalOpen(false)
    },
    onError: err => {
      notification.error({ title: 'Error al crear el helpdesk', content: err })
    }
  })

  const handleExport = () => {
    const content = getCSVContent({
      columns: ['Fecha', 'Título', 'Descripcion'],
      data: helpdeskList,
      transform: h => [dateToString(h.fecha), h.titulo, h.descripcion]
    })
    downloadCSVFile(content, 'helpdesk')
  }

  const handleNuevoHelpdesk = ({ titulo, categoria, descripcion }) => {
    if (isHeldpeskCreating) return
    crearHelpdesk({ titulo, categoria, descripcion })
  }

  return (
    <PageLoading isLoading={isLoading}>
      <TableHeader
        actions={
          <IconButton size='small' color='primary' onClick={handleExport}>
            <i className='material-icons'>download</i>
          </IconButton>
        }
        showSearch={false}
        title='Helpdesk pendientes'
      />
      <InformationTable
        details={[
          { title: 'Contenido', key: 'contenido', sortDisabled: true },
          { title: 'Fecha', key: 'fecha', sortFunction: dateSort }
        ]}
        data={helpdeskList.map(helpdesk => ({
          className: helpdesk.resuelto ? css.resuelto : '',
          fecha: dateToString(helpdesk.fecha),
          contenido: (
            <ContenidoHelpdesk
              title={helpdesk.titulo}
              content={helpdesk.descripcion}
            />
          )
        }))}
      />
      <Fab
        className={css.nuevo}
        disabled={isHeldpeskCreating}
        onClick={() => setModalOpen(true)}
        size='medium'
        color='primary'
        aria-label='add'
      >
        <i className='material-icons'>add</i>
      </Fab>
      <ModalNuevoHelpdesk
        open={isModalOpen}
        onSubmit={handleNuevoHelpdesk}
        onClose={() => setModalOpen(false)}
      />
    </PageLoading>
  )
}

export default Helpdesk
