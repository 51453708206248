import { FormControl, InputLabel, MenuItem, Select } from "@mui/material"
import { useQuery } from "@tanstack/react-query"
import { useNotification } from "../../../context/NotificationManager/NotificationManager"
import { getPacksActivos } from "../../../utils/api/contrataciones"
import css from './PackHorasSelector.module.css'

const PackHorasSelector = ({ 
  className='', 
  name, 
  label, 
  value, 
  onChange 
})=> {

  const notification = useNotification()

  const { data: packList=[] } = useQuery({
    queryKey: ['datos', 'pack-horas', 'list', 'active'], 
    queryFn: ()=> getPacksActivos()
      .then(datos=> datos.sort((p1, p2)=> p1.nombre.localeCompare(p2.nombre)))
      .catch(err=> {
        notification.error({ title: 'Error al recuperar los packs', content: err })
        return []
      })
  })

  return (
    <FormControl className={`${className} ${css.main}`} variant="standard">
      <InputLabel id={name}>{label}</InputLabel>
      <Select
        labelId={name}
        id={name}
        value={value}
        onChange={onChange}
        label={label}
      >
        {packList.map(pack=> <MenuItem key={pack.nombre} value={pack}>{pack.nombre}</MenuItem> )}
      </Select>
    </FormControl>
  )

}

export default PackHorasSelector

