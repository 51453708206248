import { MobileTimePicker } from '@mui/x-date-pickers'
import css from './HoraSelector.module.css'

const HoraSelector = ({ 
  className='', 
  label, 
  value, 
  onChange 
})=> (
  <div className={`${css.wrapper} ${className}`}>
    <MobileTimePicker
      ampm={false}
      label={label}
      value={value}
      closeOnSelect
      onChange={onChange}
      slotProps={{ textField: { variant: 'standard' }}}
    />
  </div>
)

export default HoraSelector