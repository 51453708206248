import { IconButton } from '@mui/material'
import InformationTable from '../../../../components/Table/InformationTable'
import { useQuery } from '@tanstack/react-query'
import { useNotification } from '../../../../context/NotificationManager/NotificationManager'
import { downloadCSVFile, getCSVContent } from '../../../../utils/csv'
import TableHeader from '../../../../components/TableHeader/TableHeader'
import PageLoading from '../../../../components/PageLoading/PageLoading'
import { dateFromString, dateToString, dateToWeekday } from '../../../../utils/date'
import { getCajaList } from '../../../../utils/api/caja'
import { format, formatForExport } from '../../../../utils/math'
import css from './Cajas.module.css'

const Abierta = ()=> (
  <span className={css.abierta}>Abierta</span>
)

const Cajas = ({ mes })=> {

  const notification = useNotification()

  const { isLoading, data: cajaList=[] } = useQuery({
    queryKey: ['contabilidad', 'cajas', 'list', mes], 
    queryFn: ()=> getCajaList(mes)
      .then(datos=> datos.map(caja=> ({
        ...caja,
        fecha: dateToString(caja.fecha),
        descuadre: caja.balanceFinalReal - caja.balanceFinalTeorico
      })))
      .catch(err=> {
        notification.error({ title: 'Error al recuperar las cajas', content: err })
        return []
      })
  })

  const handleExport = ()=> {
    const content = getCSVContent({
      columns: [
        'Fecha',
        'Dia',
        'Hora Apertura',
        'Hora Cierre',
        'Estado',
        'Balance Inicial',
        'Balance Final (Teórico)',
        'Balance Final (Real)',
        'Descuadre',
        'Reponsable apertura',
        'Responsable cierre',
        'Observaciones'
      ],
      data: cajaList,
      transform: c=> ([
        c.fecha,
        dateToWeekday(dateFromString(c.fecha)),
        c.horaApertura,
        c.horaCierre,
        c.estado,
        formatForExport(c.balanceInicial),
        formatForExport(c.balanceFinalTeorico),
        formatForExport(c.balanceFinalReal),
        formatForExport(c.descuadre),
        c.abiertoPor,
        c.cerradoPor,
        c.observaciones
      ])
    })
    downloadCSVFile(content, `cajas-${mes}`)
  }

  return (
    <PageLoading isLoading={isLoading}>
      <TableHeader
        actions={(
          <div className={css.actions}>
            <IconButton className={css.download} size='small' color='primary' onClick={handleExport}>
              <i className='material-icons'>download</i>
            </IconButton>
          </div>
        )}
        title={`Cierres de caja`}
        showSearch={false}
      />
      <InformationTable
        details={[
          { title: 'Fecha', key: 'fecha', sortDisabled: true },
          { title: 'Funcionamiento', key: 'funcionamiento', sortDisabled: true },
          { title: 'Cierre Teórico', key: 'cierreTeorico', align: 'right', sortDisabled: true },
          { title: 'Cierre Real', key: 'cierreReal', align: 'right', sortDisabled: true },
          { title: 'Descuadre', key: 'descuadre', align: 'right', sortDisabled: true },
          { title: 'Observaciones', key: 'observaciones', align: 'right', sortDisabled: true },
        ]}
        data={cajaList.map((caja) => ({
          fecha: `${caja.fecha} (${dateToWeekday(dateFromString(caja.fecha))})`,
          funcionamiento: (
            <>
              {caja.horaApertura} - {caja.horaCierre || <Abierta />}
            </>
          ),
          cierreTeorico: `${format(caja.balanceFinalTeorico)} €`,
          cierreReal: `${format(caja.balanceFinalReal)} €`,
          descuadre: caja.descuadre !== 0 ? (
            <span className={css.descuadre} data-tipo={caja.descuadre > 0 ? 'negativo' : 'positivo'}>
              {format(caja.descuadre)} €
            </span>
          ) : '--',
          observaciones: caja.observaciones,
        }))}
        isFetching={isLoading}
      />
    </PageLoading>
  )
}

export default Cajas
