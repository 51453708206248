import { InputAdornment, TextField } from "@mui/material"
import css from './CurrencyInput.module.css'

const CurrencyInput = ({ 
  className='', 
  name, 
  label, 
  value, 
  onChange 
})=> {

  const handleChange = (e) => {
    const { value } = e.target
    const newValue = value
      .replace(",", ".")
      .replace(/[^0-9.]/g, '')
    onChange(newValue)
  }

  return (
    <TextField
      className={`${className} ${css.main}`}
      id={name}
      value={value}
      onChange={handleChange}
      InputProps={{
        startAdornment: <InputAdornment position="start">€</InputAdornment>,
      }}
      label={label}
      variant="standard"
      fullWidth
    />
  )
}

export default CurrencyInput