import AlumnoHeader from '../../../components/AlumnoHeader/AlumnoHeader'
import Tabs from '../../../components/Tabs/Tabs'
import Contrataciones from '../Historico/Contrataciones/Contrataciones'
import Ingresos from './Ingresos/Ingresos'
import Certificados from './Certificados/Certificados'

const Historico = () => {

  return (
    <>
      <AlumnoHeader label='Histórico' />
      <Tabs
        tabs={[
          { name: 'Contrataciones', content: <Contrataciones /> },
          { name: 'Ingresos', content: <Ingresos /> },
          { name: 'Certificados', content: <Certificados /> }
        ]}
      />
    </>
  )
}

export default Historico
