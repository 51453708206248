import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Typography } from "@mui/material"
import { getAuth } from "../../utils/auth"
import { useEffect, useState } from "react"
import { dateToString, dateToWeekday, forwardMonths } from "../../utils/date"
import { getHorarios } from "../../utils/api/modelosHorario"
import { useQuery } from "@tanstack/react-query"
import { useNotification } from "../../context/NotificationManager/NotificationManager"
import ProfesorSelector from "../../components/form/ProfesorSelector/ProfesorSelector"
import css from './ModalPlanificarClasesProfesor.module.css'
import { pluralize } from "../../utils/string"

const HoraCheckbox = ({ fecha, hora, horas, onChange })=> (
  <FormControlLabel 
    control={(
      <Checkbox 
        checked={horas.some(h=> h.fecha === fecha && h.hora === hora)} 
        onChange={(e)=> onChange({ fecha, hora, checked: e.target.checked })} 
      />
    )} 
    label={hora}
  />
)

const ConfigurarHorario = ({ horas, horarios, onDaySelect, onChange })=> (
  <div className={css.horario}>
    {horarios.map(horario=> (
      <div key={horario.id} className={css.dia}>
        <Typography variant='body1' className={css.diaTitle}>
          {horario.fecha} ({horario.diaSemana})
          <Button 
            size="small"
            onClick={()=> onDaySelect(horario)}
          >
            Seleccionar todo
          </Button>
        </Typography>
        <div className={css.horas}>
          <div className={css.horasBloque}>
            {horario.horasTemprano.map(hora=> (
              <HoraCheckbox
                key={hora}
                fecha={horario.fecha}
                hora={hora}
                horas={horas}
                onChange={onChange}
              />
            ))}
          </div>
          <div className={css.horasBloque}>
            {horario.horasTarde.map(hora=> (
              <HoraCheckbox
                key={hora}
                fecha={horario.fecha}
                hora={hora}
                horas={horas}
                onChange={onChange}
              />
            ))}
          </div>
        </div>
      </div>
    ))}
  </div>
)


const ModalPlanificarClasesProfesor = ({
  open,
  mes,
  onClose,
  onSubmit,
}) => {

  const notification = useNotification()
  const userData = getAuth()

  const [profesor, setProfesor] = useState('')

  const [horas, setHoras] = useState([])

  useEffect(()=> {
    if (open) {
      setProfesor('')
      setHoras([])
    }
  }, [open, userData.centro])

  const handleChangeHora = ({ fecha, hora, checked })=> {
    if (checked) {
      setHoras([...horas, { fecha, hora }])
    } else {
      setHoras(horas.filter(h=> !(h.fecha === fecha && h.hora === hora)))
    }
  }

  const handleChangeDia = (datos)=> {
    setHoras([
      ...horas.filter(h=> h.fecha !== datos.fecha),
      ...datos.horas.map(hora=> ({ fecha: datos.fecha, hora }))
    ])
  }

  const mesSiguiente = forwardMonths(mes, 1)

  const { data: horariosList=[] } = useQuery({
    queryKey: ['planificacion', 'modelo-horario', 'list', 'dates', mes],
    enabled: open,
    queryFn: ()=> getHorarios({
      fecha_inicio: dateToString(mes),
      fecha_fin: dateToString(mesSiguiente),
      centro: userData.centro,
    })
      .then(datos=> datos
          .sort((a, b)=> a.fecha < b.fecha ? -1 : 1)
          .map(horario=> ({
          ...horario,
          horas: horario.horas.sort(),
          horasTemprano: horario.horas.filter(h=> h < '15:00').sort(),
          horasTarde: horario.horas.filter(h=> h >= '15:00').sort(),
          fecha: dateToString(horario.fecha),
          diaSemana: dateToWeekday(horario.fecha)
        }))
      )
      .catch(err=> {
        notification.error({ title: 'Error al recuperar los horarios', content: err })
        return []
      })
  })

  const isSubmitDisabled = !profesor || horas.length === 0

  const handleSubmit = ()=> {
    onSubmit({ profesor, horas })
  }

  return (
    <Dialog onClose={onClose} open={open} fullWidth maxWidth='md'>
      <DialogTitle>Planificar horarios del profesorado</DialogTitle>
      <DialogContent className={css.content}>
        <ProfesorSelector
          className={css.input}
          name='profesor'
          label='Profesor'
          value={profesor}
          onChange={(_e, value)=> setProfesor(value?.id || '')}
        />
        <ConfigurarHorario
          horas={horas}
          horarios={horariosList}
          onDaySelect={handleChangeDia}
          onChange={handleChangeHora}
        />
      </DialogContent>
      <DialogActions>
        <Button color='error' onClick={onClose}>
          Cancelar
        </Button>
        <Button
          color='primary'
          disabled={isSubmitDisabled}
          onClick={handleSubmit}
        >
          {horas.length ? `Añadir ${horas.length} ${pluralize('hora', horas.length)}` : 'Añadir horas'}
          
        </Button>
      </DialogActions>
    </Dialog>
  )

}

export default ModalPlanificarClasesProfesor