import { useEffect } from "react"
import allRoutes from '../layout/SideMenu/routes.json'
import { useLocation } from "react-router-dom"

const getBestTitle = (location)=> {
  const routeTitle = getTitleFromRoutes(location)
  if (routeTitle) return `${routeTitle} | Ágora`
  // El perfil
  if (location === '/perfil') return 'Perfil | Ágora'
  // Las páginas de alumno
  if (location.match(/\/alumnos\/[0-9a-f]{24}\/asistencias/)) return 'Asistencias alumno | Ágora'
  if (location.match(/\/alumnos\/[0-9a-f]{24}\/contabilidad/)) return 'Contabilidad alumno | Ágora'
  if (location.match(/\/alumnos\/[0-9a-f]{24}\/ficha/)) return 'Ficha alumno | Ágora'
  if (location.match(/\/alumnos\/[0-9a-f]{24}\/foto/)) return 'Foto alumno | Ágora'
  if (location.match(/\/alumnos\/[0-9a-f]{24}\/historico/)) return 'Histórico alumno | Ágora'
  if (location.match(/\/alumnos\/[0-9a-f]{24}\/notas/)) return 'Notas alumno | Ágora'
  // Las páginas de lead
  if (location.match(/\/leads\/[0-9a-f]{24}\/ficha/)) return 'Ficha lead | Ágora'
  if (location.match(/\/leads\/[0-9a-f]{24}\/contactos/)) return 'Contactos lead | Ágora'
  // Ultimate fallback
  return `Ágora | mundoestudiante`
}

const getTitleFromRoutes = (location)=> {
  for (let i=0; i < allRoutes.length; i++) {
    const title = getRouteMatch(location, allRoutes[i])
    if (title) return title
  }
  return ''
}

const getRouteMatch = (location, route)=> {
  if (route.to === location) return route.title
  if (route.children) {
    for (let i = 0; i < route.children.length; i++) {
      const result = getRouteMatch(location, route.children[i]);
      if (result) return `${route.title} - ${result}`
    }
  }
  return ''
}


const useTitle = ()=> {

  const location = useLocation()

  useEffect(()=> {
    document.title = getBestTitle(location.pathname)
  }, [location.pathname])

}

export default useTitle