import { useState } from 'react'
import { Accordion, AccordionDetails, AccordionSummary, Chip, IconButton, Link } from '@mui/material'
import InformationTable from '../../../../components/Table/InformationTable'
import { useQuery } from '@tanstack/react-query'
import { useNotification } from '../../../../context/NotificationManager/NotificationManager'
import { downloadCSVFile, getCSVContent } from '../../../../utils/csv'
import TableHeader from '../../../../components/TableHeader/TableHeader'
import PageLoading from '../../../../components/PageLoading/PageLoading'
import { dateToString } from '../../../../utils/date'
import { format, formatForExport } from '../../../../utils/math'
import { getPagosMes, getPagosPackHorasMes, getPagosTarifaMes } from '../../../../utils/api/contabilidad'
import { dateSort, numberSort, textFilter } from '../../../../utils/table'
import css from './Ventas.module.css'

const VentasSection = ({ isLoading, label, pagos, filter })=> {

  const [isExpanded, setExpanded] = useState(false)

  const deuda = format(pagos.reduce((acc, pago)=> acc + pago.importeRestante, 0))
  const ventas = format(pagos.reduce((acc, pago)=> acc + pago.importePago, 0))

  const handleChange = (_event, isExpanded) => {
    setExpanded(isExpanded)
  }
  
  return (
    <div className={css.section}>
      <Accordion 
        disabled={isLoading}
        expanded={isExpanded} 
        onChange={handleChange}
      >
        <AccordionSummary
          expandIcon={<i className='material-icons'>expand_more</i>}
        >
          <div className={css.summary}>
            {pagos.length} {label}
            {!isLoading && (
              <div className={css.chips}>
                <Chip 
                  className={css.chip} 
                  label={`Ventas: ${ventas}€`} 
                  color="primary" 
                  variant="outlined" 
                  />
                <Chip 
                  className={css.chip} 
                  label={`Deuda: ${deuda}€`} 
                  color="error" 
                  variant="outlined" 
                />
              </div>
            )}
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <InformationTable
            details={[
              { title: 'Alumno', key: 'alumno', sortKey: 'alumnoNombre' },
              { title: 'Fecha', key: 'fecha', sortFunction: dateSort },
              { title: 'Concepto', key: 'concepto' },
              { title: 'Importe', key: 'importe', align: 'right', sortFunction: numberSort },
              { title: 'Importe restante', key: 'importeRestante', align: 'right', sortFunction: numberSort },
            ]}
            data={pagos.filter(filter).map((pago) => ({
              alumnoNombre: pago.alumno,
              alumno: (
                <Link
                  className={css.link}
                  href={`/alumnos/${pago.alumnoId}/ficha`}
                >
                  {pago.alumno}
                </Link>
              ),
              fecha: pago.fecha,
              importe: `${format(pago.importePago)} €`,
              importeRestante: (
                <span 
                  className={css.deuda} 
                  data-status={pago.importeRestante > 0 ? 'deuda' : 'pagado'}
                >
                  {format(pago.importeRestante)} €
                </span>
              ),
              concepto: pago.concepto,
            }))}
            isFetching={isLoading}
          />
        </AccordionDetails>
      </Accordion>
    </div>
  )
}

const Ventas = ({ mes })=> {

  const notification = useNotification()

  const [search, setSearch] = useState('')

  const { isLoading: isLoadingPagos, data: pagoList=[] } = useQuery({
    queryKey: ['contabilidad', 'pago', 'list', 'month', mes], 
    queryFn: ()=> getPagosMes(mes)
      .then(datos=> datos
        .sort((a, b)=> b.fecha - a.fecha)
        .map(pago=> ({
          ...pago,
          fecha: dateToString(pago.fecha),
        }))
      )
      .catch(err=> {
        notification.error({ title: 'Error al recuperar las ventas', content: err })
        return []
      })
  })

  const { isLoading: isLoadingTarifas, data: pagoTarifaList=[] } = useQuery({
    queryKey: ['contabilidad', 'pago-tarifa', 'list', 'month', mes], 
    queryFn: ()=> getPagosTarifaMes(mes)
      .then(datos=> datos
        .sort((a, b)=> b.fecha - a.fecha)
        .map(pago=> ({
          ...pago,
          fecha: dateToString(pago.fecha),
        }))
      )
      .catch(err=> {
        notification.error({ title: 'Error al recuperar las ventas', content: err })
        return []
      })
  })

  const { isLoading: isLoadingPacksHoras, data: pagoPackHorasList=[] } = useQuery({
    queryKey: ['contabilidad', 'pago-pack-horas', 'list', 'month', mes], 
    queryFn: ()=> getPagosPackHorasMes(mes)
      .then(datos=> datos
        .sort((a, b)=> b.fecha - a.fecha)
        .map(pago=> ({
          ...pago,
          fecha: dateToString(pago.fecha),
        }))
      )
      .catch(err=> {
        notification.error({ title: 'Error al recuperar las ventas', content: err })
        return []
      })
  })

  const isLoading = isLoadingPagos || isLoadingTarifas || isLoadingPacksHoras
  const ventas = format([...pagoList, ...pagoTarifaList, ...pagoPackHorasList]
    .reduce((acc, pago)=> acc + pago.importePago, 0))
  const deuda = format([...pagoList, ...pagoTarifaList, ...pagoPackHorasList]
    .reduce((acc, pago)=> acc + pago.importeRestante, 0)) 

  const handleExport = ()=> {
    const content = getCSVContent({
      columns: [
        'Alumno',
        'Fecha',
        'Concepto',
        'Importe',
        'Importe Pendiente',
        'Tipo de Pago',
      ],
      data: [...pagoList, ...pagoTarifaList, ...pagoPackHorasList],
      transform: c=> ([
        c.alumno,
        c.fecha,
        c.concepto,
        formatForExport(c.importePago),
        formatForExport(c.importeRestante),
        c.tipoPago,
      ])
    })
    downloadCSVFile(content, `pagos-${mes}`)
  }

  const filterSearch = pago=> {
    return textFilter({ 
      object: pago, 
      fields: ['alumno', 'fecha', 'concepto', 'tipoPago', 'importe', 'importeRestante'],
      search: search
    })
  }

  return (
    <PageLoading>
      <TableHeader
        actions={(
          <div className={css.actions}>
            <IconButton className={css.download} size='small' color='primary' onClick={handleExport}>
              <i className='material-icons'>download</i>
            </IconButton>
            {!isLoading && (
              <div className={css.totals}>
                <Chip 
                  className={css.chip} 
                  label={`Ventas: ${ventas}€`} 
                  color="primary" 
                  variant="outlined" 
                />
                <Chip 
                  className={css.chip} 
                  label={`Deuda total: ${deuda}€`} 
                  color="error" 
                  variant="outlined" 
                />
              </div>
            )}
          </div>
        )}
        title='Ventas'
        search={search}
        onSearchChange={e=> setSearch(e.target.value)}
      />
      <VentasSection
        isLoading={isLoadingPagos}
        label='Pagos'
        pagos={pagoList}
        filter={filterSearch}
      />
      <VentasSection
        isLoading={isLoadingTarifas}
        label='Tarifas'
        pagos={pagoTarifaList}
        filter={filterSearch}
      />
      <VentasSection
        isLoading={isLoadingPacksHoras}
        label='Packs de horas'
        pagos={pagoPackHorasList}
        filter={filterSearch}
      />
    </PageLoading>
  )
}

export default Ventas