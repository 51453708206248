import { useEffect, useRef, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { Button, Card, CardActions, CardContent } from '@mui/material'
import AlumnoHeader from '../../../components/AlumnoHeader/AlumnoHeader'
import { useNotification } from '../../../context/NotificationManager/NotificationManager'
import { getFotoAlumno, updateFotoAlumno } from '../../../utils/api/alumnos'
import defaultImage from './profile.jpg'
import useAlumno from '../../../hooks/useAlumno'
import css from './Foto.module.css'

const Foto = () => {
  const notification = useNotification()
  const queryClient = useQueryClient()
  const { id: alumnoId } = useAlumno()

  const [fotoCamara, setFotoCamara] = useState('')
  const videoRef = useRef(null)

  const { data: fotoAlumno = '' } = useQuery({
    queryKey: ['foto-alumno', alumnoId], 
    queryFn: () => getFotoAlumno({ alumno: alumnoId })
    .then(datos=> datos.foto)
    .catch(()=> null)
  })

  const { isPending: isFotoPending, mutate: updateFoto } = useMutation({
    mutationFn: updateFotoAlumno,
    onSuccess: () => {
      notification.success({ title: 'Foto', content: 'Foto subida correctamente' })
      queryClient.invalidateQueries({
        queryKey: ['alumnos', 'foto-alumno', alumnoId]
      })
    },
    onError: err => {
      notification.error({ title: 'Error subiendo la foto', content: err })
    },
  })

  const handleUploadFoto = () => {
    if (isFotoPending) return
    updateFoto({ foto: fotoCamara, alumno: alumnoId })
  }

  const handleTakePicture = () => {
    const photo = document.createElement('canvas')
    const video = videoRef.current
    photo.height = 150
    photo.width = (video.videoWidth * photo.height) / video.videoHeight
    const ctx = photo.getContext('2d')
    ctx.drawImage(video, 0, 0, photo.width, photo.height)
    setFotoCamara(photo.toDataURL())
    photo.remove()
  }

  const handleClearPicture = () => {
    setFotoCamara('')
  }
  useEffect(() => {
    navigator.mediaDevices
      .getUserMedia({ video: true })
      .then(stream => {
        const video = videoRef.current
        video.srcObject = stream
        // There is a known issue here with <React.StrictMode> firing this twice
        video.play()
      })
      .catch(() => {
        notification.error({
          title: 'Error de permisos de webcam',
          content: 'No se ha podido acceder a la webcam. Revisa los permisos'
        })
      })
  }, [notification])

  return (
    <div className={css.main}>
      <AlumnoHeader label='Foto' />
      <div className={css.content}>
        <div className={css.cards}>
          <Card className={css.card} variant='elevation'>
            <CardContent>
              <img
                className={css.image}
                data-visible={fotoCamara ? 'visible' : 'hidden'}
                src={fotoCamara}
                alt='foto alumno'
              />
              <video
                className={css.video}
                data-visible={fotoCamara ? 'hidden' : 'visible'}
                ref={videoRef}
                muted
              />
            </CardContent>
            <CardActions className={css.actions}>
              {!fotoCamara && (
                <Button
                  size='small'
                  variant='outlined'
                  onClick={handleTakePicture}
                >
                  Hacer foto
                </Button>
              )}
              {fotoCamara && (
                <>
                  <Button
                    size='small'
                    variant='outlined'
                    onClick={handleUploadFoto}
                    disabled={!fotoCamara}
                  >
                    Subir foto
                  </Button>
                  <Button
                    size='small'
                    variant='outlined'
                    color='warning'
                    onClick={handleClearPicture}
                  >
                    Sacar otra foto
                  </Button>
                </>
              )}
            </CardActions>
          </Card>
          <Card className={css.card} variant='elevation'>
            <CardContent>
              <img
                src={fotoAlumno || defaultImage}
                alt='foto alumno'
                className={css.image}
              />
            </CardContent>
            <CardActions className={css.actions}>
              {fotoAlumno ? 'Foto actual' : 'Sin foto actual'}
            </CardActions>
          </Card>
        </div>
      </div>
    </div>
  )
}

export default Foto
