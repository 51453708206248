import { useState } from 'react'
import { Accordion, AccordionSummary, AccordionDetails, Typography, IconButton, Alert, Link } from '@mui/material'
import TextLabel from '../../../../../components/form/TextLabel/TextLabel'
import { useQuery } from '@tanstack/react-query'
import { getAlumno } from '../../../../../utils/api/classfy'
import { getClassfyAdminURL } from '../../../../../utils/documents'
import css from './DatosClassfy.module.css'

const DatosClassfy = ({ 
  alumno,
  onEdit,
 })=> {

  const [isExpanded, setExpanded] = useState(true)

  const { isFetching: isFichaClassfyLoading, data: fichaClassfy = null } = useQuery({
    queryKey: ['classfy', alumno.classfyId], 
    enabled: !!alumno.classfyId && !!alumno.classfyId.match(/[0-9a-f]{24}/),
    queryFn: () => getAlumno(alumno.classfyId)
    .then(datos=> datos)
    .catch(()=> null)
  })

  const noClassfyDetails = !isFichaClassfyLoading && !alumno.classfyId
  const badClassfyDetails = !isFichaClassfyLoading && !!alumno.classfyId && !fichaClassfy
  const correctClassfyDetails = !isFichaClassfyLoading && !!fichaClassfy

  const handleChange = (_event, isExpanded) => {
    setExpanded(isExpanded)
  }

  const handleEdit = (e)=> {
    e.stopPropagation()
    onEdit()
  }

  return (
    <Accordion expanded={isExpanded} onChange={handleChange}>
      <AccordionSummary
        expandIcon={<i className='material-icons'>expand_more</i>}
      >
        <div className={css.label}>
          <Typography className={css.title} variant="h2">Datos de Classfy</Typography>
          {isExpanded && (
            <IconButton className={css.button} size='small' color='secondary' onClick={handleEdit}>
              <i className='material-icons'>edit</i>
            </IconButton>
          )}
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <div className={css.row}>
          {isFichaClassfyLoading && (
            <Alert icon={<i className='material-icons'>info</i>} className={css.alert} severity='info'>
              Cargando datos de Classfy...
            </Alert>
          )}
          {noClassfyDetails && (
            <Alert icon={<i className='material-icons'>info</i>} className={css.alert} severity='info'>
              No se ha vinculado el alumno con Classfy
            </Alert>
          )}
          {badClassfyDetails && (
            <Alert icon={<i className='material-icons'>error</i>} className={css.alert} severity='error'>
              No se han encontrado los datos de Classfy
            </Alert>
          )}
          {correctClassfyDetails && (
            <Alert icon={<i className='material-icons'>check</i>} className={css.alert} severity='success'>
              Vinculado con la ficha de{' '}
              <Link target='_blank' href={getClassfyAdminURL({ id: fichaClassfy.id })}>
                <strong>{fichaClassfy.name} ({fichaClassfy.email})</strong>.
              </Link>
              <br />
              {fichaClassfy.active ? 'El alumno está activo en Classfy' : 'El alumno está inactivo en Classfy'}
            </Alert>
          )}
        </div>
        <div className={css.row}>
          <TextLabel
            className={css.field}
            name='classfy_id'
            label='ID Ficha de Classfy'
            value={alumno.classfyId}
          />
        </div>
      </AccordionDetails>
    </Accordion>
  )
}

export default DatosClassfy