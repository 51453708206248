import { useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { useNotification } from '../context/NotificationManager/NotificationManager'
import { getAlumnoName } from '../utils/api/alumnos'

const useAlumno = () => {
  const notification = useNotification()

  const { id } = useParams()

  const { isLoading, data: alumno = {} } = useQuery({
    queryKey: ['alumnos', 'alumno', 'name', id],
    enabled: !!id,
    queryFn: () => getAlumnoName(id)
      .then(datos => datos)
      .catch(err => {
        notification.error({ title: 'Error al recuperar los datos del alumno', content: err })
        return {}
      })
  })

  return {
    isLoading,
    id,
    nombre: alumno ? alumno.nombre : ''
  }
}

export default useAlumno
