import { useState } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import CursoSelector from '../../components/form/CursoSelector/CursoSelector'
import ObjetivoAlumnoSelector from '../../components/form/ObjetivoAlumnoSelector/ObjetivoAlumnoSelector'
import CodigoPromocionalSelector from '../../components/form/CodigoPromocionalSelector/CodigoPromocionalSelector'
import TarifaSelector from '../../components/form/TarifaSelector/TarifaSelector'
import NivelEstudiosSelector from '../../components/form/NivelEstudiosSelector/NivelEstudiosSelector'
import CentroEstudiosSelector from '../../components/form/CentroEstudiosSelector/CentroEstudiosSelector'
import MesRecienteSelector from '../../components/form/MesRecienteSelector/MesRecienteSelector'
import { isAnyBlank } from '../../utils/string'
import css from './ModalNuevaTarifa.module.css'

const ModalNuevaTarifa = ({
  open,
  onClose,
  onSubmit,
})=> {

  const [datos, setDatos] = useState({
    centro_estudios: '',
    formacion: '',
    curso: '',
    objetivo: '',
    tarifa: '',
    mes: '',
    codigo_promocional: '',
  })

  const isSubmitDisabled = isAnyBlank(
    datos.centro_estudios,
    datos.formacion,
    datos.curso,
    datos.objetivo,
    datos.tarifa,
    datos.mes
  )

  return (
    <Dialog onClose={onClose} open={open} fullWidth maxWidth='md'>
      <DialogTitle>Dar de alta una nueva tarifa</DialogTitle>
      <DialogContent className={css.content}>
        <TarifaSelector
          className={css.input}
          name='tarifa_contratada'
          label='Tarifa a contratar'
          value={datos.tarifa}
          onChange={e=> setDatos(tarifa=> ({...tarifa, tarifa: e.target.value}))}
        />
        <MesRecienteSelector
          className={css.input}
          name='mes_tarifa'
          label='Mes de la tarifa'
          value={datos.mes}
          backwardOptions={6}
          forwardOptions={2}
          onChange={e=> setDatos(tarifa=> ({...tarifa, mes: e.target.value}))}
        />
        <CentroEstudiosSelector
          className={css.input}
          name='estudios'
          label='Centro de estudios'
          value={datos.centro_estudios}
          onChange={value=> setDatos(tarifa=> ({...tarifa, centro_estudios: value}))}
        />
        <NivelEstudiosSelector
          className={css.input}
          name='formacion'
          label='Nivel'
          value={datos.formacion}
          onChange={e=> setDatos(tarifa=> ({...tarifa, formacion: e.target.value}))}
        />
        <CursoSelector
          className={css.input}
          name='curso'
          label='Curso'
          value={datos.curso}
          onChange={e=> setDatos(tarifa=> ({...tarifa, curso: e.target.value}))}
        />
        <ObjetivoAlumnoSelector
          className={css.input}
          name='objetivo'
          label='Objetivo'
          value={datos.objetivos}
          onChange={e=> setDatos(tarifa=> ({...tarifa, objetivo: e.target.value}))}
        />
        <CodigoPromocionalSelector
          className={css.input}
          name='codigo_promocional'
          label='Código promocional'
          value={datos.codigo_promocional}
          onChange={e=> setDatos(tarifa=> ({...tarifa, codigo_promocional: e.target.value}))}
        />
      </DialogContent>
      <DialogActions>
        <Button color='error' onClick={onClose}>
          Cancelar
        </Button>
        <Button
          color='primary'
          disabled={isSubmitDisabled}
          onClick={()=> onSubmit(datos)}
        >
          Enviar
        </Button>
      </DialogActions>
    </Dialog>
  )
}
export default ModalNuevaTarifa
