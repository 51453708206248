import { useState } from 'react'
import { getAlumnoList } from '../../utils/api/alumnos'
import { IconButton, Link } from '@mui/material'
import InformationTable from '../../components/Table/InformationTable'
import { useQuery } from '@tanstack/react-query'
import { useNotification } from '../../context/NotificationManager/NotificationManager'
import { downloadCSVFile, getCSVContent } from '../../utils/csv'
import TableHeader from '../../components/TableHeader/TableHeader'
import PageLoading from '../../components/PageLoading/PageLoading'
import { textFilter } from '../../utils/table'
import css from './Alumnos.module.css'

const Alumnos = ()=> {

  const notification = useNotification()

  const [search, setSearch] = useState('')

  const { isLoading, data: alumnoList=[] } = useQuery({
    queryKey: ['alumno', 'list'], 
    queryFn: ()=> getAlumnoList()
      .then(datos=> datos)
      .catch(err=> {
        notification.error({ title: 'Error al recuperar los alumnos', content: err })
        return []
      })
  })

  const handleExport = ()=> {
    const content = getCSVContent({
      columns: ['Nombre', 'Teléfono Fijo', 'Teléfono Móvil', 'E-mail'],
      data: alumnoList,
      transform: c=> ([
        c.nombre,
        c.fijo,
        c.movil,
        c.email,
      ])
    })
    downloadCSVFile(content, 'alumnos')
  }
  
  const filterSearch = alumno=> {
    return textFilter({ 
      object: alumno, 
      fields: ['nombre', 'fijo', 'movil', 'email'],
      search: search
    })
  }

  return (
    <PageLoading isLoading={isLoading}>
      <TableHeader
        actions={(
          <div className={css.actions}>
            <IconButton className={css.download} size='small' color='primary' onClick={handleExport}>
              <i className='material-icons'>download</i>
            </IconButton>
          </div>
        )}
        title='Alumnos activos'
        search={search}
        onSearchChange={e=> setSearch(e.target.value)}
      />
      <InformationTable
        details={[
          { title: 'Nombre', key: 'nombre' },
          { title: 'Fijo', key: 'fijo', visibility: 'xl', sortDisabled: true },
          { title: 'Móvil', key: 'movil', visibility: 'md', sortDisabled: true },
          { title: 'E-mail', key: 'email', visibility: 'lg', sortDisabled: true }
        ]}
        data={alumnoList.filter(filterSearch).map((alumno) => ({
          nombre: (
            <Link
              className={css.link}
              href={`/alumnos/${alumno.id}/ficha`}
            >
              {alumno.nombre}
            </Link>
          ),
          fijo: alumno.fijo || '--',
          movil: alumno.movil || '--',
          email: alumno.email || '--',
        }))}
        isFetching={isLoading}
      />
    </PageLoading>
  )
}

export default Alumnos
