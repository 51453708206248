import { Step, StepLabel, Stepper } from "@mui/material"
import { useEffect, useState } from "react"
import { dateToString, getToday } from "../../utils/date"
import { createCaja, getCaja } from "../../utils/api/caja"
import { useNotification } from "../../context/NotificationManager/NotificationManager"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import PageLoading from "../../components/PageLoading/PageLoading"
import { getPagosPendientesRecientes } from "../../utils/api/contabilidad"
import AbrirCaja from "./components/AbrirCaja/AbrirCaja"
import css from './TerminalPagos.module.css'
import OperacionesCaja from "./components/OperacionesCaja/OperacionesCaja"
import { getIngresosDay } from "../../utils/api/ingresos"
import CerrarCaja from "./components/CerrarCaja/CerrarCaja"

const TerminalPagos = ()=> {

  const fecha = dateToString(getToday())

  const notification = useNotification()
  const queryClient = useQueryClient()

  const [step, setStep] = useState(0)

  const { isLoading, data: caja={} } = useQuery({
    queryKey: ['contabilidad', 'caja', fecha],
    queryFn: ()=> getCaja(fecha)
      .then(datos=> datos)
      .catch(err=> {
        notification.error({ title: 'Error al recuperar los alumnos', content: err })
        return []
      })
  })

  const { isLoading: isPagosLoading, data: pagos=[] } = useQuery({
    queryKey: ['contabilidad', 'pagos', 'pending', 'recent'],
    queryFn: ()=> getPagosPendientesRecientes()
      .then(datos=> datos
          .map(pago=> ({
            ...pago,
            fecha: dateToString(pago.fecha),
          }))
          .sort((a, b)=> a.alumno.localeCompare(b.alumno))
      )
      .catch(err=> {
        notification.error({ title: 'Error al recuperar los pagos recientes', content: err })
        return []
      })
  })

  const { isLoading: isIngresosLoading, data: ingresos=[] } = useQuery({
    queryKey: ['contabilidad', 'ingreso-alumno', 'list', 'day', fecha],
    queryFn: ()=> getIngresosDay(fecha)
      .then(datos=> datos
        .sort((a, b)=> b.fecha - a.fecha)
        .map(ingreso=> ({
          ...ingreso,
          fecha: dateToString(ingreso.fecha),
          fechaFactura: ingreso.fechaFactura ? dateToString(ingreso.fechaFactura) : '',
        }))
      )
      .catch(err=> {
        notification.error({ title: 'Error al recuperar los ingresos de hoy', content: err })
        return []
      })
  })

  const { isPending: isCajaOpening, mutate: abrirCaja } = useMutation({
    mutationFn: createCaja,
    onSuccess: ()=> {
      notification.success({ title: 'Caja abierta', content: 'La terminal de pagos está ahora operativa' })
      queryClient.invalidateQueries({ 
        queryKey: ['contabilidad', 'caja', fecha] 
      })
    },
    onError: (err)=> {
      notification.error({ title: 'Error abriendo caja', content: err })
    }
  })

  useEffect(()=> {
    // Si no hay caja o no hay horaApertura, estamos en el primer paso
    if (!caja || !caja.horaApertura) setStep(0)
    // Si no hay horaCierre, estamos en el segundo paso
    else if (!caja.horaCierre) setStep(1)
    // Si hay horaCierre, estamos en el tercer paso
    else setStep(3)
  }, [caja])

  const handleOpenCaja = (balanceInicial)=> {
    if (isCajaOpening) return
    abrirCaja({ balance_inicial: balanceInicial, observaciones: '' })
  }

  return (
    <PageLoading isLoading={isLoading}>
      <Stepper className={css.stepper} activeStep={step} alternativeLabel>
        <Step>
          <StepLabel>
            {caja.horaApertura ? `Caja abierta a las ${caja.horaApertura}` : 'Abrir caja'}
          </StepLabel>
        </Step>
        <Step>
          <StepLabel>
            {ingresos.length === 0 && 'Sin movimientos de caja'}
            {ingresos.length === 1 && '1 movimiento de caja'}
            {ingresos.length > 1 && `${ingresos.length} movimientos de caja`}
          </StepLabel>
        </Step>
        <Step>
          <StepLabel>
            {caja.horaCierre ? `Caja cerrada a las ${caja.horaCierre}` : 'Cierre caja'}
          </StepLabel>
        </Step>
      </Stepper>
      {step === 0 && <AbrirCaja onOpen={handleOpenCaja} />}
      {step === 1 && (
        <OperacionesCaja
          caja={caja}
          isPagosLoading={isPagosLoading}
          pagos={pagos} 
          isIngresosLoading={isIngresosLoading}
          ingresos={ingresos}
        />
      )}
      {step === 3 && <CerrarCaja caja={caja} />}
    </PageLoading>
  )
}

export default TerminalPagos
