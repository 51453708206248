
import { useState } from 'react'
import EstadoAsistencia from '../../../components/EstadoAsistencia/EstadoAsistencia'
import PageLoading from '../../../components/PageLoading/PageLoading'
import { useNotification } from '../../../context/NotificationManager/NotificationManager'
import { getAsistenciasPendientes, updateAsistencia } from '../../../utils/api/asistencias'
import { getAuth } from '../../../utils/auth'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { dateToString } from '../../../utils/date'
import { downloadCSVFile, getCSVContent } from '../../../utils/csv'
import { dateTimeSort, textFilter } from '../../../utils/table'
import TableHeader from '../../../components/TableHeader/TableHeader'
import { IconButton, Link, Typography } from '@mui/material'
import InformationTable from '../../../components/Table/InformationTable'
import css from './Asistencias.module.css'


const SinProfesor = ()=> (
  <span className={css.placeholder}>Sin profesor</span>
)

const Asistencias = ()=> {

  const userData = getAuth()

  const queryClient = useQueryClient()
  const notification = useNotification()

  const [search, setSearch] = useState('')

  const { isLoading, data: asistenciaList=[] } = useQuery({
    queryKey: ['planificacion', 'asistencia', 'list', 'sin-asistencia'], 
    queryFn: ()=> getAsistenciasPendientes()
      .then(datos=> datos.map(asistencia=> ({
        ...asistencia,
        fecha: dateToString(asistencia.fecha)
      })))
      .catch(err=> {
        notification.error({ title: 'Error al recuperar las asistencias', content: err })
        return []
      })
  })
  
  const { isPending: isUpdatingAsistencia, mutate: modificarAsistencia } = useMutation({
    mutationFn: updateAsistencia,
    onSuccess: ()=> {
      queryClient.invalidateQueries({
        queryKey: [ 'planificacion', 'asistencia', 'list' ],
      })
    },
    onError: err=> {
      notification.error({ title: 'Error al actualizar la asistencia', content: err })
    }
  })

  const handleExport = ()=> {
    const content = getCSVContent({
      columns: ['Alumno', 'Centro', 'Fecha', 'Hora', 'Asignatura', 'Clase', 'Profesor', 'Observaciones'],
      data: asistenciaList,
      transform: c=> ([
        c.alumno,
        c.centro,
        c.fecha,
        c.hora,
        `${c.asignatura} - ${c.nivel}`,
        c.clase,
        c.profesor,
        c.observaciones,
      ])
    })
    downloadCSVFile(content, `asistencias-pendientes`)
  }

  const filterSearch = asistencia=> {
    return textFilter({ 
      object: asistencia, 
      fields: ['alumno', 'centro', 'fecha', 'hora', 'asignatura', 'tipo', 'nivel', 'clase', 'profesor', 'observaciones'],
      search: search
    })
  }

  const handleAsistenciaClick = (estado, asistencia)=> {
    if (isUpdatingAsistencia) return
    modificarAsistencia({ id: asistencia.id, asistencia: estado })
  }

  return (
    <PageLoading isLoading={isLoading}>
      <TableHeader
        actions={(
          <div className={css.actions}>
            <IconButton className={css.download} size='small' color='primary' onClick={handleExport}>
              <i className='material-icons'>download</i>
            </IconButton>
          </div>
        )}
        title={`${asistenciaList.length} asistencias pendientes`}
        search={search}
        onSearchChange={e=> setSearch(e.target.value)}
      />
      {asistenciaList.length === 0 && (
        <div className={css.empty}>
          <Typography className={css.placeholder} variant='body1'>
            No hay asistencias pendientes
          </Typography>
        </div>
      )}
      {asistenciaList.length > 0 && (
        <InformationTable
          details={[
            { title: 'Alumno', key: 'alumno', sortKey: 'alumnoNombre' },
            { title: 'Fecha', key: 'fecha', sortFunction: dateTimeSort, sortKey: 'fechaInicio' },
            { title: 'Asignatura', key: 'asignatura' },
            { title: 'Profesor', key: 'profesor' },
            { title: 'Asistencia', key: 'asistencia', align: 'center', sortDisabled: true },
            { title: 'Observaciones', key: 'observaciones', sortDisabled: true },
          ]}
          data={asistenciaList.filter(filterSearch).map((asistencia) => ({
            alumnoNombre: asistencia.alumno,
            alumno: (
              <Link
                className={css.link}
                href={`/alumnos/${asistencia.alumnoId}/ficha`}
              >
                {asistencia.alumno}
              </Link>
            ),
            fecha: `${asistencia.fecha} ${asistencia.hora}`,
            fechaInicio: `${asistencia.fecha} ${asistencia.hora.substring(0, 5)}`,
            asignatura: asistencia.asignatura,
            profesor: asistencia.profesor || <SinProfesor />,
            asistencia: (
              <EstadoAsistencia
                asistencia={asistencia}
                centro={userData.centro}
                onClick={(estado)=> handleAsistenciaClick(estado, asistencia)}
              />
            ),
            observaciones: asistencia.observaciones,
          }))}
        />
      )}
    </PageLoading>
  )
}

export default Asistencias