import { IconButton, SpeedDial, SpeedDialAction, SpeedDialIcon } from '@mui/material'
import Calendar from '../../../../../components/Calendar/Calendar'
import { getAuth } from '../../../../../utils/auth'
import { dateFromString, dateToMonth, dateToString, forwardDays, forwardMonths, getStartOfMonth, isSameMonth, monthToString } from '../../../../../utils/date'
import { useNotification } from '../../../../../context/NotificationManager/NotificationManager'
import { useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { getAsistenciasAlumno } from '../../../../../utils/api/asistencias'
import { getPlanificacionClases } from '../../../../../utils/documents'
import css from './VerAsistencias.module.css'
import useAlumno from '../../../../../hooks/useAlumno'
import { mergeClases } from '../../../../../utils/model/asistencias'

const AsistenciaCalendario = ({ asignatura, asistencia, clase, nivel, hora, centro, dimmed })=> {
  const userData = getAuth()
  return (
    <div className={css.calendarEvent} data-clase={clase} data-status={dimmed ? 'dimmed' : 'normal'}>
      <p className={css.calendarEventRow}>
        <span className={css.calendarEventAsistencia} data-asistencia={asistencia} />
        <p className={css.calendarEventAsignatura}>
          {asignatura} <span className={css.calendarEventDetail}>({nivel.substring(0, 4)})</span>
        </p>
      </p>
      <p className={css.calendarEventRow}>
        {hora} {userData.centro === centro ? '' : <span className={css.calendarEventDetail}>({centro})</span>}
      </p>
    </div>
  )
}

const VerAsistencias = ({ alumnoId }) => {

  const notification = useNotification()

  const userData = getAuth()

  const { nombre: alumnoNombre } = useAlumno()

  const [mes, setMes] = useState(getStartOfMonth())
  const [speedDialOpen, setSpeedDialOpen] = useState(false)
  const [isGeneratingDocument, setGeneratingDocument] = useState(false)

  const handleMonthChange = (offset) => {
    const newMes = new Date(mes)
    newMes.setMonth(newMes.getMonth() + offset)
    setMes(newMes)
  }

  const generatePlanification = ()=> {
    if (isGeneratingDocument) return
    setGeneratingDocument(true)
    getPlanificacionClases({ 
      academia: userData.centro, 
      mes: monthToString(mes), 
      alumno: alumnoNombre, 
      asistencias: mergeClases(asistenciaList)
        .map(asistencia=> ({
          centro: asistencia.centro,
          fecha: asistencia.fecha,
          hora: asistencia.hora.substring(0, 5),
          asignatura: asistencia.asignatura, 
          detalle: `${asistencia.count} ${asistencia.count === 1 ? 'hora' : 'horas'}`
        }))
        .filter(asistencia=> isSameMonth(dateFromString(asistencia.fecha), mes))
        .sort((a, b)=> {
          const momentA = `${a.fecha} ${a.hora}`
          const momentB = `${b.fecha} ${b.hora}`
          if (momentA < momentB) return -1
          if (momentA > momentB) return 1
          return 0
        })
    })
    .finally(()=> setGeneratingDocument(false))
  }

  const { data: asistenciaList=[] } = useQuery({
    queryKey: ['planificacion', 'asistencia', 'list', 'alumno', alumnoId, mes], 
    queryFn: ()=> getAsistenciasAlumno({
      alumno: alumnoId,
      fecha: dateToString(forwardDays(mes, -6)),
      fechaFin: dateToString(forwardDays(forwardMonths(mes, 1), 5))
    })
      .then(datos=> datos
        .sort((a, b)=> {
          if (a.fecha < b.fecha) return -1
          if (a.fecha > b.fecha) return 1
          if (a.hora < b.hora) return -1
          if (a.hora > b.hora) return 1
          return 0
        })
        .map(asistencia=> ({
          ...asistencia,
          fecha: dateToString(asistencia.fecha)
        })))
      .catch(err=> {
        notification.error({ title: 'Error al recuperar las asistencias', content: err })
        return []
      })
  })


  const asistenciasCalendario = asistenciaList
    .map(asistencia=> ({
      fecha: asistencia.fecha,
      component: (
        <AsistenciaCalendario
          key={asistencia.id}
          clase={asistencia.clase.toLowerCase()}
          asistencia={asistencia.asistencia.toLowerCase()}
          asignatura={asistencia.asignatura}
          nivel={asistencia.nivel}
          hora={asistencia.hora}
          centro={asistencia.centro}
          dimmed={!isSameMonth(dateFromString(asistencia.fecha), mes)}
        />
      )
    }))
    .reduce((acc, asistencia)=> {
      return {
        ...acc,
        [asistencia.fecha]: [
          ...(acc[asistencia.fecha] || []),
          asistencia.component
        ]
      }
    }, {})

  return (
    <div className={css.calendar}>
      <div className={css.monthSelection}>
        <IconButton className={css.monthIcon} size='small' onClick={()=> handleMonthChange(-1)}>
          <i className='material-icons'>arrow_back</i>
        </IconButton>
        <p className={css.calendarMonth}>
          {dateToMonth(mes)}
        </p>
        <IconButton className={css.monthIcon} size='small' onClick={()=> handleMonthChange(+1)}>
          <i className='material-icons'>arrow_forward</i>
        </IconButton>
      </div>
      <Calendar
        month={monthToString(mes)}
        events={asistenciasCalendario}
      />
      <SpeedDial
        className={css.speed}
        ariaLabel="Nuevo documento"
        icon={<SpeedDialIcon />}
        onClose={()=> setSpeedDialOpen(false)}
        onOpen={()=> setSpeedDialOpen(true)}
        open={speedDialOpen && !isGeneratingDocument}
      >
        <SpeedDialAction
          icon={<i className='material-icons'>history_edu</i>}
          tooltipTitle='Planificación'
          tooltipOpen
          onClick={generatePlanification}
        />
      </SpeedDial>
    </div>
  )
}

export default VerAsistencias