import { Alert, Card, CardContent, CardHeader } from "@mui/material"
import css from './CerrarCaja.module.css'

const CerrarCaja = ({ caja })=> {


  return (
    <Card elevation={4} className={css.cerrar}>
      <CardHeader title='Caja cerrada' />
      <CardContent className={css.content}>
        <Alert className={css.info} severity='info'>
          {caja.cerradoPor} ha cerrado la caja a las {caja.horaCierre}.
        </Alert>
        <p>
          No se pueden realizar operaciones con la caja cerrada
          
        </p>
      </CardContent>
    </Card>
  )
}

export default CerrarCaja