import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import css from './NivelEstudiosSelector.module.css'

const NIVELES = [
  'PRIMARIA',
  'SECUNDARIA',
  'BACHILLERATO',
  'SELECTIVIDAD',
  'PCE',
  'UNIVERSIDAD',
  'POST_UNIVERSIDAD',
  'ACCESO_MAYORES',
  'ACCESO_GRADO',
  'MODULO',
  'FP',
  'IDIOMA',
  'OTROS'
]

const NivelEstudiosSelector = ({ 
  className, 
  name, 
  label, 
  value, 
  onChange 
}) => (
  <FormControl
    className={`${className} ${css.main}`}
    variant='standard'
    margin='dense'
  >
    <InputLabel id={name}>{label}</InputLabel>
    <Select
      name={name}
      labelId={name}
      id={name}
      value={value}
      onChange={onChange}
      label={label}
    >
      {NIVELES.map(nivel => (
        <MenuItem key={nivel} value={nivel}>
          {nivel.replace('_', ' ')}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
)

export default NivelEstudiosSelector
