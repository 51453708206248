import { useState } from "react"
import { monthToString } from "../../../utils/date"
import { getAuth } from "../../../utils/auth"
import { Typography } from "@mui/material"
import MesRecienteSelector from "../../../components/form/MesRecienteSelector/MesRecienteSelector"
import Tabs from "../../../components/Tabs/Tabs"
import Ingresos from "./tabs/Ingresos/Ingresos"
import Pagos from "./tabs/Pagos/Pagos"
import Facturas from "./tabs/Facturas/Facturas"
import css from './Contabilidad.module.css'


const Contabilidad = ()=> {

  const [mes, setMes] = useState(monthToString(new Date()))

  const userData = getAuth()

  return (
    <>
      <div className={css.header}>
        <Typography variant='h6'>Seguimiento de la contabilidad de {userData.centro}</Typography>
        <MesRecienteSelector
          className={css.mes}
          name='mes'
          label='Mes'
          value={mes}
          forwardOptions={1}
          backwardOptions={18}
          onChange={e=> setMes(e.target.value)}
        />
      </div>
      <Tabs
        className={css.tabs}
        tabs={[
          { name: 'Pagos', content: <Pagos mes={mes} />},
          { name: 'Ingresos sin asignar', content: <Ingresos mes={mes} /> },
          { name: 'Facturas pendientes', content: <Facturas mes={mes} /> },
        ]}
      />
    </>
  )
}

export default Contabilidad