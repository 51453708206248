import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import css from './FormaContactoSelector.module.css'

const FORMAS_CONTACTO = [
  'Whatsapp',
  'Persona',
  'Teléfono',
  'E-mail'
]

const VALORES_ACEPTADOS = [
  'Formulario Web',
  'Typeform',
  'Playfilm'
]

const FormaContactoSelector = ({ className, name, label, value, onChange })=> (
  <FormControl 
    className={`${className} ${css.main}`} 
    variant='standard'
    disabled={VALORES_ACEPTADOS.includes(value)}
    fullWidth
  >
    <InputLabel id={name}>{label}</InputLabel>
    <Select
      name={name}
      labelId={name}
      id={name}
      value={value}
      onChange={onChange}
      label={label}
    >
      {FORMAS_CONTACTO.map(forma => (
        <MenuItem key={forma} value={forma}>
          {forma}
        </MenuItem>
      ))}
      {VALORES_ACEPTADOS.includes(value) && (
        <MenuItem value={value}>
          {value}
        </MenuItem>
      )}
    </Select>
  </FormControl>
)

export default FormaContactoSelector
