
import { useState } from 'react'
import { Button, Collapse, FormControl, IconButton, InputLabel, MenuItem, Select, Slider, TableCell, TableRow, Typography } from '@mui/material'
import { format } from '../../../../../utils/math'
import css from './IngresoRow.module.css'

const PagoSelector = ({ value, pagos, onChange })=> (
  <FormControl
    className={css.input}
    variant='standard'
    margin='dense'
    fullWidth
  >
    <InputLabel id='pago'>Pago a seleccionar</InputLabel>
    <Select
      name='pago'
      labelId='pago'
      id='pago'
      value={value}
      onChange={onChange}
      label='Pago a seleccionar'
    >
      {pagos.map(pago => (
        <MenuItem key={pago.id} value={pago.id}>
          {pago.concepto} ({pago.fecha})
        </MenuItem>
      ))}
    </Select>
  </FormControl>
)

const IngresoRow = ({ ingreso, pagos, onAssign })=> {

  const [isOpen, setOpen] = useState(false)

  const [pago, setPago] = useState({
    pago: '',
    importe: 0,
  })

  const handleSubmit = ()=> {
    const tipoPago = pagos.find(p => p.id === pago.pago).tipo
    onAssign({
      ingreso: ingreso.id,
			pago: pago.pago,
			tipo_pago: tipoPago,
			importe: pago.importe
    })
  }

  return (
    <>
      <TableRow hover={!isOpen} data-open={isOpen ? 'open' : 'closed'} className={css.row}>
        <TableCell className={css.mainCell}>
          {ingreso.concepto}
        </TableCell>
        <TableCell className={css.mainCell}>
          {ingreso.fecha} {ingreso.hora}
        </TableCell>
        <TableCell className={css.mainCell}>
          {ingreso.formaPago}
        </TableCell>
        <TableCell className={css.mainCell}>
          {format(ingreso.importe)} €
        </TableCell>
        <TableCell className={css.mainCell}>
          <IconButton
            size="small"
            onClick={()=> setOpen(!isOpen)}
          >
            <i className='material-icons'>
              {isOpen ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
            </i>
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow data-open={isOpen ? 'open' : 'closed'} className={css.detailRow}>
        <TableCell className={css.additionalRow} colSpan={7}>
          <Collapse in={isOpen} timeout="auto" unmountOnExit>
            <Typography variant="body1" className={css.subtitle}>
              Asignar ingreso
            </Typography>
            <div className={css.form}>
              <PagoSelector
                value={pago.pago}
                pagos={pagos}
                onChange={e => setPago({ ...pago, pago: e.target.value })}
              />
              <div className={css.input}>
                <Typography variant="body1" className={css.detail}>
                  Importe a asignar
                </Typography>
                <Slider
                  valueLabelDisplay="on"
                  value={pago.importe}
                  valueLabelFormat={value => `${format(value)} €`}
                  onChange={(e, value) => setPago({ ...pago, importe: value })}
                  step={0.5}
                  min={0}
                  max={ingreso.importe}
                />
              </div>
              <Button
                variant='outlined'
                color='primary'
                startIcon={<i className='material-icons'>add</i>}
                disabled={!pago.pago || !pago.importe}
                className={css.button}
                onClick={handleSubmit}
              >
                Asignar ingreso
              </Button>
            </div>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}

export default IngresoRow