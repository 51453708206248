import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker'
import css from './FechaSelector.module.css'

const FechaSelector = ({
  className = '',
  label,
  value,
  disabled = false,
  disablePast = false,
  disableFuture = false,
  onChange
}) => (
  <div className={`${css.wrapper} ${className}`}>
    <MobileDatePicker
      label={label}
      inputFormat='dd/MM/yyyy'
      mask='__/__/____'
      value={value}
      disabled={disabled}
      disableFuture={disableFuture}
      disablePast={disablePast}
      closeOnSelect
      onChange={onChange}
      slotProps={{ textField: { variant: 'standard' }}}
    />
  </div>
)

export default FechaSelector
