import { useEffect, useState } from "react"
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@mui/material"
import { isAnyBlank } from "../../utils/string"
import AsignaturaSelector from "../../components/form/AsignaturaSelector/AsignaturaSelector"
import ProfesorSelector from "../../components/form/ProfesorSelector/ProfesorSelector"
import AcademiaSelector from "../../components/form/AcademiaSelector/AcademiaSelector"
import css from './ModalEditarAsistencia.module.css'


const ModalEditarAsistencia = ({ open, asistencia, disabled, onSubmit, onClose }) => {
  
  const [datos, setDatos] = useState({
    asignatura: '',
    profesor: '',
    centro: '',
    observaciones: '',
  })

  useEffect(()=> {
    setDatos({
      asignatura: '',
      profesor: '',
      centro: '',
      observaciones: '',
    })
  }, [open])

  useEffect(()=> {
    if (!asistencia) return
    setDatos({
      asignatura: asistencia.asignaturaId,
      profesor: asistencia.profesorId,
      centro: asistencia.centro,
      observaciones: asistencia.observaciones,
    })
  }, [asistencia])

  const isSubmitDisabled = isAnyBlank(datos.asignatura, datos.centro)

  const handleSubmit = () => {
    onSubmit({
      id: asistencia.id,
      asignatura: datos.asignatura,
      profesor: datos.profesor,
      centro: datos.centro,
      observaciones: datos.observaciones,
    })
  }

  return (
    <Dialog onClose={onClose} open={open} fullWidth maxWidth='md'>
      <DialogTitle>Editar datos de la clase</DialogTitle>
      <DialogContent className={css.main}>
        <DialogContentText className={css.intro}>
          Puedes modificar los datos de la clase
        </DialogContentText>
        <AsignaturaSelector
          className={css.input}
          name='asignatura'
          label='Asignatura'
          value={datos.asignatura}
          onChange={(_e, value)=> setDatos({ ...datos, asignatura: value?.id || '' })}
        />
        <ProfesorSelector
          className={css.input}
          name='profesor'
          label='Profesor'
          value={datos.profesor}
          onChange={(_e, value)=> setDatos({ ...datos, profesor: value?.id || '' })}
        />
        <AcademiaSelector
          className={css.input}
          name='academia'
          label='Academia'
          value={datos.centro}
          onChange={e => setDatos({ ...datos, centro: e.target.value })}
        />
        <TextField
          className={css.input}
          label='Observaciones'
          value={datos.observaciones}
          onChange={e => setDatos({ ...datos, observaciones: e.target.value })}
          variant='standard'
          multiline
        />
      </DialogContent>
      <DialogActions>
        <Button 
          color='error' 
          disabled={disabled}
          onClick={onClose}
        >
          Cancelar
        </Button>
        <Button 
          disabled={isSubmitDisabled || disabled}
          color='primary' 
          onClick={handleSubmit}
        >
          Modificar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ModalEditarAsistencia