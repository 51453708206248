import { useState } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import CursoSelector from '../../components/form/CursoSelector/CursoSelector'
import ObjetivoAlumnoSelector from '../../components/form/ObjetivoAlumnoSelector/ObjetivoAlumnoSelector'
import CodigoPromocionalSelector from '../../components/form/CodigoPromocionalSelector/CodigoPromocionalSelector'
import NivelEstudiosSelector from '../../components/form/NivelEstudiosSelector/NivelEstudiosSelector'
import CentroEstudiosSelector from '../../components/form/CentroEstudiosSelector/CentroEstudiosSelector'
import PackHorasSelector from '../../components/form/PackHorasSelector/PackHorasSelector'
import VolumenHorasPackSelector from '../../components/form/VolumenHorasPackSelector/VolumenHorasPackSelector'
import { isAnyBlank } from '../../utils/string'
import css from './ModalNuevoPackHoras.module.css'

const ModalNuevoPackHoras = ({
  open,
  onClose,
  onSubmit
})=> {

  const [datos, setDatos] = useState({
    centro_estudios: '',
    formacion: '',
    curso: '',
    objetivo: '',
    pack_horas: null,
    horas: '',
    codigo_promocional: ''
  })

  const isSubmitDisabled = isAnyBlank(
    datos.centro_estudios, 
    datos.formacion, 
    datos.curso,
    datos.objetivo, 
    datos.pack_horas, 
    datos.horas
  )

  const handleSubmit = ()=> {
    onSubmit({
      centro_estudios: datos.centro_estudios, 
      formacion: datos.formacion, 
      curso: datos.curso,
      objetivo: datos.objetivo, 
      pack_horas: datos.pack_horas.nombre, 
      horas: datos.horas
    })
  }

  return (
    <Dialog onClose={onClose} open={open} fullWidth maxWidth='md'>
      <DialogTitle>Crear nuevo pack de horas</DialogTitle>
      <DialogContent className={css.content}>
        <PackHorasSelector
          className={css.input}
          name='pack'
          label='Pack'
          value={datos.pack_horas}
          onChange={e=> setDatos(pack=> ({...pack, pack_horas: e.target.value}))}
        />
        <VolumenHorasPackSelector
          className={css.input}
          name='horas'
          label='Horas a contratar'
          value={datos.horas}
          pack={datos.pack_horas}
          onChange={e=> setDatos(pack=> ({...pack, horas: e.target.value}))}
        />
        <CentroEstudiosSelector
          className={css.input}
          name='estudios'
          label='Centro de estudios'
          value={datos.centro_estudios}
          onChange={value=> setDatos(pack=> ({...pack, centro_estudios: value}))}
        />
        <NivelEstudiosSelector
          className={css.input}
          name='nivel'
          label='Nivel'
          value={datos.formacion}
          onChange={e=> setDatos(pack=> ({...pack, formacion: e.target.value}))}
        />
        <CursoSelector
          className={css.input}
          name='curso'
          label='Curso'
          value={datos.curso}
          onChange={e=> setDatos(pack=> ({...pack, curso: e.target.value}))}
        />
        <ObjetivoAlumnoSelector
          className={css.input}
          name='objetivo'
          label='Objetivo'
          value={datos.objetivo}
          onChange={e=> setDatos(pack=> ({...pack, objetivo: e.target.value}))}
        />
        <CodigoPromocionalSelector
          className={css.input}
          name='codigo_promocional'
          label='Código promocional'
          value={datos.codigo_promocional}
          onChange={e=> setDatos(pack=> ({...pack, codigo_promocional: e.target.value}))}
        />
      </DialogContent>
      <DialogActions>
        <Button color='error' onClick={onClose}>
          Cancelar
        </Button>
        <Button
          color='primary'
          disabled={isSubmitDisabled}
          onClick={handleSubmit}
        >
          Enviar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ModalNuevoPackHoras
