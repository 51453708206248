import { GET, PUT, DELETE, POST } from '../request'

export const getPack = nombre=> GET('/api/agora/datos/pack-horas', { nombre })

export const getPacks = () => GET('/api/agora/datos/pack-horas/list')

export const getPacksAlumno = (alumnoId) => GET('/api/agora/alumnos/contabilidad/pack-horas-alumno/list/alumno', { alumno: alumnoId })

export const getPackAlumno = (data = {}) => GET('/api/agora/alumnos/contabilidad/pack-horas-alumno', data)

export const createPackAlumno = ({
  alumno,
  centro_estudios,
  formacion,
  curso,
  objetivo,
  pack_horas,
  horas,
  codigo_promocional
}) => POST('/api/agora/alumnos/contabilidad/pack-horas-alumno', {
  alumno,
  centro_estudios,
  formacion,
  curso,
  objetivo,
  pack_horas,
  horas,
  codigo_promocional
})

export const updatePackAlumno = ({
  id,
  centro_estudios,
  formacion,
  curso,
  objetivo,
  horas,
}) => PUT('/api/agora/alumnos/contabilidad/pack-horas-alumno', {
  id,
  centro_estudios,
  formacion,
  curso,
  objetivo,
  horas,
})

export const deletePack = id => DELETE('/api/agora/alumnos/contabilidad/pack-horas-alumno', { id })
