import { useEffect, useState } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material'
import css from './ModalEditarDatosClassfyAlumno.module.css'

const ModalEditarDatosClassfyAlumno = ({
  open,
  alumno,
  onClose,
  onSubmit,
}) => {

  const [classfyId, setClassfyId] = useState('')

  useEffect(()=> {
    setClassfyId(alumno.classfyId || '')
  }, [open, alumno])

  const handleChange = event => {
    setClassfyId(event.target.value)
  }

  return (
    <Dialog onClose={onClose} open={open} fullWidth maxWidth='md'>
      <DialogTitle>Editar datos de facturas</DialogTitle>
      <DialogContent>
        <TextField
          className={css.input}
          id='classfy'
          name='classfy'
          label='ID de la ficha de Classfy'
          variant='standard'
          margin='dense'
          value={classfyId}
          onChange={handleChange}
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button color='error' onClick={onClose}>
          Cancelar
        </Button>
        <Button
          color='primary'
          onClick={() => onSubmit(classfyId)}
        >
          Enviar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ModalEditarDatosClassfyAlumno
